import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProfileInterface } from '../lib/model/profile';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-subscription-info',
  templateUrl: './subscription-info.component.html',
  styleUrls: ['./subscription-info.component.less']
})
export class SubscriptionInfoComponent {
  profile$: Observable<ProfileInterface>;
  subscriptionsUrl: string;

  constructor(profileService: ProfileService) {
    this.profile$ = profileService.getProfile();
    this.subscriptionsUrl = environment.subscriptionsUrl;
  }
}
