import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';
import { LoadGroup } from 'src/app/lib/model/load-group/load-group';
import { LoadGroupList } from 'src/app/lib/model/load-group/load-group-list';
import { Load } from 'src/app/load/lib/load';

@Injectable({
  providedIn: 'root'
})
export class PendingLoadsService {
  private isActive = new BehaviorSubject(true);

  private loads = new BehaviorSubject<LoadGroupList>(
    new LoadGroupList(LoadGroup)
  );

  constructor() {}

  public clear() {
    this.loads.next(new LoadGroupList(LoadGroup));
  }

  public addLoad(load: Load, cnt: number = 1) {
    console.log(
      'pending-loads.service addLoad',
      load,
      cnt,
      this.isActive.value
    );
    if (!this.isActive.value) {
      return;
    }
    const nextList = this.loads.value.addLoad(load, cnt);
    console.log('adding loads', nextList);
    this.loads.next(nextList);
  }

  public remove(load: Load) {
    this.loads.next(this.loads.value.removeLoad(load));
  }

  public loads$(): Observable<LoadGroupList> {
    return this.loads.asObservable();
  }

  public getCurrentLoads(): LoadGroupList {
    return this.loads.value;
  }

  public setIsActive(val: boolean) {
    this.isActive.next(val);
  }

  public isActive$() {
    return this.isActive.asObservable();
  }

  public getIsActive() {
    return this.isActive.value;
  }

  public showList$() {
    return combineLatest([this.isActive$(), this.loads$()]).pipe(
      map(([isActive, list]) => isActive && !list.isEmpty())
    );
  }
}
