import { Injectable, Type } from '@angular/core';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { Box } from '../type/box/lib/box';
import { Container } from '../type/container/lib/container';
import { Trailer } from '../type/trailer/lib/trailer';
import { Space } from './space';

@Injectable({
  providedIn: 'root'
})
export class SpaceFactory {
  public constructor(private loadFactory: LoadFactory) {}

  private typeMap: { [key: string]: Type<Space> } = {
    trailer: Trailer,
    box: Box,
    container: Container
  };

  recreate(obj: any): Space {
    if (typeof this.typeMap[obj.type] === undefined) {
      console.error('unknown Space type', obj.type);
      return null;
    }
    return new this.typeMap[obj.type]({ ...obj }, this.loadFactory);
  }
}
