import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DateRange } from './date-range';
import { FilterType } from './filter-type';

@Injectable({
  providedIn: 'root'
})
export class DatesFilterService {
  currentType: FilterType = FilterType.month;
  currentDate: Date;
  range = new Subject<DateRange>();
  rangeFrom: Date = null;
  rangeTo: Date = null;

  constructor() {
    this.currentDate = new Date();
  }

  get range$(): Observable<DateRange> {
    return this.range.asObservable();
  }

  updateRange(range: DateRange) {
    this.range.next(range);
  }
}
