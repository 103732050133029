import { Component, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-weight',
  templateUrl: './weight.component.html',
  styleUrls: ['./../../load.component.less', './weight.component.less']
})
export class WeightComponent {
  @Input() mode = 'outline';
  private _defaultValue: number;
  @Input() set default(val: number) {
    this._defaultValue = val;
    this.setValue(val || null);
  }
  get default() {
    return this._defaultValue;
  }
  public form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, public ui: UiService) {
    this.createForm();
  }

  public getValue(): number {
    //validate
    return this.form.get('weight').value;
  }

  public setValue(val: number) {
    this.form.get('weight').setValue(val);
  }

  private createForm() {
    this.form = this.formBuilder.group({
      weight: [null, Validators.compose([])]
    });
  }
}
