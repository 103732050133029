import { Injectable } from '@angular/core';
import { EMPTY, map, Observable, Subject } from 'rxjs';
import { CalculationService } from '../../api/calculation.service';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { ContextStack } from './context-stack';
import { PositionerResponse } from 'src/app/lib/communication/positioner.response';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  private stacks: Map<string, ContextStack>;
  private stackSize = 4;

  private update = new Subject<void>();
  constructor(private calculationService: CalculationService) {
    this.stacks = new Map();
  }

  public initIfEmpty(context: VehicleContext) {
    if (!this.stacks.has(context.getUuid())) {
      this.stacks.set(context.getUuid(), new ContextStack(this.stackSize));
    }
    const stack = this.stacks.get(context.getUuid());
    if (stack.isEmpty()) {
      stack.push(context);
    }
  }

  public pushState(context: VehicleContext) {
    if (!this.stacks.has(context.getUuid())) {
      this.stacks.set(context.getUuid(), new ContextStack(this.stackSize));
    }
    console.log('push state', context.getHistoryUuid());
    const stack = this.stacks.get(context.getUuid());
    stack.push(context);
    this.update.next();
  }

  public get state$(): Observable<void> {
    return this.update.asObservable();
  }

  public canUndo(context: VehicleContext): boolean {
    if (context && this.stacks.has(context.getUuid())) {
      const stack = this.stacks.get(context.getUuid());
      return stack.canMovePrev(context);
    }
    return false;
  }

  public canRedo(context: VehicleContext): boolean {
    if (context && this.stacks.has(context.getUuid())) {
      const stack = this.stacks.get(context.getUuid());
      return !stack.isCurrentLast();
    }
    return false;
  }

  public back(context: VehicleContext): Observable<PositionerResponse> {
    if (this.stacks.has(context.getUuid())) {
      const stack = this.stacks.get(context.getUuid());
      const prevState = stack.moveCurrentPrev();
      return this.calculationService.setHistoryHead(prevState);
    } else {
      return EMPTY;
    }
  }

  public forward(context: VehicleContext): Observable<VehicleContext> {
    if (this.stacks.has(context.getUuid())) {
      const stack = this.stacks.get(context.getUuid());
      const nextState = stack.moveCurrentNext();
      return this.calculationService
        .saveContext(nextState)
        .pipe(map(() => nextState));
    } else {
      return EMPTY;
    }
  }
}
