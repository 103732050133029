import { Pipe, PipeTransform } from '@angular/core';
import { UiService } from '../../../services/ui.service';
import { Load } from '../../lib/load';

@Pipe({
  name: 'loadFilter'
})
export class FilterPipe implements PipeTransform {
  constructor(private uiService: UiService) {}

  transform(items: Load[], searchText: string): Load[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    return items.filter((item) =>
      (
        item.name +
        item.descriptiveDimensions
          .map((d) => this.uiService.getLengthInCurrentUnit(d))
          .join('x')
      )
        .toLowerCase()
        .includes(searchText)
    );
  }
}
