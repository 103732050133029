import { Load } from '../../load/lib/load';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { PositionerRequest } from '../../lib/communication/positioner.request';

export class UpdateLoadRequest extends PositionerRequest {
  constructor(loadsToUpdate: Load[], context: VehicleContext) {
    super({
      historyUuid: context.getHistoryUuid(),
      addedLoads: loadsToUpdate,
      orbitControlsState: context.getOrbitControlsState(),
      settings: context.getSettings()
    });
  }
}
