<div [class.marker-mode]="!!selectedMarker">
  <div
    class="marker-cursor {{ selectedMarker?.color }}"
    [hidden]="!selectedMarker"
    #cursor
  ></div>
  <div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div class="modal-header" cdkDragHandle>
      <div>
        <button
          mat-raised-button
          class="mat-button mat-accent btn-secondary"
          type="button"
          (click)="downloadPdf()"
        >
          <ng-container i18n>Zapisz jako PDF</ng-container>
        </button>
        <button
          mat-raised-button
          class="mat-button mat-accent btn-secondary"
          type="button"
          (click)="downloadXLS()"
        >
          <ng-container i18n>Zapisz jako XLS</ng-container>
        </button>
        <button
          mat-raised-button
          class="mat-button mat-accent btn-secondary"
          type="button"
          (click)="downloadJPG()"
        >
          <ng-container i18n>Zapisz jako JPG</ng-container>
        </button>
        <button
          mat-raised-button
          class="mat-button mat-accent btn-secondary"
          type="button"
          (click)="share()"
        >
          <ng-container i18n>Udostępnij</ng-container>
          <mat-progress-spinner
            *ngIf="shareLoading"
            class="progress"
            [color]="primary"
            [mode]="indeterminate"
            [diameter]="26"
          >
          </mat-progress-spinner>
        </button>
        <button
          mat-raised-button
          class="mat-button mat-accent btn-secondary"
          type="button"
          (click)="toggleComment()"
        >
          <ng-container *ngIf="commentVisible" i18n>Ukryj uwagi</ng-container>
          <ng-container *ngIf="!commentVisible" i18n
            >Dodaj / edytuj uwagi</ng-container
          >
        </button>
        <button
          mat-raised-button
          class="mat-button mat-accent btn-secondary"
          type="button"
          (click)="toggleMarkers()"
          i18n-matTooltip
          matTooltip="Możesz dodać dodatkowe oznaczenia na każdym z widoków"
          [matTooltipPosition]="'below'"
          [matTooltipShowDelay]="500"
          #markersTooltip="matTooltip"
        >
          <ng-container *ngIf="markersVisible" i18n
            >Ukryj znaczniki</ng-container
          >
          <ng-container *ngIf="!markersVisible" i18n
            >Dodaj znaczniki</ng-container
          >
        </button>
        <div class="markers-toolbox" *ngIf="markersVisible">
          <div
            *ngFor="let marker of markers"
            class="marker-tool {{ marker.color }}"
            (click)="toggleSelectMarker(marker)"
            [class.selected]="marker.selected"
          ></div>
        </div>
      </div>
      <div class="views-container">
        <span i18n>Widoki: </span>
        <mat-checkbox [(ngModel)]="topView" i18n>Góra</mat-checkbox>
        <mat-checkbox [(ngModel)]="sideFrontView" i18n>Bok 1</mat-checkbox>
        <mat-checkbox [(ngModel)]="sideBackView" i18n>Bok 2</mat-checkbox>
        <mat-checkbox [(ngModel)]="frontView" i18n>Przód</mat-checkbox>
        <mat-checkbox [(ngModel)]="rearView" i18n>Tył</mat-checkbox>
        <mat-checkbox [(ngModel)]="currentView"
          ><ng-container>3D</ng-container>
          <span class="help">
            (<ng-container i18n
              >Aktualny widok rozmieszczenia. Dostosuj widok przestrzeni, aby
              pokazać najważniejsze aspekty załadunku</ng-container
            >)</span
          >
        </mat-checkbox>
      </div>
      <button mat-button (click)="close()" class="close-btn">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="showShareRow">
    <div class="share-project-row">
      <mat-form-field class="url-input" appearance="fill">
        <mat-label>URL</mat-label>
        <input matInput readonly value="{{ shareUrl }}" #shareUrlInput />
      </mat-form-field>
      <button
        mat-raised-button
        matSuffix
        [ngxClipboard]="shareUrlInput"
        (cbOnSuccess)="urlCopied($event)"
        i18n-title
        title="Kopiuj URL"
        class="copy-button mat-accent btn-secondary"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
  </div>
  <div class="pdf-outline">
    <div class="pdf-container" #pdf_container>
      <div class="header pdf-part">
        <div class="logo-row">
          <img
            src="{{ settings?.logo || 'assets/images/logo.png' }} "
            alt="SmartLoad"
          />
          <div class="text-right">
            <p>
              <ng-container i18n>Projekt załadunku z dnia</ng-container>:
              <strong>{{ date }}</strong>
            </p>
            <p>
              <ng-container i18n>Opracował/a</ng-container>:
              <strong>{{ (user | async)?.fullName() }}</strong>
            </p>
          </div>
        </div>

        <h1>
          <span>{{ project?.toString() }}</span>
        </h1>

        <div [class.hidden]="!commentVisible">
          <app-project-comment-form
            class="in-export"
            rows="4"
            [showFormElements]="false"
            (saved)="commentSaved()"
          ></app-project-comment-form>
        </div>
      </div>
      <div class="vehicle-container" *ngFor="let contextData of data">
        <div class="pdf-part">
          <p class="text-center">
            <ng-container class="" i18n>Przestrzeń ładunkowa</ng-container>:
            <strong
              >{{ contextData.context.getVehicle().fullName }}
              {{
                contextData.context.getVehicle() | vehicleDimensions | async
              }}</strong
            >
          </p>
          <p class="strong text-center">
            <ng-container i18n>Waga ładunku</ng-container>:
            {{
              contextData.context.getAllLoadsWeight()
                | weightUnit
                | async
                | number: '0.0-2'
            }}
            {{ ui.getWeightUnit() | async }} |
            <ng-container i18n>Ładowność</ng-container>:
            {{
              contextData.context.getVehicle()?.maxLoadingWeight
                | weightUnit
                | async
                | number: '0.0-2'
            }}
            {{ ui.getWeightUnit() | async }}
          </p>
        </div>
        <div class="images">
          <div
            *ngIf="sideFrontView"
            class="pdf-part view-vehicle-{{
              contextData.context.getVehicle().uuid
            }}"
          >
            <div class="marker-canvas" (click)="markerClick($event)"></div>
            <app-scene-view
              [context]="contextData.context"
              side="side"
              [scale]="viewScale"
              [textScale]="textScale"
            ></app-scene-view>
            <div class="description" i18n>Widok: bok 1</div>
          </div>
          <div
            *ngIf="sideBackView"
            class="pdf-part view-vehicle-{{
              contextData.context.getVehicle().uuid
            }}"
          >
            <div class="marker-canvas" (click)="markerClick($event)"></div>
            <app-scene-view
              [context]="contextData.context"
              side="sideBack"
              [scale]="viewScale"
              [textScale]="textScale"
            ></app-scene-view>
            <div class="description" i18n>Widok: bok 2</div>
          </div>

          <div
            *ngIf="topView"
            class="pdf-part  view-vehicle-{{
              contextData.context.getVehicle().uuid
            }}"
            style="position: relative"
          >
            <div class="marker-canvas" (click)="markerClick($event)"></div>
            <app-scene-view
              [context]="contextData.context"
              side="top"
              [scale]="viewScale"
              [textScale]="textScale"
            ></app-scene-view>
            <div
              class="description"
              style="position: absolute; top: 5px; left: 5px"
            >
              <ng-container i18n>Widok: góra</ng-container>
            </div>
          </div>

          <div
            *ngIf="rearView"
            class="pdf-part  view-vehicle-{{
              contextData.context.getVehicle().uuid
            }}"
          >
            <div class="marker-canvas" (click)="markerClick($event)"></div>
            <app-scene-view
              [context]="contextData.context"
              side="rear"
              [scale]="viewScale"
              [textScale]="textScale"
            ></app-scene-view>
            <div class="description" i18n>Widok: tył</div>
          </div>
          <div
            *ngIf="frontView"
            class="pdf-part  view-vehicle-{{
              contextData.context.getVehicle().uuid
            }}"
          >
            <div class="marker-canvas" (click)="markerClick($event)"></div>
            <app-scene-view
              [context]="contextData.context"
              side="front"
              [scale]="viewScale"
              [textScale]="textScale"
            ></app-scene-view>
            <div class="description" i18n>Widok: przód</div>
          </div>
          <div
            *ngIf="currentView"
            class="pdf-part  view-vehicle-{{
              contextData.context.getVehicle().uuid
            }}"
          >
            <div class="marker-canvas" (click)="markerClick($event)"></div>
            <div class="description" i18n>Widok: 3D</div>
            <img src="{{ contextData.sceneImg.data }}" class="loadings-img" />
          </div>
        </div>
        <div class="pdf-part">
          <app-vehicle-summary
            hideList="true"
            type="pdf-export"
            [context]="contextData.context"
          ></app-vehicle-summary>
        </div>
        <table class="load-list pdf-part">
          <thead>
            <tr>
              <th><ng-container i18n>Nazwa</ng-container>:</th>
              <th><ng-container i18n>Wymiary</ng-container>:</th>
              <th><ng-container i18n>Ilość</ng-container>:</th>
              <th><ng-container i18n>Waga</ng-container>:</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let group of contextData.context.groupLoads().loads">
              <td>
                <div
                  class="color-box"
                  [style.backgroundColor]="colors.getCssValue(group.load.color)"
                ></div>
                {{ group.load.fullName }}<br />
                <div class="text-normal">
                  <span *ngIf="group.isFloorableBoth()">
                    <span>(<ng-container i18n>piętrowalne</ng-container>)</span>
                  </span>
                  <span *ngIf="group.isFloorableOnlyBottom()">
                    <span
                      >(<ng-container i18n>piętrowalne aktywnie</ng-container
                      >)</span
                    >
                  </span>
                  <span *ngIf="group.isFloorableOnlyTop()">
                    <span
                      >(<ng-container i18n>piętrowalne pasywnie</ng-container
                      >)</span
                    >
                  </span>
                  <span *ngIf="group.isNonFloorable()">
                    <span
                      >(<ng-container i18n>niepiętrowalne</ng-container>)</span
                    >
                  </span>
                </div>
              </td>
              <td>
                {{ group.load | dimensions | async }}
              </td>
              <td>
                {{ group.cnt }}
              </td>
              <td>
                {{ group.load.weight | weightUnit | async | number: '0.0-2' }}
                {{ ui.getWeightUnit() | async }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
