import { Constants } from '../vehicle/lib/constants';
import { Vector } from '../lib/communication/vector';
import { Box3, Vector3 } from 'three';
import { Load } from '../load/lib/load';
import { Vehicle } from '../vehicle/lib/vehicle';
import { Space } from '../vehicle/space/lib/space';
import { Constants as Config } from 'src/app/config/constants';

export class MeshPositionerValidator {
  vehicle: Vehicle;
  loads: Load[];
  currentLoad: Load;
  movement: Vector;

  constructor(
    vehicle: Vehicle,
    loads: Load[],
    currentLoad: Load,
    movement: Vector
  ) {
    this.vehicle = vehicle;
    this.loads = loads;
    this.currentLoad = currentLoad;
    this.movement = movement;
  }

  //FIXME to także powinno zachodzić po stronie usługi
  public validate() {
    this.fixProtrusion();
    this.fixIntersections();
    this.fixGrounding();
  }

  private fixIntersections() {
    const xMinLoad =
      this.currentLoad.mesh.position.x -
      (this.currentLoad.cuboidHull.length * Config.DIMENSION_SCALING) / 2;
    const xMaxLoad =
      this.currentLoad.mesh.position.x +
      (this.currentLoad.cuboidHull.length * Config.DIMENSION_SCALING) / 2;
    const yMinLoad =
      this.currentLoad.mesh.position.y -
      (this.currentLoad.cuboidHull.height * Config.DIMENSION_SCALING) / 2;
    const yMaxLoad =
      this.currentLoad.mesh.position.y +
      (this.currentLoad.cuboidHull.height * Config.DIMENSION_SCALING) / 2;
    const zMinLoad =
      this.currentLoad.mesh.position.z -
      (this.currentLoad.cuboidHull.width * Config.DIMENSION_SCALING) / 2;
    const zMaxLoad =
      this.currentLoad.mesh.position.z +
      (this.currentLoad.cuboidHull.width * Config.DIMENSION_SCALING) / 2;

    for (const load of this.loads) {
      if (load.uuid === this.currentLoad.uuid) {
        continue;
      }

      const xMinLoadCheck =
        load.mesh.position.x -
        (load.cuboidHull.length * Config.DIMENSION_SCALING) / 2;
      const xMaxLoadCheck =
        load.mesh.position.x +
        (load.cuboidHull.length * Config.DIMENSION_SCALING) / 2;
      const yMinLoadCheck =
        load.mesh.position.y -
        (load.cuboidHull.height * Config.DIMENSION_SCALING) / 2;
      const yMaxLoadCheck =
        load.mesh.position.y +
        (load.cuboidHull.height * Config.DIMENSION_SCALING) / 2;
      const zMinLoadCheck =
        load.mesh.position.z -
        (load.cuboidHull.width * Config.DIMENSION_SCALING) / 2;
      const zMaxLoadCheck =
        load.mesh.position.z +
        (load.cuboidHull.width * Config.DIMENSION_SCALING) / 2;
      //console.log('zMaxLoadCheck,zMinLoadCheck', zMaxLoadCheck, zMinLoadCheck);

      const maxX = Math.min(xMaxLoad, xMaxLoadCheck);
      const minX = Math.max(xMinLoad, xMinLoadCheck);
      const intersectXlength = Config.roundToScale(maxX - minX);

      const maxZ = Math.min(zMaxLoad, zMaxLoadCheck);
      const minZ = Math.max(zMinLoad, zMinLoadCheck);
      const intersectZlength = Config.roundToScale(maxZ - minZ);

      const maxY = Math.min(yMaxLoad, yMaxLoadCheck);
      const minY = Math.max(yMinLoad, yMinLoadCheck);
      const intersectYlength = Config.roundToScale(maxY - minY);
      if (
        intersectXlength > 0 &&
        intersectZlength > 0 &&
        intersectYlength > 0
      ) {
        //console.log('fix intersections');
        this.currentLoad.mesh.position.z = Config.roundToScale(
          zMaxLoadCheck +
            (this.currentLoad.cuboidHull.width * Config.DIMENSION_SCALING) / 2
        );
        this.fixIntersections();
      }
    }
  }

  //bez sprawdzenia wysokosci
  private inVehicle(): Space {
    const vehiclePosition = this.vehicle.mesh.mesh.position;
    const load = this.currentLoad;
    const position = load.mesh.position;

    const xMinLoad =
      position.x - (load.cuboidHull.length * Config.DIMENSION_SCALING) / 2;
    const xMaxLoad =
      position.x + (load.cuboidHull.length * Config.DIMENSION_SCALING) / 2;
    const zMinLoad =
      position.z - (load.cuboidHull.width * Config.DIMENSION_SCALING) / 2;
    const zMaxLoad =
      position.z + (load.cuboidHull.width * Config.DIMENSION_SCALING) / 2;

    for (const space of this.vehicle.spaces) {
      const spaceWorldPosition = space.mesh.meshObj.getWorldPosition(
        new Vector3()
      );
      const xMinSpace = spaceWorldPosition.x;
      const xMaxSpace = xMinSpace + space.length * Config.DIMENSION_SCALING;
      const zMinSpace = spaceWorldPosition.z;
      const zMaxSpace =
        spaceWorldPosition.z + space.width * Config.DIMENSION_SCALING;
      if (
        xMinLoad >= xMinSpace &&
        xMaxLoad <= xMaxSpace &&
        zMinLoad >= zMinSpace &&
        zMaxLoad <= zMaxSpace
      ) {
        return space;
      }
    }
    return null;
  }

  private roundVector(vector: Vector3) {
    vector.x = Config.roundToScale(vector.x);
    vector.y = Config.roundToScale(vector.y);
    vector.z = Config.roundToScale(vector.z);
    return vector;
  }

  private roundBox(box: Box3) {
    box.min.copy(this.roundVector(box.min.clone()));
    box.max.copy(this.roundVector(box.max.clone()));
    return box;
  }

  //sprawdzenie aktualnie przeniesionego ładunku czy nie wisi w powietrzu,
  //praktycznie każdy z ładunków powinien być sprawdzany i przeniesiony siłą ale spróbujemy zrobić to po stronie serwera
  private fixGrounding() {
    const box = this.roundBox(
      new Box3().setFromObject(this.currentLoad.mesh.obj)
    );
    const boxSize = box.getSize(new Vector3());

    let greatestY = 0;
    const space = this.inVehicle();
    let spaceMinY = 0;
    if (space !== null) {
      //TODO: przetestowac mocno
      const bb = this.roundBox(new Box3().setFromObject(space.mesh.meshObj));
      greatestY = Math.max(bb.min.y, 0);
      // greatestY += 50; //vehicle floor;
    }
    spaceMinY = greatestY;

    for (const load of this.loads) {
      if (this.currentLoad.mesh.obj.uuid !== load.mesh.obj.uuid) {
        const checkBox = this.roundBox(new Box3().setFromObject(load.mesh.obj));
        const maxX = Math.min(checkBox.max.x, box.max.x);
        const minX = Math.max(checkBox.min.x, box.min.x);
        const intersectXlength = Config.roundToScale(maxX - minX);

        const maxZ = Math.min(checkBox.max.z, box.max.z);
        const minZ = Math.max(checkBox.min.z, box.min.z);
        const intersectZlength = Config.roundToScale(maxZ - minZ);
        if (intersectXlength > 0 && intersectZlength > 0) {
          /*console.log(
            'grounding intersect',
            intersectXlength,
            intersectZlength,
            checkBox.max.y,
            greatestY
          );*/
          greatestY = Math.max(greatestY, checkBox.max.y);
        }
      }
    }

    const diffY =
      this.currentLoad.mesh.position.y - (greatestY + boxSize.y / 2);
    if (diffY > 0) {
      this.movement.y -= diffY;
      this.currentLoad.mesh.position.y = greatestY + boxSize.y / 2;
    }
  }

  //validator powinien tez byc osobną klasa jak meshpositioner
  //teoretycznie powinno być zbadane dopiero po opuszczeniu
  private fixProtrusion() {
    //jeżeli występuje protrusion to musi sobie z tym poradzic algorytm w services
    const vehiclePosition = this.vehicle.mesh.mesh.position;
    const load = this.currentLoad;
    const position = load.mesh.position;

    const xMinLoad =
      position.x - (load.cuboidHull.length * Config.DIMENSION_SCALING) / 2;
    const xMaxLoad =
      position.x + (load.cuboidHull.length * Config.DIMENSION_SCALING) / 2;
    const yMinLoad =
      position.y - (load.cuboidHull.height * Config.DIMENSION_SCALING) / 2;
    const yMaxLoad =
      position.y + (load.cuboidHull.height * Config.DIMENSION_SCALING) / 2;
    const zMinLoad =
      position.z - (load.cuboidHull.width * Config.DIMENSION_SCALING) / 2;
    const zMaxLoad =
      position.z + (load.cuboidHull.width * Config.DIMENSION_SCALING) / 2;

    for (const space of this.vehicle.spaces) {
      const spaceWorldPosition = space.mesh.meshObj.getWorldPosition(
        new Vector3()
      );
      const xMinSpace = spaceWorldPosition.x;
      const zMinSpace = spaceWorldPosition.z;
      const yMinSpace = spaceWorldPosition.y;
      const xMaxSpace = xMinSpace + space.length * Config.DIMENSION_SCALING;
      const yMaxSpace =
        spaceWorldPosition.y + space.height * Config.DIMENSION_SCALING;
      const zMaxSpace =
        spaceWorldPosition.z + space.width * Config.DIMENSION_SCALING;

      const maxX = Math.min(xMaxLoad, xMaxSpace);
      const minX = Math.max(xMinLoad, xMinSpace);
      const intersectXlength = Config.roundToScale(maxX - minX);

      const maxZ = Math.min(zMaxLoad, zMaxSpace);
      const minZ = Math.max(zMinLoad, zMinSpace);
      const intersectZlength = Config.roundToScale(maxZ - minZ);

      const maxY = Math.min(yMaxLoad, yMaxSpace);
      const minY = Math.max(yMinLoad, yMinSpace);
      const intersectYlength = Config.roundToScale(maxY - minY);

      if (
        intersectXlength > 0 &&
        intersectYlength > 0 &&
        intersectZlength > 0
      ) {
        if (
          intersectXlength <
            Config.roundToScale(
              load.cuboidHull.length * Config.DIMENSION_SCALING
            ) ||
          intersectZlength <
            Config.roundToScale(
              load.cuboidHull.width * Config.DIMENSION_SCALING
            )
        ) {
          /*console.log(
            'validator protrusion move z',
            intersectXlength,
            Config.roundToScale(
              load.cuboidHull.length * Config.DIMENSION_SCALING
            ),
            intersectZlength,
            Config.roundToScale(
              load.cuboidHull.width * Config.DIMENSION_SCALING
            )
          );*/
          load.mesh.position.z = Config.roundToScale(
            zMaxSpace + (load.cuboidHull.width * Config.DIMENSION_SCALING) / 2
          );
        }
      }
    }
    return null;
  }
}
