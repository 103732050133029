<div class="cargo-select" (click)="toggleList()">
  <span class="icon">
    <img src="../../assets/images/ic_cargo2.png" alt="" />
  </span>
  <div *ngIf="item?.name; else noLoad" class="text text-center">
    {{ item.fullName }}
    <strong
      >r={{ item.radius | lengthUnit | async | number : '0.0-2' }} h={{
        item.height | lengthUnit | async | number : '0.0-2'
      }}
      {{ ui.getLengthUnit() | async }}</strong
    >
  </div>
  <ng-template #noLoad>
    <span class="text text-center">---</span>
  </ng-template>
  <button mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
</div>
<div class="load-list-container">
  <div class="load-list" [class.open]="listOpened">
    <button mat-icon-button (click)="toggleList()">
      <mat-icon>close</mat-icon>
    </button>

    <mat-form-field>
      <input
        #search
        matInput
        [(ngModel)]="searchText"
        autocomplete="off"
        (input)="onSearchText()"
        placeholder="Szukaj"
        class="inp"
      />
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>
    <mat-list dense role="list">
      <cdk-virtual-scroll-viewport [itemSize]="10" class="load-list-viewport">
        <mat-list-item
          *cdkVirtualFor="
            let load of items | loadFilter : searchText;
            let odd = odd
          "
          [class.load-list-item-alternate]="load.userDefined"
          (click)="select(load); toggleList()"
          role="listitem"
        >
          <h5>
            {{ load.fullName }}
            <span>
              r={{ load.radius | lengthUnit | async | number : '0.0-2' }} h={{
                load.height | lengthUnit | async | number : '0.0-2'
              }}
              {{ ui.getLengthUnit() | async }}
            </span>
          </h5>
        </mat-list-item>
      </cdk-virtual-scroll-viewport>
    </mat-list>
  </div>
</div>
