import {
  Injectable,
  ApplicationRef,
  ComponentFactoryResolver, // FIXME deprecated
  ComponentRef,
  Injector,
  EmbeddedViewRef
} from '@angular/core';

import { VehicleContext } from '../lib/model/vehicle-context';

export type ComponentType<T> = new (...args: any[]) => T;
@Injectable({
  providedIn: 'root'
})
export class LoadComponentService {
  context: VehicleContext;
  header: string;
  content: string;
  private components: ComponentRef<any>[] = [];

  constructor(
    private appRef: ApplicationRef,
    private resolver: ComponentFactoryResolver,
    private injector: Injector
  ) {}

  public add<T>(
    component: ComponentType<T> | ComponentRef<T>,
    element?: Element | string
  ): ComponentRef<T> {
    const componentRef =
      component instanceof ComponentRef
        ? component
        : this.resolver
            .resolveComponentFactory(component)
            .create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    if (typeof element === 'string') {
      element = document.querySelector(element);
    }
    if (!element) {
      element = document.body;
    }
    element.appendChild(
      (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement
    );
    this.components.push(componentRef);
    return componentRef;
  }

  public remove(
    dialog: number | ComponentRef<any> | ComponentType<any>
  ): boolean {
    // console.log('load-component.service.ts: remove called');
    let componentRef: ComponentRef<any>;

    if (typeof dialog === 'number' && this.components.length > dialog) {
      componentRef = this.components.splice(dialog, 1)[0];
    } else if (dialog instanceof ComponentRef) {
      for (const cr of this.components) {
        if (cr === dialog) {
          componentRef = cr;
        }
      }
    } else {
      for (const cr of this.components) {
        if (cr.componentType === dialog) {
          console.log('type found');
          componentRef = cr;
        }
      }
    }
    if (componentRef) {
      this.removeComponent(componentRef);
      return true;
    }
    return false;
  }

  public clear(from: string) {
    // console.log('load-component.service.ts: clear called from', from);
    while (this.components.length > 0) {
      this.removeComponent(this.components.pop());
    }
  }

  private removeComponent(componentRef: ComponentRef<any>) {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
}
