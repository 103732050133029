export class Statistics {
  spaceUuid: string;
  fxLDM = 0; // wolna przestrzeń ładunkowa w osi X - w długości samochodu - free x Loading Meter
  oxLDM = 0; // zajęta przestrzeń ładunkowa w osi X - w długości samochodu - occupied x Loading Meter
  fzLDM = 0; // wolna przestrzeń ładunkowa w osi Z - w szerokości samochodu - free z Loading Meter
  ozLDM = 0; // zajęta przestrzeń ładunkowa w osi Z - w szerokości samochodu - occupied z Loading Meter
  fyLDM = 0; // wolna przestrzeń ładunkowa w osi Y - w wysokości samochodu - free z Loading Meter
  oyLDM = 0; // zajęta przestrzeń ładunkowa w osi Y - w wysokości samochodu - occupied z Loading Meter

  fV = 0; // wolna objętość - free V
  oV = 0; // zajęta objętość - occupied V
  fW = 0; // wolna ładowność - free Weight
  oW = 0; // zajęta ładowność - occupied Weight
  fF = 0; // wolna podłoga - free Floor [mm]
  oF = 0; // zajęta podłoga - occupied Floor
  cnt = 0; // ilość dodanych ładunków do pojazdu

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  public getFreeFloor(): number {
    const freeFloor = this.fF / 1000000;
    return this.roundTo(freeFloor, 2);
  }

  public getFreeVolume(): number {
    const freeVolume = this.fV / 1000000000;
    return this.roundTo(freeVolume, 2);
  }

  public getLoadsVolume(): number {
    const loadsVolume = this.oV / 1000000000;
    return this.roundTo(loadsVolume, 2);
  }

  public getUsedWeight(): number {
    const usedWeight = this.oW;
    return this.roundTo(usedWeight, 2);
  }

  public getTotalLoadingLength(): number {
    return this.roundTo(this.fxLDM + this.oxLDM, 2);
  }

  public getFreeLoadingLength(): number {
    const freeLDM = this.fxLDM;
    return this.roundTo(freeLDM, 2) || 0;
  }

  public getUsedLoadingLength(): number {
    const usedLDM = this.oxLDM;
    // // console.log('oxLDM', this.oxLDM, 'fxLDM', this.fxLDM);
    return this.roundTo(usedLDM, 2) || 0;
  }

  private roundTo(num: number, precision: number) {
    const multiplier = Math.pow(10, precision);
    return Math.round(num * multiplier + Number.EPSILON) / multiplier;
  }

  public getFreeLoadingLengthPercentage() {
    if (this.getTotalLoadingLength() === 0) {
      return 0;
    }
    return this.roundTo(
      (this.getFreeLoadingLength() / this.getTotalLoadingLength()) * 100,
      2
    );
  }
  public getUsedLoadingLengthPercentage() {
    if (this.getTotalLoadingLength() === 0) {
      return 0;
    }
    return this.roundTo(
      (this.getUsedLoadingLength() / this.getTotalLoadingLength()) * 100,
      2
    );
  }

  public getUsedWeightPercentage(fromTotal: number) {
    if (fromTotal === 0) {
      return 0;
    }
    return this.roundTo((this.getUsedWeight() / fromTotal) * 100, 2);
  }

  public getTotalSpace(unit: string) {
    const length = this.getTotalLoadingLength() ?? 0;
    return this.getSpaceLengthByUnit(length, unit);
  }

  public getUsedSpace(unit: string) {
    const length = this.getUsedLoadingLength() ?? 0;
    return this.getSpaceLengthByUnit(length, unit);
  }

  public getFreeSpace(unit: string) {
    const length = this.getFreeLoadingLength() ?? 0;
    return this.getSpaceLengthByUnit(length, unit);
  }

  public getUsedSpacePercentage(unit: string) {
    return this.getUsedLoadingLengthPercentage(); // dopóki LDM = m, to procenty zostają takie same
  }

  public getFreeSpacePercentage(unit: string) {
    return this.getFreeLoadingLengthPercentage(); // dopóki LDM = m, to procenty zostają takie same
  }

  private getSpaceLengthByUnit(length: number, unit: string) {
    switch (unit) {
      case 'LDM':
      case 'm':
        return this.roundTo(length / 1000, 2);
      case 'cm':
        return this.roundTo(length / 10, 2);
      case 'mm':
        return this.roundTo(length, 2);
    }
    return 0;
  }
}
