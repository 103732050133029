export class Message {
  constructor(
    public title: string,
    public body?: string,
    public actionText?: string
  ) {}
}

export class SystemMessage {
  constructor(public type: string, public context?: any) {}
}
