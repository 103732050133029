import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { VehicleContext } from 'src/app/lib/model/vehicle-context';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { Vehicle } from '../../lib/vehicle';
import { AxlesService } from '../axles.service';
import { FormComponent } from './form.component';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  private requestUiUpdate$ = new Subject<void>();
  public context: VehicleContext;

  constructor(
    private loadComponentService: LoadComponentService,
    private axleService: AxlesService
  ) {}

  get vehicle(): Vehicle {
    return this.context.getVehicle();
  }

  get updateAxles$(): Observable<void> {
    return this.requestUiUpdate$.asObservable();
  }

  public requestUiUpdate() {
    this.requestUiUpdate$.next();
  }

  close() {
    this.loadComponentService.clear('vehicle/axles/form/form.service.ts');
  }

  public openForm(context: VehicleContext) {
    this.context = context;
    this.close();
    this.loadComponentService.add(
      FormComponent //,
      // '#scene-container'
    );
  }

  public updateUiAndClose() {
    this.requestUiUpdate();
    this.close();
  }

  public submit(): Observable<Vehicle> {
    return this.axleService.updateVehicle(this.context);
  }
}
