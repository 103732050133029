import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { UiService } from 'src/app/services/ui.service';
import { Vehicle } from '../../../lib/vehicle';
import { Space } from '../../../space/lib/space';
import { Axle } from '../../lib/axle';
import { SpaceAxlesForm } from './space-axles.form';

export class AxlesForm extends UntypedFormGroup {
  get Vehicle(): SpaceAxlesForm {
    return this.controls.vehicle as SpaceAxlesForm;
  }

  get Spaces(): SpaceAxlesForm[] {
    return (this.controls.spaces as UntypedFormArray)
      .controls as SpaceAxlesForm[];
  }

  constructor(
    private readonly vehicle: Vehicle,
    private uiService: UiService,
    private readonly fb: UntypedFormBuilder = new UntypedFormBuilder()
  ) {
    super(
      fb.group({
        vehicle: new SpaceAxlesForm(vehicle, uiService, fb),
        spaces: fb.array(
          vehicle.enabledSpaces.map(
            (space) => new SpaceAxlesForm(space, uiService, fb)
          )
        )
      }).controls
    );
  }

  addVehicleAxle() {
    this.Vehicle.addAxle(new Axle());
  }

  addSpaceAxle(space: Space) {
    const spaceForm = this.Spaces.find(
      (form) => form.space.uuid === space.uuid
    );
    if (!spaceForm) {
      throw new Error(`Space ${space.uuid} not defined in form`);
    }
    spaceForm.addAxle(new Axle());
  }

  findSpaceForm(space: Space) {
    return this.Spaces.find((form) => form.space.uuid === space.uuid);
  }

  get valid() {
    const vehicleValid = this.Vehicle.valid;
    const invalidSpaces = this.Spaces.filter((form) => !form.valid);
    return super.valid && vehicleValid && invalidSpaces.length === 0;
  }

  markAllAsTouched() {
    super.markAllAsTouched();
    this.Vehicle.markAllAsTouched();
    this.Spaces.forEach((form) => form.markAllAsTouched);
  }
}
