import { Load } from 'src/app/load/lib/load';
import { Vector3 } from 'three';
import { TextLabel } from './TextLabel';

export abstract class FlooringLabel extends TextLabel {
  public constructor(protected load: Load, protected textScale = 1.0) {
    super('', new Vector3(), 0, '', textScale);
    this.updateText();
    this.updateLabelPosition();
    this.updateCssClass();
  }

  protected abstract updateText(): void;

  protected abstract updateLabelPosition(): void;

  protected abstract updateCssClass(): void;
}
