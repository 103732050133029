import { AlwaysDepth, Sprite, SpriteMaterial, TextureLoader } from 'three';
import { GravityCenter } from './gravity-center';
import { Constants as Config } from 'src/app/config/constants';

export class GravityCenterSprite extends Sprite {
  private gravityCenter: GravityCenter;

  constructor(gravityCenter: GravityCenter) {
    super();
    this.gravityCenter = gravityCenter;

    const map = new TextureLoader().load(
      '/assets/images/gravity-center-sprite.png'
    );
    this.material = new SpriteMaterial({
      map,
      depthWrite: true,
      depthFunc: AlwaysDepth,
      transparent: true,
      opacity: 0.8
    });
    this.scale.set(
      Config.DIMENSION_SCALING * 200,
      Config.DIMENSION_SCALING * 200,
      1
    );
    this.name = 'gravity-center';
  }

  public getName(): string {
    return (
      '(gravity-center (' +
      this.gravityCenter.position.x +
      ',' +
      this.gravityCenter.position.y +
      ',' +
      this.gravityCenter.position.z +
      ')' +
      this.gravityCenter.weight
    );
  }
}
