import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, Subject } from 'rxjs';
import { SceneService } from '../scene/scene.service';
import { LoadComponentService } from '../services/load-component.service';
import { LoadEventObject } from './lib/load-event-object';
import { PositionerResponse } from '../lib/communication/positioner.response';
import { LoadShape } from './lib/load-shape';
import { LoadListItem } from './lib/load-list-item';

@Injectable({
  providedIn: 'root'
})
export class LoadService {
  public submitEvent: Subject<void> = new Subject<void>();
  private item = new Subject<LoadListItem>();
  private list = new BehaviorSubject<LoadListItem[]>([]);
  private selected = new BehaviorSubject<LoadListItem[]>([]);

  constructor(
    private sceneService: SceneService,
    private loadComponentService: LoadComponentService
  ) {}

  readonly loadShapes = [
    new LoadShape({
      type: 'cuboid',
      name: 'Prostopadłościan',
      icon: 'ic_cargo2.png'
    }),
    new LoadShape({
      type: 'cylinder',
      name: 'Walec',
      icon: 'ic_cargo2.png'
    }),
    new LoadShape({
      type: 'lShape',
      name: 'L-kształtny',
      icon: 'ic_cargo2.png'
    }),
    new LoadShape({
      type: 'tShape',
      name: 'T-kształtny',
      icon: 'ic_cargo2.png'
    }),
    new LoadShape({
      type: 'hShape',
      name: 'H-kształtny',
      icon: 'ic_cargo2.png'
    }),
    new LoadShape({
      type: 'hashShape',
      name: 'Hash-kształtny',
      icon: 'ic_cargo2.png'
    })
  ];

  public submitClicked(): Observable<any> {
    this.submitEvent.next();
    return new Subject<any>().asObservable();
  }

  public addLoadAmount(
    eventObject: LoadEventObject[]
  ): Observable<PositionerResponse> {
    return this.sceneService.addLoadAmount(eventObject);
  }

  public setSingleItemForScroll(item: LoadListItem) {
    this.item.next(item);
  }
  public getItemForScroll(): Observable<LoadListItem> {
    return this.item.asObservable();
  }

  public updateList(list: LoadListItem[]) {
    this.list.next(list);
  }

  public get list$(): Observable<LoadListItem[]> {
    return this.list.asObservable();
  }

  public get selected$(): Observable<LoadListItem[]> {
    return this.selected.asObservable();
  }

  public get currentSelected(): LoadListItem[] {
    return this.selected.value;
  }

  public updateSelected(list: LoadListItem[]) {
    this.selected.next(list);
    this.list.next(
      this.list.value.map((item) => {
        const found = list.find((i) => i.load.uuid === item.load.uuid);
        item.selected = !!found;
        return item;
      })
    );
  }

  public closeModal(from: string) {
    this.loadComponentService.clear(from);
  }
}
