<div
  class="help-overlay {{ service.type }}"
  *ngIf="service.active && service.overlayVisible"
  (click)="service.closeOverlay()"
></div>
<div
  class="help-container {{ service.type }}"
  *ngIf="service.active"
  [@enterLeaveAnimation]
>
  <button
    mat-button
    class="help-close"
    (click)="service.close()"
    i18n-aria-label
    aria-label="Zamknij pomoc"
  >
    <mat-icon>close</mat-icon>
  </button>

  <div [ngSwitch]="service.type">
    <ng-container *ngSwitchCase="service.currentType.Flooring">
      <app-flooring></app-flooring>
    </ng-container>
    <ng-container *ngSwitchCase="service.currentType.Protrusion">
      <app-protrusion></app-protrusion>
    </ng-container>
    <ng-container *ngSwitchCase="service.currentType.BlockRotation">
      <app-help-block-rotation></app-help-block-rotation>
    </ng-container>
    <ng-container *ngSwitchCase="service.currentType.AllowVerticalRotation">
      <app-help-vertical-rotation></app-help-vertical-rotation>
    </ng-container>
    <ng-container *ngSwitchDefault
      >..... SERVICE TYPE{{ service.type }}
    </ng-container>
  </div>
</div>
