<mat-expansion-panel (opened)="opened = true" (closed)="opened = false">
  <mat-expansion-panel-header>
    <mat-panel-title i18n>Dostosuj wystawanie</mat-panel-title>
  </mat-expansion-panel-header>
  <div [formGroup]="form" class="row">
    <mat-slider
      thumbLabel
      formControlName="protrusion"
      [displayWith]="formatSliderLabel"
      step="1"
      min="0"
      max="40"
      value="0"
      aria-label="units"
    ></mat-slider>
    <button
      mat-icon-button
      (click)="toggleHelp()"
      class="style-success"
      type="button"
    >
      <mat-icon
        aria-hidden="false"
        i18n-aria-label
        aria-label="Pomoc dotycząca wystawania"
        >help_outline</mat-icon
      >
    </button>
  </div>
</mat-expansion-panel>
