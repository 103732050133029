export class Vector {
  public x: number;
  public z: number;
  public y: number;
  public xLength: number;
  public zLength: number;
  public yLength: number;
  public occupied: boolean;
  public type: string;

  constructor(obj?: Partial<Vector>) {
    Object.assign(this, obj);
  }

  get top() {
    return this.y + this.yLength;
  }

  get front() {
    return this.x + this.xLength;
  }

  get zEnd() {
    return this.z + this.zLength;
  }

  get volume(): number {
    return this.area * this.zLength;
  }

  get area(): number {
    return this.xLength * this.yLength;
  }
}
