import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, Subject } from 'rxjs';
import { VehicleContext } from 'src/app/lib/model/vehicle-context';
import { HttpService } from 'src/app/services/http.service';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { ContextService } from 'src/app/vehicle/context/context.service';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { environment } from 'src/environments/environment';
import { FlooringLevelModalComponent } from './flooring-level-modal.component';

class UpdateCalculationRequest {
  uuid: string;
  value: any;
  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

@Injectable({
  providedIn: 'root'
})
export class FlooringLevelSettingsService extends HttpService {
  private saveUrl = environment.apiUrl + '/database/calculation/update-vehicle';
  public updateFlooringLevelInView$ = new Subject<void>();

  public get context(): VehicleContext {
    return this.contextService.getCurrentContext();
  }

  constructor(
    private loadComponentService: LoadComponentService,
    private contextService: ContextService,
    protected http: HttpClient
  ) {
    super(http);
  }

  show() {
    this.loadComponentService.clear('flooring-level-settings.service.ts show');
    this.loadComponentService.add(
      FlooringLevelModalComponent //,
      // '#scene-container'
    );
  }

  close() {
    this.loadComponentService.clear('flooring-level-settings.service.ts');
  }

  public updateVehicle(vehicle: Vehicle): Observable<Vehicle> {
    const request = new UpdateCalculationRequest();
    request.uuid = this.context.getHistoryUuid();
    request.value = vehicle;

    const httpCall = this.http
      .post<any>(this.saveUrl, request)
      .pipe(catchError(this.handleError('updateVehicle', [])));
    return httpCall;
  }
}
