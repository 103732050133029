import { Injectable } from '@angular/core';
import { LoadComponentService } from '../services/load-component.service';

@Injectable({
  providedIn: 'root'
})
export class CalculationSummaryService {
  constructor(private modalInfoService: LoadComponentService) {}

  close() {
    this.modalInfoService.clear('calculation-summary.service.ts');
  }
}
