import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonModule as VehicleCommonModule } from './common/common.module';
import { VehicleComponent } from './vehicle.component';
import { SummaryComponent } from './summary/summary.component';
import { MaterialModule } from '../material.module';
import { BusComponent } from './type/bus/bus.component';
import { TruckComponent } from './type/truck/truck.component';
import { ContainerComponent } from './type/container/container.component';
import { OtherComponent } from './type/other/other.component';
import { FormModule } from './form/form.module';
import { BrowserModule } from '@angular/platform-browser';
import { SpaceModule } from './space/space.module';
import { ContextComponent } from './context/context.component';
import { SettingsComponent } from './summary/settings/settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AxlesModule } from './axles/axles.module';
import { EditComponent } from './context/edit/edit.component';
import { WarehouseComponent } from './type/warehouse/warehouse.component';

@NgModule({
  declarations: [
    VehicleComponent,
    SummaryComponent,
    BusComponent,
    WarehouseComponent,
    TruckComponent,
    ContainerComponent,
    ContextComponent,
    OtherComponent,
    SettingsComponent,
    EditComponent
  ],
  imports: [
    CommonModule,
    VehicleCommonModule,
    BrowserModule,
    MaterialModule,
    FormModule,
    SpaceModule,
    AxlesModule,
    ReactiveFormsModule
  ],
  exports: [VehicleComponent, SummaryComponent]
})
export class VehicleModule {}
