export class TimeoutError extends Error {
  constructor() {
    super(
      $localize`Serwer nie odpowiedział w wymaganym czasie.` +
        ' ' +
        $localize`Ponów próbę lub skontaktuj się z nami na smartload@evomedia.pl jeśli problem się powtarza.`
    );
    this.name = 'TimeoutError';
  }
}
