import { Load } from '../../load/lib/load';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { PositionerRequest } from '../../lib/communication/positioner.request';
import { Vector } from '../../lib/communication/vector';
import { Constants as Config } from 'src/app/config/constants';

export class MoveLoadRequest extends PositionerRequest {
  constructor(
    loadToMove: Load,
    movement: Vector,
    context: VehicleContext
    // loadPosition: Vector
  ) {
    console.log(
      'MoveLoadRequest',
      loadToMove.position,
      loadToMove.mesh.position
    );
    const vehicle = context.getVehicle();
    vehicle.position = new Vector({
      x: context.getVehicle().mesh.position.x / Config.DIMENSION_SCALING,
      y: context.getVehicle().mesh.position.y / Config.DIMENSION_SCALING,
      z: context.getVehicle().mesh.position.z / Config.DIMENSION_SCALING
    });
    vehicle.spaces.forEach((x) => {
      x.position = new Vector({
        x: x.mesh.position.x / Config.DIMENSION_SCALING,
        y: x.mesh.position.y / Config.DIMENSION_SCALING,
        z: x.mesh.position.z / Config.DIMENSION_SCALING
      });
    });

    // console.log(
    // 'loadPosition',
    // loadPosition,
    // loadToMove.position,
    // loadToMove.mesh.position
    // );
    super({
      uuid: context.getUuid(),
      initialTimestamp: context.getInitialTimestamp(),
      message: 'test',
      // existingLoads: context.getExistingLoads(),
      loads: context.getLoads(),
      // addedLoads: [],
      vehicle,
      // matrix: context.getMatrix(),
      // maxLoadWidth: 0,
      load: loadToMove,
      action: 'move',
      vector: movement,
      orbitControlsState: context.getOrbitControlsState(),
      projectId: context.getProjectId(),
      settings: context.getSettings(),
      // vehiclePosition: new Vector(context.getVehicle().mesh.position),
      loadPosition: new Vector({
        x: loadToMove.mesh.position.x / Config.DIMENSION_SCALING,
        y: loadToMove.mesh.position.y / Config.DIMENSION_SCALING,
        z: loadToMove.mesh.position.z / Config.DIMENSION_SCALING
      })
      // loadPosition
    });
  }
}
