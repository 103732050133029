<div class="loads-list-header">
  <button
    class="change-order"
    (click)="toggleReorderMode()"
    [class.enabled]="reorderMode"
    i18n-matTooltip
    matTooltip="Zmień kolejność ładunków na liście"
    [matTooltipPosition]="'right'"
  >
    <mat-icon>sort</mat-icon>
  </button>
  <span i18n>Dodane ładunki:</span>
</div>
<div
  class="loaded"
  cdkDropList
  [cdkDropListDisabled]="!reorderMode"
  (cdkDropListDropped)="orderChanged($event)"
>
  <div
    *ngFor="let group of loads.loads"
    class="loaded-element"
    cdkDrag
    cdkDragLockAxis="y"
  >
    <app-loaded-element
      [group]="group"
      [drag]="reorderMode"
    ></app-loaded-element>
    <div class="drag-placeholder" *cdkDragPlaceholder></div>
  </div>
</div>
