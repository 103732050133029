import { Component, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.less']
})
export class NameComponent {
  @Input() mode = 'outline';
  public form: UntypedFormGroup;
  private _defaultName = '';
  @Input() set default(name: string) {
    this._defaultName = name;
    this.form.get('name').setValue(name);
  }
  get default() {
    return this._defaultName;
  }

  @Input() events: Observable<void>;
  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    this.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.onSubmit());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private onSubmit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      const errors = this.getValidationErrors();
      return false;
    }
    return true;
  }

  private getValidationErrors() {
    const errors: any = {};
    Object.keys(this.form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        errors[key] = {};
        Object.keys(controlErrors).forEach((keyError) => {
          errors[key][keyError] = controlErrors[keyError];
        });
      }
    });
    return errors;
  }

  public isValid() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  public getValue(): string {
    return this.form.get('name').value;
  }

  public setValue(val: string) {
    this.form.get('name').setValue(val);
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required])]
    });
  }
}
