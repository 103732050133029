import { Injectable } from '@angular/core';
import { Camera, Object3D, Raycaster, Vector2 } from 'three';

@Injectable({
  providedIn: 'root'
})
export class ClickHandlerService {
  private camera: Camera;
  private canvas: HTMLCanvasElement;

  private mouse: Vector2;
  private raycaster: Raycaster;
  private objects: Object3D[];

  constructor() {}

  public init(camera: Camera, canvas: HTMLCanvasElement) {
    this.camera = camera;
    this.canvas = canvas;
    this.mouse = new Vector2();
    this.raycaster = new Raycaster();
    this.objects = [];
  }

  public activate(objects: Object3D[]) {
    this.deactivate();
    this.objects = objects;
    this.canvas.addEventListener('click', this.onClick, false);
  }

  public addObjects(objects: Object3D[]) {
    this.objects.push(...objects);
  }

  public removeObject(object: Object3D) {
    this.objects = this.objects.filter((x) => x !== object);
  }

  public clearObjects() {
    this.objects = [];
  }

  private onClick = (event: MouseEvent) => {
    const rect = this.canvas.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects(this.objects);
    intersects.forEach((el) => {
      if (typeof el.object.userData.onclick !== 'undefined') {
        el.object.userData.onclick();
      }
    });
  };

  public deactivate() {
    this.canvas.removeEventListener('click', this.onClick, false);
    this.objects = [];
  }
}
