import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, Subject } from 'rxjs';
import { VehicleContext } from 'src/app/lib/model/vehicle-context';
import { HttpService } from 'src/app/services/http.service';

import { environment } from 'src/environments/environment';
import { Vehicle } from '../lib/vehicle';

class UpdateCalculationRequest {
  uuid: string;
  value: any;
  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AxlesService extends HttpService {
  private saveUrl = environment.apiUrl + '/database/calculation/update-vehicle';
  constructor(protected http: HttpClient) {
    super(http);
  }

  public updateVehicle(context: VehicleContext): Observable<Vehicle> {
    console.log(
      'axles.service.ts: updateVehicle called',
      context.getVehicle(),
      this.saveUrl
    );
    const request = new UpdateCalculationRequest();
    request.uuid = context.getHistoryUuid();
    request.value = context.getVehicle();

    const httpCall = this.http
      .post<any>(this.saveUrl, request)
      .pipe(catchError(this.handleError('updateVehicle', [])));
    return httpCall;
  }
}
