<mat-list class="select-list">
  <mat-list-item
    *ngFor="let item of items"
    (click)="select(item)"
    [ngClass]="{ selected: item?.uuid === selectedItem?.uuid }"
  >
    <h5 class="row">
      <span class="name">{{ item.fullName }}</span>
      <span>
        <ng-container *ngIf="item.type === 'warehouse'">
          {{ item | vehicleReadableSize | async }}
        </ng-container>
        <ng-container *ngIf="item.type !== 'warehouse'">
          {{ item | vehicleDimensions | async }}
        </ng-container>
        <span *ngIf="item.maxLoadingWeight">
          |
          {{ item.maxLoadingWeight | weightUnit | async | number : '0.0-2' }}
          {{ ui.getWeightUnit() | async }}
        </span>
      </span>
      <div *ngIf="listType === 'user'" class="actions">
        <button (click)="edit(item)" class="edit-btn">
          <mat-icon>edit</mat-icon>
        </button>
        <button (click)="delete(item)" class="delete-btn">✕</button>
      </div>
    </h5>
  </mat-list-item>
  <div *ngIf="items.length === 0" i18n>
    Brak przestrzeni wybranego typu. Sprawdź ustawienia swojej floty, aby
    pokazać przestrzenie.
  </div>
</mat-list>
