<div class="modal-box file-import-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n *ngIf="directory">Edytuj folder</h1>
      <h1 i18n *ngIf="!directory">Dodaj nowy folder</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <div [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
        <div>
          <mat-form-field>
            <input
              cdkFocusInitial
              matInput
              i18-placeholder
              placeholder="Nazwa"
              formControlName="name"
              autocomplete="off"
              required
            />
            <mat-error *ngIf="form.get('name').hasError('required')">
              <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
              <strong><ng-container i18n>wymagane</ng-container></strong>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="buttons">
          <button mat-raised-button (click)="onSubmit()">
            <mat-icon>save</mat-icon>
            <ng-container i18n>Zapisz</ng-container>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
