import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form/form.component';

import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule as LoadCommonModule } from '../../common/common.module';
import { SelectComponent } from './select/select.component';

@NgModule({
  declarations: [FormComponent, SelectComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LoadCommonModule
  ],
  exports: [FormComponent, SelectComponent]
})
export class LShapeModule {}
