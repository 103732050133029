<div class="label-component checkbox-list-condensed">
  <mat-checkbox
    [(ngModel)]="model.showNumber"
    name="model.showNumber"
    (change)="modelChanged()"
    i18n
    >numeracja</mat-checkbox
  >
  <mat-checkbox
    [(ngModel)]="model.showName"
    name="model.showNumber"
    (change)="modelChanged()"
    i18n
    >nazwa</mat-checkbox
  >
  <mat-checkbox
    [(ngModel)]="model.showWeight"
    name="model.showWeight"
    (change)="modelChanged()"
    i18n
    >waga</mat-checkbox
  >

  <mat-checkbox
    [(ngModel)]="model.showDimensions"
    name="model.showDimensions"
    (change)="modelChanged()"
    i18n
    >wymiary</mat-checkbox
  >
  <mat-checkbox
    [(ngModel)]="model.showFlooring"
    name="model.showFlooring"
    (change)="modelChanged()"
    i18n
    >piętrowanie</mat-checkbox
  >

  <!-- tu nie powinno to byc bo to nie dotyczy ladunku tylko przestrzeni-->
  <mat-checkbox
    [(ngModel)]="model.showGravityCenter"
    name="model.showGravityCenter"
    (change)="modelChanged()"
    i18n
    >środek ciężkości</mat-checkbox
  >

  <mat-icon title="Mniejsze etykiety" (click)="fontDecrease()"
    >text_decrease</mat-icon
  >
  <mat-icon title="Większe etykiety" (click)="fontIncrease()"
    >text_increase</mat-icon
  >
</div>
