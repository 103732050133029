//import THREE = require("three");

import { SpaceMesh } from '../../../lib/space-mesh';
import { Box } from './box';
import {
  BufferGeometry,
  EdgesGeometry,
  Float32BufferAttribute,
  Line,
  LineBasicMaterial,
  LineDashedMaterial,
  LineSegments,
  Mesh,
  Vector3
} from 'three';
import { Constants } from 'src/app/vehicle/lib/constants';
import { Constants as Config } from 'src/app/config/constants';

export class BoxMesh extends SpaceMesh {
  protected length: number;
  protected height: number;
  protected width: number;
  protected indices = [];
  protected vertices = [];

  public constructor(box: Box) {
    super('box', box);
    console.log('box.mesh constructor called');
    this.length = box.length * Config.DIMENSION_SCALING;
    this.width = box.width * Config.DIMENSION_SCALING;
    this.height = box.height * Config.DIMENSION_SCALING;
    this.init();
  }

  public getLength(): number {
    return this.length * 1;
  }

  public getWidth(): number {
    return this.width * 1;
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getName(): string {
    return '(' + this.length + 'x' + this.width + 'x' + this.height + ')';
  }

  private init() {
    this.meshObj.geometry = new BufferGeometry();
    console.log('init - get buffer geometry called');
    this.getBufferGeometry();
  }

  protected getBufferGeometry() {
    console.log('getBufferGeometry box mesh');
    const x0 = 0;
    const y0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const y1 = y0 + this.height;
    const z1 = z0 + this.width;
    let groupStart = 0;
    let groupCount = 0;
    // loading space
    this.vertices.push(x1, y1, z1); // 0 right top front
    this.vertices.push(x1, y1, z0); // 1 right top back
    this.vertices.push(x1, y0, z0); // 2 right bottom back
    this.vertices.push(x1, y0, z1); // 3 right bottom front
    this.vertices.push(x0, y0, z1); // 4 left bottom front
    this.vertices.push(x0, y1, z1); // 5 left top front
    this.vertices.push(x0, y1, z0); // 6 left top back
    this.vertices.push(x0, y0, z0); // 7 left bottom back

    if (this.space.enabled) {
      // loading space
      this.indices.push(1, 2, 3); // doors bottom triangle
      this.indices.push(0, 1, 3); // doors top triangle

      this.indices.push(3, 4, 5); // front side bottom triangle
      this.indices.push(0, 3, 5); // front side top triangle

      this.indices.push(1, 2, 7); // back side bottom triangle
      this.indices.push(1, 7, 6); // back side top triangle

      this.indices.push(0, 1, 5); // roof front triangle
      this.indices.push(1, 6, 5); // roof back triangle

      this.indices.push(4, 5, 7); // cabin left bottom triangle
      this.indices.push(5, 6, 7); // cabin left top triangle
      this.indices.push(2, 3, 4, 2, 4, 7); // floor disabled

      groupCount = 6 * 6;

      this.meshObj.geometry.addGroup(groupStart, groupCount, 0);
      groupStart += groupCount;
    } else {
      this.indices.push(2, 3, 4, 2, 4, 7); // floor disabled
      groupCount = 6;
      this.meshObj.geometry.addGroup(groupStart, groupCount, 5);
      groupStart += groupCount;
    }

    this.meshObj.geometry.setIndex(this.indices);
    this.meshObj.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.meshObj.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.meshObj.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.meshObj.add(wireframe);

    if (!this.space.enabled) {
      const m = Constants.DISABLED_SPACE_LINE_MATERIAL;

      const lines = [];
      /*lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x1, y1, z1),
            new Vector3(x0, y1, z0)
          ]),
          m
        )
      );
      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x1, y1, z0),
            new Vector3(x0, y1, z1)
          ]),
          m
        )
      );*/

      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x1, y0, z1),
            new Vector3(x0, y0, z0)
          ]),
          m
        )
      );
      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x1, y0, z0),
            new Vector3(x0, y0, z1)
          ]),
          m
        )
      );
      /*
      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x0, y1, z0),
            new Vector3(x1, y0, z0)
          ]),
          m
        )
      );

      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x0, y0, z0),
            new Vector3(x1, y1, z0)
          ]),
          m
        )
      );

      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x0, y1, z1),
            new Vector3(x1, y0, z1)
          ]),
          m
        )
      );

      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x0, y0, z1),
            new Vector3(x1, y1, z1)
          ]),
          m
        )
      );

      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x0, y0, z0),
            new Vector3(x0, y1, z1)
          ]),
          m
        )
      );

      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x0, y0, z1),
            new Vector3(x0, y1, z0)
          ]),
          m
        )
      );

      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x1, y0, z0),
            new Vector3(x1, y1, z1)
          ]),
          m
        )
      );

      lines.push(
        new Line(
          new BufferGeometry().setFromPoints([
            new Vector3(x1, y0, z1),
            new Vector3(x1, y1, z0)
          ]),
          m
        )
      );*/
      this.meshObj.add(...lines);
    }
  }
}
