import { Load } from 'src/app/load/lib/load';

export type LoadGroupType = new (load: Load, cnt: number) => LoadGroup;

export class LoadGroup {
  public load: Load;
  public cnt: number;
  public list: Array<Load>;
  public expanded = false;

  constructor(load: Load, cnt: number = 1) {
    this.load = load;
    this.cnt = cnt;
    this.list = new Array();
    this.list.push(load);
  }

  public addLoad(load: Load, cnt: number = 1): LoadGroup {
    this.cnt += cnt;
    while (cnt-- > 0) {
      this.list.push(load);
    }
    return this;
  }

  public updateCount(cnt: number): LoadGroup {
    if (cnt > this.cnt) {
      let diff = cnt - this.cnt;
      this.cnt = cnt;
      while (diff-- > 0) {
        this.list.push(this.load);
      }
    } else if (cnt < this.cnt) {
      this.cnt = cnt;
      this.list = this.list.slice(0, cnt);
    }
    return this;
  }

  public isFloorableBoth() {
    return this.load?.floorableBottom && this.load?.floorableTop;
  }

  public isFloorableOnlyTop() {
    return !this.load?.floorableBottom && this.load?.floorableTop;
  }

  public isFloorableOnlyBottom() {
    return this.load?.floorableBottom && !this.load?.floorableTop;
  }

  public isNonFloorable() {
    return !this.load?.floorableBottom && !this.load?.floorableTop;
  }

  public has(aLoad: Load) {
    const sameBoxes =
      //aLoad.uuid === this.load.uuid &&
      aLoad.name === this.load.name &&
      aLoad.color === this.load.color &&
      aLoad.floorableTop === this.load.floorableTop &&
      aLoad.floorableBottom === this.load.floorableBottom &&
      aLoad.weight === this.load.weight &&
      aLoad.volume === this.load.volume;

    return sameBoxes;
  }
}
