import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ContextMenuServiceCommunicationModel } from './lib/context-menu-service-communication-model';
import { ContextMenuActionType } from './lib/context-menu-action-type.enum';
import { ChangeLoadSize } from './lib/change-load-size.type';
import { ChangeFlooring } from './lib/change-flooring.type';
import { Load } from '../load/lib/load';

@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {
  private model = new Subject<ContextMenuServiceCommunicationModel>();
  private object: ContextMenuServiceCommunicationModel;

  constructor() {}

  public open(loads: Load[], event: MouseEvent) {
    this.object = new ContextMenuServiceCommunicationModel();
    this.object.selectedLoads = loads;
    this.object.opened = true;
    this.object.event = event;
    this.object.action = ContextMenuActionType.open;
    this.yield();
  }

  public close() {
    if (this.object !== undefined && this.object.opened) {
      this.object.action = ContextMenuActionType.close;
      this.object.opened = false;
      this.yield();
      // this.object.action = ContextMenuActionType.close;
    }
  }

  public yield() {
    this.model.next(this.object);
  }

  public modelChanged(): Observable<ContextMenuServiceCommunicationModel> {
    return this.model.asObservable();
  }

  public rotateHorizontal() {
    this.object.action = ContextMenuActionType.rotateHorizontal;
    // this.sceneService.rotateHorizontal(this.object.selectedLoad.load);
    this.object.opened = false;
    this.yield();
  }

  public rotateVertical() {
    this.object.action = ContextMenuActionType.rotateVertical;
    // this.sceneService.rotateVertical(this.object.selectedLoad.load);
    this.object.opened = false;
    this.yield();
  }

  public changeColor(value: number) {
    this.object.action = ContextMenuActionType.changeColor;
    this.object.value = value;
    this.object.opened = false;
    this.yield();
  }

  public changeSize(data: ChangeLoadSize) {
    this.object.action = ContextMenuActionType.changeSize;
    this.object.value = data;
    this.object.opened = false;
    this.yield();
  }

  public changeFlooring(data: ChangeFlooring) {
    this.object.action = ContextMenuActionType.changeFlooring;
    this.object.value = data;
    this.object.opened = false;
    this.yield();
  }

  public copyLoad(data: { idx: any; cnt: number }) {
    this.object.action = ContextMenuActionType.copy;
    this.object.value = data;
    this.object.opened = false;
    this.yield();
  }

  public deleteLoad() {
    this.object.action = ContextMenuActionType.delete;
    this.object.opened = false;
    this.yield();
  }
}
