import { LoadFactory } from 'src/app/load/lib/load-factory';
import { Space } from '../space';

export abstract class CuboidSpace extends Space {
  shape = 'cuboid';
  epCnt: number;
  maxWeight: number;

  width: number;
  length: number;
  height: number;
  enabled: true;

  constructor(obj: unknown, loadFactory: LoadFactory) {
    super(obj, loadFactory);
  }

  get capacity() {
    return this.length * this.width * this.height;
  }

  get area() {
    return this.length * this.width;
  }

  get dimensions(): number[] {
    return [this.length, this.width, this.height];
  }

  get identity(): string {
    const length = this.length > this.width ? this.length : this.width;
    const width = this.length < this.width ? this.length : this.width;

    return (
      this.type +
      '[' +
      length +
      'x' +
      width +
      'x' +
      this.height +
      ',' +
      this.maxWeight +
      ']'
    );
  }
}
