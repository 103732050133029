import { KeyValue } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { FleetForm } from './lib/fleet.form';
import { UiService } from 'src/app/services/ui.service';

type VehicleTypes = { [key: string]: any };

@Component({
  selector: 'app-fleet-select-form',
  templateUrl: './fleet-select-form.component.html',
  styleUrls: ['./fleet-select-form.component.less']
})
export class FleetSelectFormComponent {
  protected SystemTypes: VehicleTypes = {
    bus: { name: $localize`Busy` },
    tandem: { name: $localize`Tandemy` },
    mega: { name: $localize`Mega` },
    solo: { name: $localize`Solówki` },
    container: { name: $localize`Kontenery` },
    standard: { name: $localize`Firanki / plandeki` },
    pallet: {
      name: $localize`Palety`,
      help: $localize`Paleta jako przestrzeń ładunkowa`
    }
  };
  protected CustomTypes = { custom: { name: $localize`Własne` } };

  protected form: FleetForm;

  protected originalKeysOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => 0;

  constructor(
    fb: FormBuilder,
    profileService: ProfileService,
    uiService: UiService
  ) {
    const selected = profileService.currentSettings.usedVehicles || [];

    if (uiService.warehousesEnabled) {
      this.SystemTypes.warehouse = {
        name: $localize`Magazyny`
      };
    }

    const types = Object.keys(this.SystemTypes).map((type) => ({
      value: type,
      name: this.SystemTypes[type].name,
      selected: selected.includes(type)
    }));
    console.log(types);
    this.form = new FleetForm(
      Object.keys(this.SystemTypes).map((type) => ({
        value: type,
        name: this.SystemTypes[type].name,
        selected: selected.includes(type)
      })),
      Object.keys(this.CustomTypes).map((type) => ({
        value: type,
        name: this.CustomTypes[type].name,
        selected: selected.includes(type)
      })),
      fb
    );
    console.log(Object.keys(this.form.SystemTypes.controls));
  }

  updateValues(selected: string[]) {
    this.form.updateValues(selected);
  }

  selectAll() {
    this.form.updateValues(
      Object.keys(this.SystemTypes).concat(Object.keys(this.CustomTypes))
    );
  }

  getValues(): string[] {
    return this.form.getSelectedTypes();
  }

  isValid() {
    return this.form.valid;
  }
}
