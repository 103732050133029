import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadService } from '../lib/load.service';
import { Load } from './load';
import { LoadFactory } from './load-factory';

@Injectable({
  providedIn: 'root'
})
export class CustomLoadService extends LoadService {
  private baseUrl = environment.apiUrl + '/database/profile/custom-load';
  private projectId: string;
  private deleteManyUrl: string;
  constructor(protected http: HttpClient, loadFactory: LoadFactory) {
    super(http, loadFactory);
    this.url = this.baseUrl;
    this.listUrl = this.url + '/list';
    this.deleteManyUrl = this.url + '/delete-many';
    this.sortByProp = 'createdAt';
  }

  public updateCurrentProject(uuid: string) {
    this.projectId = uuid;
    this.listUrl = this.url + '/list/' + (uuid ?? '');
    if (uuid) {
      this.refresh();
    }
  }

  public create(item: Load): Observable<void> {
    item.projectId = this.projectId;
    return this.http
      .post<string>(this.url, item)
      .pipe(map(() => this.update(true)));
  }

  public delete(item: Load): Observable<void> {
    // console.log('custom-load.service.ts in cuboid', item);
    const uuid = item.uuid;
    return this.http
      .delete<Load>(this.url + '/' + uuid)
      .pipe(map(() => this.update(true)));
  }

  public deleteMany(items: Load[]): Observable<void> {
    const uuids = items.map((l) => l.uuid);
    return this.http
      .post<string>(this.deleteManyUrl, uuids)
      .pipe(map(() => this.update(true)));
  }
}
