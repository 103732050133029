<div [ngSwitch]="shape.type">
  <ng-container *ngSwitchCase="'cuboid'">
    <app-cuboid-select
      (itemSelectedEvent)="itemSelected($event)"
    ></app-cuboid-select>

    <div class="row gap">
      <app-amount #amount></app-amount>
      <app-weight #weight></app-weight>
    </div>
    <app-cuboid-form
      [events]="eventsSubject.asObservable()"
      (addLoadEvent)="addLoad($event)"
    ></app-cuboid-form>
  </ng-container>
  <ng-container *ngSwitchCase="'cylinder'">
    <app-cylinder-select></app-cylinder-select>
    <div class="row">
      <app-amount #amount></app-amount>
      <app-weight #weight></app-weight>
    </div>
    <app-cylinder-form
      [events]="eventsSubject.asObservable()"
      (addLoadEvent)="addLoad($event)"
    ></app-cylinder-form>
  </ng-container>
  <ng-container *ngSwitchCase="'lShape'">
    <app-lshape-select></app-lshape-select>
    <div class="row">
      <app-amount #amount></app-amount>
      <app-weight #weight></app-weight>
    </div>
    <app-lshape-form
      [events]="eventsSubject.asObservable()"
      (addLoadEvent)="addLoad($event)"
    ></app-lshape-form>
  </ng-container>
  <ng-container *ngSwitchCase="'tShape'">
    <app-tshape-select></app-tshape-select>
    <div class="row">
      <app-amount #amount></app-amount>
      <app-weight #weight></app-weight>
    </div>
    <app-tshape-form
      [events]="eventsSubject.asObservable()"
      (addLoadEvent)="addLoad($event)"
    ></app-tshape-form>
  </ng-container>
  <ng-container *ngSwitchCase="'hShape'">
    <app-hshape-select></app-hshape-select>
    <div class="row">
      <app-amount #amount></app-amount>
      <app-weight #weight></app-weight>
    </div>
    <app-hshape-form
      [events]="eventsSubject.asObservable()"
      (addLoadEvent)="addLoad($event)"
    ></app-hshape-form>
  </ng-container>
  <ng-container *ngSwitchCase="'hashShape'">
    <app-hash-shape-select></app-hash-shape-select>
    <div class="row">
      <app-amount #amount></app-amount>
      <app-weight #weight></app-weight>
    </div>
    <app-hash-shape-form
      [events]="eventsSubject.asObservable()"
      (addLoadEvent)="addLoad($event)"
    ></app-hash-shape-form>
  </ng-container>
  <ng-container *ngSwitchDefault>...{{ shape.type }}</ng-container>
</div>

<app-color #color></app-color>
<app-flooring #flooring></app-flooring>

<app-settings #settings></app-settings>
<div class="text-center mt-1">
  <button
    mat-raised-button
    class="btn-outlined"
    type="submit"
    (click)="onSubmit()"
    [disabled]="load === null"
    i18n
  >
    Załaduj
  </button>
</div>
