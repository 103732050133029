import { v4 as uuidv4 } from 'uuid';
import { SpaceMesh } from './space-mesh';
import { FlooringLevelSettings } from '../flooring-level/lib/flooring-level-settings';
import { Axle } from '../../axles/lib/axle';
import { Load } from 'src/app/load/lib/load';
import { Settings } from '../../form/settings/settings';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { Vector } from 'src/app/lib/communication/vector';
import { Point } from './point';
import { Constants as Config } from 'src/app/config/constants';

type DataPoint = { name: string; value: Number };

export abstract class Space {
  uuid: string;
  name: string;
  shape: string;
  type: string;
  model: string;

  width: number;
  height: number;
  length: number;
  maxLoadingWeight: number;

  createdAt?: Date;
  updatedAt?: Date;

  axles = new Array<Axle>();
  order: number;
  settings: FlooringLevelSettings;
  #calculationSettings: Settings;

  #mesh: SpaceMesh;

  loads: Array<Load> = new Array<Load>();

  position: Vector; //only for communication (moveloadrequest)
  matrix: Vector[];

  offset: Point;
  enabled = true;

  constructor(obj: any, loadFactory: LoadFactory) {
    console.log('space.enabled', obj.enabled);
    Object.assign(this, obj);
    if (this.uuid === undefined) {
      this.uuid = uuidv4();
    }
    this.settings = new FlooringLevelSettings(obj?.settings ?? {});
    this.#calculationSettings = new Settings(obj?.calculationSettings ?? {});

    this.axles = (this.axles || []).map((axle) => new Axle(axle));
    this.loads = (this.loads || []).map((load) =>
      loadFactory.recreateLoad(load)
    );
    //console.log(this.loads);

    if (this.loads.length > 0) {
      // console.log(
      // 'space.ts:',
      // this.loads[0].uuid,
      // this.loads[0],
      // this.loads[0].mesh
      // );
    }
    this.matrix = (this.matrix || []).map((v) => new Vector(v));
  }

  abstract get fullName(): string;
  abstract get volume(): number;
  abstract get area(): number;
  abstract get fullDescription(): string;
  abstract get dimensions(): number[];

  get mesh(): SpaceMesh {
    return this.#mesh;
  }

  protected set mesh(mesh: SpaceMesh) {
    this.#mesh = mesh;
  }

  get maxLength() {
    return this.length;
  }

  updateCalculationSettings(settings: Settings) {
    this.#calculationSettings = settings;
    console.log('update space settings', this.#calculationSettings);
    if (this.enabled) {
      this.mesh.redrawGrid();
      this.mesh.showGrid(this.#calculationSettings.grid.show);
    }
  }

  /**
   * //FIXME - to raczej powinno trafić na serwer
   * @param oldSize
   */
  public updateMatrixToFitSize(oldSize?: Vector) {
    this.matrix.forEach((v) => {
      if (v.front > this.length) {
        v.xLength = this.length - v.x;
      }
      if (v.top > this.height) {
        v.yLength = this.height - v.y;
      }
      if (v.zEnd > this.width) {
        v.zLength = this.width - v.z;
      }
      if (oldSize) {
        if (v.front === oldSize.xLength && oldSize.xLength < this.length) {
          v.xLength += this.length - oldSize.xLength;
        }
        if (v.zEnd === oldSize.zLength && oldSize.zLength < this.width) {
          v.zLength += this.width - oldSize.zLength;
        }
        if (v.top === oldSize.yLength && oldSize.yLength < this.height) {
          v.yLength += this.height - oldSize.yLength;
        }
      }
    });
  }

  get calculationSettings() {
    return this.#calculationSettings;
  }

  public generateUuid() {
    this.uuid = uuidv4();
  }

  public asVector(): Vector {
    return new Vector({
      x: this.mesh.position.x,
      y: this.mesh.position.y,
      z: this.mesh.position.z,
      xLength: this.length * Config.DIMENSION_SCALING,
      yLength: this.height * Config.DIMENSION_SCALING,
      zLength: this.width * Config.DIMENSION_SCALING
    });
  }

  public getMassDistributionData() {
    const data: DataPoint[] = [];
    const dataByX: { [key: string]: Number } = {};
    this.loads.forEach((load) => {});
  }
}
