import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { VehicleService as VehicleUiService } from '../../vehicle.service';
import { VehicleType } from '../../lib/vehicle-type';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.less']
})
export class TypeComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup) tabs: MatTabGroup;
  @Input() items: Array<VehicleType>;
  protected currentTabIndex = 0;

  private unsubscribe$ = new Subject<void>();

  constructor(private vehicleUiService: VehicleUiService) {}

  ngOnInit(): void {
    this.vehicleUiService.vehicleType$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((type) => {
        this.currentTabIndex = type
          ? this.items.findIndex((item) => item.type === type.type)
          : 0;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public select(name: string): void {
    const item = this.items.find((x) => x.type === name);
    this.vehicleUiService.selectVehicleType(item);
  }

  tabChanged(event: MatTabChangeEvent) {
    const type = this.items[event.index];
    if (type) {
      this.select(type.type);
    }
  }

  onShow() {
    this.tabs.realignInkBar();
  }
}
