import { ElementRef, Injectable } from '@angular/core';
import { InfoType } from './lib/info-type';

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  public active = false;
  public overlayVisible = true;
  public type: InfoType;
  public currentType: typeof InfoType;
  private attachedToElement?: ElementRef;

  public constructor() {
    this.currentType = InfoType;
  }

  toggle(type: InfoType, element?: ElementRef) {
    if (this.type === type) {
      this.close();
    } else {
      this.open(type, element);
    }
  }

  open(type: InfoType, attachTo?: ElementRef) {
    this.type = type;
    this.active = true;
    this.attachedToElement = attachTo;
    attachTo?.nativeElement?.classList.add('with-help');
  }

  close() {
    this.active = false;
    this.type = null;
    this.closeOverlay();
  }

  closeOverlay() {
    this.overlayVisible = false;
    this.attachedToElement?.nativeElement?.classList.remove('with-help');
  }
}
