import { NgModule } from '@angular/core';
import { CommonModule as AngularCommon } from '@angular/common';
import { VehicleDimensionsPipe } from './pipe/vehicle-dimensions.pipe';
import { DirectivesModule } from '../../directives/directives.module';
import { OnlyNumberDirective } from 'src/app/directives/only-number.directive';
import { LengthUnitPipe } from 'src/app/directives/length-unit.pipe';
import { WeightUnitPipe } from 'src/app/directives/weight-unit.pipe';
import { VehicleReadableSizePipe } from './pipe/vehicle-readable-size.pipe';

@NgModule({
  declarations: [VehicleDimensionsPipe, VehicleReadableSizePipe],
  imports: [AngularCommon, DirectivesModule],
  exports: [
    LengthUnitPipe,
    WeightUnitPipe,
    VehicleDimensionsPipe,
    VehicleReadableSizePipe,
    OnlyNumberDirective
  ]
})
export class CommonModule {}
