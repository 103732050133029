import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Load } from 'src/app/load/lib/load';
// import { Cuboid } from 'src/app/lib/model/cuboid';

import { CustomLoadService } from '../../../lib/custom-load.service';
import { Cuboid } from '../lib/cuboid';
import { LoadService } from '../../../lib/load.service';
import { LoadService as LoadUiService } from '../../../load.service';
import { LoadListItem } from 'src/app/load/lib/load-list-item';

@Component({
  selector: 'app-cuboid-select',
  templateUrl: './select.component.html',
  styleUrls: ['../../../load.component.less', './select.component.less']
})
export class SelectComponent implements OnInit, OnDestroy {
  @Output() itemSelectedEvent = new EventEmitter<Cuboid>();
  private standardItems: Cuboid[] = [];
  private customItems: Cuboid[] = [];
  public get items(): Cuboid[] {
    return this.standardItems
      .concat(this.customItems)
      .sort((x, y) => (x < y ? 1 : -1));
  }
  public listOpened = false;
  public item: Cuboid;
  public searchText: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: LoadService,
    private loadUiService: LoadUiService,
    private customLoadService: CustomLoadService
  ) {}

  ngOnInit(): void {
    this.service.loads$.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      this.standardItems = x
        .filter((l: Load) => l.shape === 'cuboid')
        .map((l: Load) => l as Cuboid);
    });

    this.customLoadService.loads$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list: Load[]) => {
        this.customItems = list
          .filter((l: Load) => l.shape === 'cuboid')
          .map((l: Load) => l as Cuboid);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public toggleList() {
    this.listOpened = !this.listOpened;
    if (this.listOpened) {
      setTimeout(() => {
        // this.searchElement.nativeElement.focus();
      }, 0);
    }
  }

  public onSearchText() {}

  public select(item: Cuboid): void {
    this.item = item;
    this.loadUiService.updateSelected([new LoadListItem(item)]);
    this.itemSelectedEvent.emit(item);
  }
}
