<div class="modal-box file-import-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Dodaj komentarz do projektu</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <app-project-comment-form
        class="in-modal"
        [showFormElements]="true"
        rows="8"
        (saved)="saved()"
      ></app-project-comment-form>
    </div>
  </div>
</div>
