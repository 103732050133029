<div [formGroup]="form">
  <div class="row">
    <div class="help-block" i18n>
      Typowe, najczęściej występujące przestrzenie ładunkowe. Mają przypisane
      rozmiary, które możesz zmienić.
    </div>
    <div class="fleet-types" [formGroup]="form.SystemTypes">
      <div
        *ngFor="
          let item of form.SystemTypes.controls | keyvalue : originalKeysOrder
        "
      >
        <mat-checkbox [formControl]="item.value">
          {{ SystemTypes[item.key].name }}</mat-checkbox
        >
        <div class="help" *ngIf="SystemTypes[item.key].help">
          {{ SystemTypes[item.key].help }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="help-block" i18n>
      Własne - samodzielnie tworzysz pojazd od podstaw - nazwa, wymiary i
      ładowność.
    </div>
    <div class="fleet-types">
      <div
        *ngFor="
          let item of form.CustomTypes.controls | keyvalue : originalKeysOrder
        "
      >
        <mat-checkbox [formControl]="item.value">
          {{ CustomTypes[item.key].name }}</mat-checkbox
        >
        <div class="help" *ngIf="CustomTypes[item.key].help">
          {{ CustomTypes[item.key].help }}
        </div>
      </div>
    </div>
  </div>
</div>
