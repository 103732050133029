import { Injectable } from '@angular/core';
import { Message, SystemMessage } from './message';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private criticalError = new Subject<Message>();
  private system = new Subject<SystemMessage>();
  private snackBarDurationInSeconds = 5;

  private snackbarConfigSuccess: MatSnackBarConfig = {
    panelClass: ['style-success'],
    duration: this.snackBarDurationInSeconds * 1000
  };

  private snackbarConfigError: MatSnackBarConfig = {
    panelClass: ['style-error'],
    duration: this.snackBarDurationInSeconds * 1000
  };

  constructor(private toastr: ToastrService, private snackBar: MatSnackBar) {}

  public success(message: Message, options?: Partial<IndividualConfig>) {
    const opts = { enableHtml: true, ...options };
    this.toastr.success(message.body, message.title, opts);
  }

  public error(message: Message) {
    this.toastr.error(message.body, message.title, {
      enableHtml: true,
      disableTimeOut: true
    });
  }

  public info(message: Message) {
    this.toastr.info(message.body, message.title, { enableHtml: true });
  }

  public critical(message: Message) {
    this.criticalError.next(message);
  }

  public systemMessage(type: string, context?: any) {
    const message = new SystemMessage(type, context);
    this.system.next(message);
  }

  public getCritical(): Observable<Message> {
    return this.criticalError.asObservable();
  }

  public getSystemMessage(): Observable<SystemMessage> {
    return this.system.asObservable();
  }

  public snackSuccess(message: Message) {
    this.snackBar.open(
      message.title,
      message.actionText || 'OK',
      this.snackbarConfigSuccess
    );
  }

  public snackError(message: Message) {
    this.snackBar.open(
      message.title,
      message.actionText || 'OK',
      this.snackbarConfigError
    );
  }
}
