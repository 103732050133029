import { Component, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.less']
})
export class NameComponent {
  private _defaultName = '';
  @Input() set default(name: string) {
    this._defaultName = name;
    this.form.get('name').setValue(name);
  }
  get default() {
    return this._defaultName;
  }
  public form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.createForm();
  }

  public getValue(): string {
    return this.form.get('name').value;
  }

  public validateForm(): boolean {
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.compose([Validators.required])]
    });
  }
}
