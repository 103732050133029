import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Load } from '../../load/lib/load';

import { Space } from './lib/space';
import { SpaceType } from './lib/space-type';
import { SpaceService } from './space.service';

@Component({
  selector: 'app-space',
  templateUrl: './space.component.html',
  styleUrls: ['./space.component.less']
})
export class SpaceComponent implements OnInit, OnDestroy {
  @Input() space: Space;
  @Input() events: Observable<void>;

  @Output() addSpaceEvent = new EventEmitter<Space>();

  public saveForm: UntypedFormGroup;
  public fb: UntypedFormBuilder = new UntypedFormBuilder();

  private unsubscribe$ = new Subject<void>();

  constructor(private service: SpaceService) {
    this.saveForm = this.fb.group({
      name: [null, Validators.required]
    });
  }
  ngOnInit(): void {
    console.log('space.component.ts:ngOnInit:space', this.space);

    this.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.onSubmit());
  }

  onSubmit() {
    console.log('space.component.ts:onSubmit');
    // console.log('space.component.ts onSubmit');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  confirmSpaceDimensions(space: Space) {
    console.log('space.component.ts:confirmSpaceDimensions', space);
    this.addSpaceEvent.emit(space);
  }
}
