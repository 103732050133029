/* eslint-disable @typescript-eslint/naming-convention */
import { environment } from './../../../environments/environment';

export interface User {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  preferred_username: string;
  sub: string;
  roles: Array<string>;
  resource_access?: Map<string, any>;

  hasRole(role: string): boolean;
  fullName(): string;
  get isPlusMember(): boolean;
}
export class UserModel implements User {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  preferred_username: string;
  sub: string;
  roles: Array<string>;
  resource_access?: Map<string, any>;

  constructor(data?: any) {
    Object.assign(this, data);
    if (data.resource_access && data.resource_access[environment.authClient]) {
      this.roles = data.resource_access[environment.authClient].roles || [];
    } else {
      this.roles = [];
    }
  }

  fullName(): string {
    return `${this.given_name} ${this.family_name}`;
  }

  hasRole(role: string): boolean {
    return this.roles.includes(role);
  }

  get isPlusMember(): boolean {
    return this.hasRole('ROLE_MEMBER_PLUS');
  }
}
