export enum SystemSpaceType {
  bus,
  tandem,
  mega,
  solo,
  container,
  standard,
  pallet,
  warehouse
}

export enum CustomSpaceType {
  custom
}
