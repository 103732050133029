<div
  [formGroup]="form"
  novalidate
  (ngSubmit)="onSubmit()"
  class="load-form full-width"
>
  <br />

  <mat-expansion-panel
    #dataPanel
    (opened)="dataPanelOpenState = true"
    (closed)="dataPanelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Dostosuj wymiary</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field>
      <input
        matInput
        placeholder="Nazwa"
        formControlName="item"
        autocomplete="off"
      />
      <mat-error *ngIf="form.controls['item'].hasError('required')">
        Pole <strong>wymagane</strong>
      </mat-error>
    </mat-form-field>
    <div class="row">
      <mat-form-field>
        <input
          matInput
          placeholder="a ({{ ui.getLengthUnit() | async }})"
          formControlName="a"
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['a'].hasError('required')">
          Pole <strong>wymagane</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="b ({{ ui.getLengthUnit() | async }})"
          formControlName="b"
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['b'].hasError('required')">
          Pole <strong>wymagane</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="c ({{ ui.getLengthUnit() | async }})"
          formControlName="c"
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['c'].hasError('required')">
          Pole <strong>wymagane</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="d ({{ ui.getLengthUnit() | async }})"
          formControlName="d"
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['d'].hasError('required')">
          Pole <strong>wymagane</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          placeholder="e ({{ ui.getLengthUnit() | async }})"
          formControlName="e"
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['e'].hasError('required')">
          Pole <strong>wymagane</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          placeholder="f ({{ ui.getLengthUnit() | async }})"
          formControlName="f"
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['f'].hasError('required')">
          Pole <strong>wymagane</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <input
          matInput
          placeholder="Wysokość ({{ ui.getLengthUnit() | async }})"
          formControlName="height"
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['height'].hasError('required')">
          Pole <strong>wymagane</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</div>
