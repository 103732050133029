<div class="orbit-controls">
  <div class="rotate text-center">
    <div>
      <button mat-raised-button color="default" (click)="move('down')">
        <mat-icon class="center-vertical">keyboard_arrow_up</mat-icon>
      </button>
    </div>

    <div class="row">
      <button mat-raised-button color="default" (click)="move('right')">
        <mat-icon class="center-vertical">keyboard_arrow_left</mat-icon>
      </button>
      <button mat-raised-button color="default" (click)="move('left')">
        <mat-icon class="center-vertical">keyboard_arrow_right</mat-icon>
      </button>
    </div>

    <div>
      <div>
        <button mat-raised-button color="default" (click)="move('up')">
          <mat-icon class="center-vertical">keyboard_arrow_down</mat-icon>
        </button>
      </div>
    </div>

    <div class="zoom">
      <div>
        <button mat-raised-button color="default" (click)="dolly('in')">
          <mat-icon class="center-vertical">add</mat-icon>
        </button>
      </div>
      <div>
        <button mat-raised-button color="default" (click)="dolly('out')">
          <mat-icon class="center-vertical">remove</mat-icon>
        </button>
      </div>
    </div>

    <div>
      <button mat-raised-button color="default" (click)="rotate('up')">
        <mat-icon class="rotate-up">360</mat-icon>
      </button>
    </div>

    <div class="row">
      <button mat-raised-button color="default" (click)="rotate('left')">
        <mat-icon class="rotate-left">360</mat-icon>
      </button>
      <button mat-raised-button color="default" (click)="rotate('right')">
        <mat-icon class="rotate-right">360</mat-icon>
      </button>
    </div>

    <div>
      <button mat-raised-button color="default" (click)="rotate('down')">
        <mat-icon class="rotate-down">360</mat-icon>
      </button>
    </div>
  </div>
</div>
