import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { LoadComponentService } from '../../services/load-component.service';
import { Space } from './lib/space';

@Injectable({
  providedIn: 'root'
})
export class SpaceService {
  public submitEvent: Subject<void> = new Subject<void>();
  private space = new BehaviorSubject<Space>(null);

  constructor(private loadComponentService: LoadComponentService) {}

  public submitClicked(): Observable<any> {
    this.submitEvent.next();
    return new Subject<any>().asObservable();
  }

  public spaceChanged(): Observable<Space> {
    return this.space.asObservable();
  }

  public addSpace(space: Space) {
    this.space.next(space);
  }

  public setItem(item: Space) {
    this.space.next(item);
  }
  public getItem(): Observable<Space> {
    return this.space.asObservable();
  }

  public create(load: Space) {
    // this.customSpaceService.create(load).then(() => {
    // this.closeModal('load.service.ts: create');
    // });
  }

  public delete(load: Space) {
    // console.log('delete custom load', load);
  }

  public edit(load: Space) {
    // console.log('edit custonm load', load);
  }

  public closeModal(from: string) {
    this.loadComponentService.clear(from);
  }
}
