<div class="list">
  <div class="row" *ngFor="let report of reports$ | async">
    <div class="fill">
      <div class="name">{{ report.name }}</div>
      <div class="text-small">
        {{ report.createdAt | date : 'yyyy-MM-dd HH:mm' }}
        (<span [ngSwitch]="report.loadsCount">
          <span *ngSwitchCase="1">1 ładunek</span>
          <span *ngSwitchCase="2 || 3 || 4"
            >{{ report.loadsCount }} ładunki</span
          >
          <span *ngSwitchDefault>{{ report.loadsCount }} ładunków</span> </span
        >)
      </div>
    </div>
    <button
      type="button"
      (click)="download(report)"
      mat-raised-button
      color="primary"
    >
      <mat-icon>download</mat-icon>
      Pobierz XLSX
    </button>
  </div>
</div>
<div class="text-small mt-1" *ngIf="(reports$ | async)?.length === 0">
  <ng-container i18n
    >Tu pojawią się raporty z rozmieszczeń na wielu przestrzeniach</ng-container
  >
</div>
