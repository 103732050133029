<div class="modal-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Wybierz swoją flotę</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <p class="help" i18n>
        Zaznacz pojazdy i inne przestrzenie ładunkowe, z których będziesz
        korzystać. Swój wybór możesz potem w każdej chwili zmienić w zakładce
        Ustawienia.
      </p>
      <app-fleet-select-form></app-fleet-select-form>
      <div class="error" *ngIf="fleetError$ | async">
        Wybierz przynajmniej jeden typ przestrzeni ładunkowych, aby kontynuować
      </div>
      <div class="mt-1 text-center">
        <button
          mat-raised-button
          type="submit"
          class="btn-primary"
          (click)="onSubmit()"
        >
          <span i18n>Zapisz </span>
          <mat-icon *ngIf="isLoading">
            <mat-spinner class="progress" color="accent" diameter="24">
            </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
