import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { CylinderGeometry } from 'three';
import { Cylinder } from './cylinder';

export class CylinderMesh extends LoadMesh {
  private radius: number;
  private height: number;

  public constructor(object: Cylinder, settings: LoadDisplaySettings) {
    super('cylinder', object.color, settings, object.uuid);
    this.radius = object.radius;
    this.height = object.height;
    this.init();
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getRadius(): number {
    return this.radius * 1;
  }

  public getName(): string {
    return '(' + this.radius + 'x' + this.height + ')';
  }

  public getVolume(): number {
    return this.radius * this.radius * Math.PI * this.height;
  }

  private init() {
    this.obj.geometry = new CylinderGeometry(
      this.radius,
      this.radius,
      this.height,
      128
    );

    this.prepareMesh();
  }
}
