import { VehicleContext } from '../../lib/model/vehicle-context';
import { PositionerRequest } from '../../lib/communication/positioner.request';

export class ClearLoadsRequest extends PositionerRequest {
  constructor(context: VehicleContext) {
    super({
      uuid: context.getUuid(),
      historyUuid: context.getHistoryUuid(),
      initialTimestamp: context.getInitialTimestamp(),
      message: 'test',
      vehicle: context.getVehicle(),
      action: 'clear-loads',
      orbitControlsState: context.getOrbitControlsState(),
      projectId: context.getProjectId(),
      settings: context.getSettings()
    });
  }
}
