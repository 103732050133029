import { Component } from '@angular/core';

@Component({
  selector: 'app-bus',
  templateUrl: './bus.component.html',
  styleUrls: ['./bus.component.less']
})
export class BusComponent {
  constructor() {}
}
