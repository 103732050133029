import { CSS2DRenderer } from '../vendor/three/CSS2DRenderer';
import { TextLabel } from './TextLabel';

export class LabelRenderer extends CSS2DRenderer {
  public constructor() {
    super();
    this.domElement.style.position = 'absolute';
    this.domElement.style.inset = '0px';
    this.domElement.style.pointerEvents = 'none';
  }

  public attach(toElementWithId: string = 'scene-container') {
    const container: HTMLElement | null =
      document.getElementById(toElementWithId);
    if (container === null) {
      throw new Error(
        'LabelRenderer cannot be attached when there is no scene.'
      );
    }
    container.appendChild(this.domElement);
    this.setSize(this.domElement.clientWidth, this.domElement.clientHeight);
  }

  public addLabel(label: TextLabel) {
    console.log('LabelRenderer.addLabel', label);
  }
}
