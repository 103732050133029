import { NgModule } from '@angular/core';
import { LoadComponent } from './load.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../material.module';
import { FormModule } from './form/form.module';

@NgModule({
  declarations: [LoadComponent],
  imports: [CommonModule, BrowserModule, MaterialModule, FormModule],
  exports: [LoadComponent]
})
export class LoadModule {}
