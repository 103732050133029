import { v4 as uuidv4 } from 'uuid';
import { Project } from './project';

export class Directory {
  uuid: string;
  userId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  projects: Project[] = [];

  constructor(obj?: any) {
    Object.assign(this, obj);
    if (this.uuid === undefined) {
      this.uuid = uuidv4();
    }
    if (this.createdAt) {
      this.createdAt = new Date(this.createdAt);
    }
    if (this.updatedAt) {
      this.updatedAt = new Date(this.updatedAt);
    }
    this.projects = (this.projects || []).map((p) => new Project(p));
  }

  toString() {
    return this.name;
  }
}
