import { Space } from 'src/app/vehicle/space/lib/space';
import { SpaceFactory } from 'src/app/vehicle/space/lib/space-factory';
import { Vehicle } from '../../../lib/vehicle';
import { WarehouseMesh } from './warehouse-mesh';

export class Warehouse extends Vehicle {
  type = 'warehouse';
  width: number;
  length: number;
  height: number;

  trailerSpacing = 0;
  cabinWidth = 0;
  cabinLength = 0;
  cabinHeight = 0;

  constructor(obj: any, private spaceFactory: SpaceFactory) {
    super(obj, spaceFactory);
    this.mesh = new WarehouseMesh(this);
  }

  get fullName(): string {
    return `${this.name}`;
  }

  get fullDescription(): string {
    return `${this.length}x${this.width}x${this.height}`;
  }
  get isEmpty(): boolean {
    return !(this.length + this.width + this.height > 0);
  }

  get totalLength(): number {
    return this.length;
  }

  get spacesForSizeDisplay(): Space[] {
    return [
      this.spaceFactory.recreate({
        ...this.enabledSpaces[0],
        length: this.length,
        width: this.width,
        height: this.height,
        maxLoadingWeight: this.maxLoadingWeight
      })
    ];
  }

  get dimensions(): number[] {
    return [this.length, this.width, this.height];
  }

  public toJSON() {
    const { spaceFactory, ...otherProps } = this;
    return otherProps;
  }
}
