import { Injectable } from '@angular/core';
import { Camera, Vector3 } from 'three';
import { TextLabel } from '../lib/text-label';
import { Constants as Config } from 'src/app/config/constants';

@Injectable({
  providedIn: 'root'
})
export class CSS2DToCanvasConverterService {
  public addLabelsToCanvas(
    labels: TextLabel[],
    canvas: HTMLCanvasElement,
    camera: Camera,
    fontSizeModifier: number = 0,
    fontSizeMultiplier: number = 1
  ) {
    console.log('adding labels to canvas');
    let labelCanvas: HTMLCanvasElement =
      canvas.parentElement.querySelector('.label-canvas');
    if (labelCanvas === null) {
      labelCanvas = document.createElement('canvas');
      labelCanvas.classList.add('label-canvas');
      canvas.parentElement.appendChild(labelCanvas);
    }
    labelCanvas.width = canvas.width;
    labelCanvas.height = canvas.height;
    const context = labelCanvas.getContext('2d');
    context.drawImage(canvas, 0, 0);
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    labels.forEach((label: TextLabel) => {
      let lineHeight = (10 + fontSizeModifier) * fontSizeMultiplier;
      let fontSize = (8 + fontSizeModifier) * fontSizeMultiplier;
      if (label.getCssClass().includes('blue')) {
        context.fillStyle = '#312fbf';
      } else {
        context.fillStyle = 'black';
      }
      if (label.getCssClass().includes('fs14')) {
        fontSize = (14 + fontSizeModifier) * fontSizeMultiplier;
        lineHeight = (18 + fontSizeModifier) * fontSizeMultiplier;
      } else if (label.getCssClass().includes('fs12')) {
        fontSize = (12 + fontSizeModifier) * fontSizeMultiplier;
        lineHeight = (15 + fontSizeModifier) * fontSizeMultiplier;
      }
      const radius = label.getRadius();
      if (radius) {
        const scale = 8000 / radius;
        fontSize *= scale;
        lineHeight *= scale;
      }
      fontSize *= Config.DIMENSION_SCALING;
      lineHeight *= Config.DIMENSION_SCALING;

      if (label.getCssClass().includes('material-label')) {
        context.font = 'normal 20px MaterialDesign';
      } else {
        context.font = `normal ${fontSize}px Arial`;
      }
      const position = this.calculateAbsolutePosition(label, canvas, camera);
      const text = label
        .getText()
        .replace(/<br>/g, '\n')
        .replace(/\n\n+/g, '\n')
        .split('\n');
      let yOffset = 0;
      text.forEach((line) => {
        context.fillText(line, position.x, position.y + yOffset);
        yOffset += lineHeight;
      });
    });
    console.log('adding labels to canvas finished');

    return labelCanvas;
  }

  private calculateAbsolutePosition(
    label: TextLabel,
    canvas: HTMLCanvasElement,
    camera: Camera
  ): Vector3 {
    const widthHalf = canvas.width / 2;
    const heightHalf = canvas.height / 2;
    //const position = new Vector3();
    //label.getWorldPosition(position);
    const position = label.getPosition().clone();
    position.project(camera);
    position.x = position.x * widthHalf + widthHalf;
    position.y = -(position.y * heightHalf) + heightHalf;
    return position;
  }
}
