import { Vector } from '../communication/vector';
import { Statistics } from './statistics';
import { Load } from 'src/app/load/lib/load';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { VehicleFactory } from 'src/app/vehicle/lib/vehicle-factory';
import { OrbitControlsState } from './orbit-controls-state';

export class GaragePosition {
  public vehicle: Vehicle;
  public matrix: Vector[];
  public uuid: string;
  public existingLoads: Load[];
  public loads: Load[];
  public statistics: Statistics;
  public thumbnail?: string;
  public createdAt: Date;
  public updatedAt: Date;
  public calculationId: string;
  public orbitControlsState: OrbitControlsState;

  constructor(
    obj: any,
    vehicleFactory: VehicleFactory,
    loadFactory: LoadFactory
  ) {
    Object.assign(this, obj);
    this.statistics = new Statistics(obj?.statistics || {});
    this.thumbnail = atob(this.thumbnail);
    if (!this.thumbnail?.startsWith('data:')) {
      this.thumbnail = 'data:image/jpeg;base64,' + this.thumbnail;
    }
    if (this.vehicle != null && !(this.vehicle instanceof Vehicle)) {
      this.vehicle = vehicleFactory.recreate(this.vehicle);
    }
    this.existingLoads = (this.existingLoads || []).map((l) =>
      loadFactory.recreateLoad(l)
    );
    this.loads = (this.loads || []).map((l) => loadFactory.recreateLoad(l));
    this.matrix = (this.matrix || []).map((v) => new Vector(v));
    this.orbitControlsState = new OrbitControlsState(
      obj?.orbitControlsState || {}
    );
  }
}
