import { VehicleContext } from 'src/app/lib/model/vehicle-context';
import {
  Camera,
  OrthographicCamera,
  PerspectiveCamera,
  SRGBColorSpace,
  Vector3,
  WebGLRenderer
} from 'three';
import { MyScene } from './MyScene';
import { Constants } from 'src/app/config/constants';

export class SceneDirector {
  public scene: MyScene;
  public camera: PerspectiveCamera;
  public camera2d: OrthographicCamera;
  public renderer: WebGLRenderer;
  public canvas: HTMLCanvasElement;
  private view2d: boolean = false;

  setupMainScene(
    cameraPosition: Vector3,
    canvas: HTMLCanvasElement,
    makeCopy = false,
    view2d = false,
    scale = 1.0
  ) {
    this.view2d = view2d;
    this.canvas = canvas;
    this.scene = new MyScene();
    this.scene.isSecondaryCopy = makeCopy;
    this.scene.initDefaultView();
    this.initCamera(cameraPosition);
    this.init2dCamera(cameraPosition);
    this.initRenderer(scale);
  }

  hasScene(): boolean {
    return !!this.scene;
  }

  getCurrentCamera(): Camera {
    return this.view2d ? this.camera2d : this.camera;
  }

  render() {
    requestAnimationFrame(() => {
      /*if (this.renderer.getContext().isContextLost()) {
        console.log('reinit renderer');
        //this.initRenderer();
      }*/
      this.renderer.render(this.scene, this.camera);
    });
  }

  updateSize(width: number, height: number) {
    this.renderer.setSize(width, height, false);
    this.camera.aspect = this.getAspectRatio();
    this.camera.updateProjectionMatrix();
  }

  getAspectRatio(): number {
    return this.canvas.clientWidth / this.canvas.clientHeight;
  }

  moveCamera(position: Vector3) {
    this.camera.position.copy(position);
    this.camera.lookAt(this.scene.position);
    this.camera.updateProjectionMatrix();
  }

  sideView(context: VehicleContext, front: boolean = true, aspectRatio = 1.0) {
    const vehicleMesh = this.scene.getObjectByName('vehicle-mesh');
    const length = context.getVehicle().maxLength * Constants.DIMENSION_SCALING;
    const height = context.getVehicle().maxHeight * Constants.DIMENSION_SCALING;
    const width = context.getVehicle().maxWidth * Constants.DIMENSION_SCALING;

    const newPosition = new Vector3(
      0,
      0,
      ((front ? 1 : -1) * (width + 1000 * Constants.DIMENSION_SCALING)) / 2
    );
    this.setCamera2dFov(-length / 2, length / 2, length / aspectRatio, 0);
    this.camera2d.lookAt(new Vector3(0, 0, 0));
    this.moveCamera2d(newPosition);
  }

  rearView(context: VehicleContext, front: boolean = false, aspectRatio = 1.0) {
    const vehicleMesh = this.scene.getObjectByName('vehicle-mesh');
    const length = context.getVehicle().maxLength * Constants.DIMENSION_SCALING;
    const height = context.getVehicle().maxHeight * Constants.DIMENSION_SCALING;
    const width = context.getVehicle().maxWidth * Constants.DIMENSION_SCALING;
    const newPosition = new Vector3(
      ((front ? -1 : 1) * (length + 1000 * Constants.DIMENSION_SCALING)) / 2,
      0,
      0
    );
    this.setCamera2dFov(
      (-height / 2) * aspectRatio,
      (height / 2) * aspectRatio,
      height,
      0
    );
    this.camera2d.lookAt(newPosition);
    this.moveCamera2d(newPosition);
  }

  topView(context: VehicleContext, aspectRatio = 1.0) {
    const vehicleMesh = this.scene.getObjectByName('vehicle-mesh');
    const length = context.getVehicle().maxLength * Constants.DIMENSION_SCALING;
    const height = context.getVehicle().maxHeight * Constants.DIMENSION_SCALING;
    const width = context.getVehicle().maxWidth * Constants.DIMENSION_SCALING;
    const newPosition = new Vector3(
      0,
      height,
      vehicleMesh.position.z + width / 2
    );

    this.setCamera2dFov(
      -length / 2,
      length / 2,
      length / aspectRatio / 2,
      -length / aspectRatio / 2
    );
    this.camera2d.lookAt(new Vector3(0, 0, 0));
    this.moveCamera2d(newPosition);
  }

  private initCamera(position: Vector3) {
    this.camera = new PerspectiveCamera(45, this.getAspectRatio(), 0.1, 20000);
    this.camera.position.copy(position);
    this.camera.updateProjectionMatrix();
  }

  private setCamera2dFov(
    left: number,
    right: number,
    top: number,
    bottom: number
  ) {
    this.camera2d.left = left;
    this.camera2d.right = right;
    this.camera2d.top = top;
    this.camera2d.bottom = bottom;
    this.camera2d.updateProjectionMatrix();
  }

  private init2dCamera(position: Vector3) {
    this.camera2d = new OrthographicCamera(-10000, 10000, 5000, 0, 0.1, 20000);
    this.camera2d.position.copy(position);
    this.camera2d.updateProjectionMatrix();
  }

  render2d() {
    requestAnimationFrame(() => {
      /*if (this.renderer.getContext().isContextLost()) {
        console.log('reinit renderer');
        this.initRenderer();
      }*/
      this.renderer?.render(this.scene, this.camera2d);
    });
  }

  moveCamera2d(position: Vector3) {
    this.camera2d.position.copy(position);
    this.camera2d.lookAt(this.scene.position);
    this.camera2d.updateProjectionMatrix();
    this.camera2d.updateMatrixWorld();
  }

  dispose() {
    this.renderer.dispose();
    this.renderer = null;
    console.log('renderer disposed');
  }

  private initRenderer(scale = 1.0) {
    this.renderer = new WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      preserveDrawingBuffer: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(
      this.canvas.clientWidth * scale,
      this.canvas.clientHeight * scale,
      false
    );
    this.renderer.setClearColor(0xffffff, 0.5);
    this.renderer.sortObjects = false;
    //this.renderer.shadowMap.enabled = true;
    this.renderer.outputColorSpace = SRGBColorSpace;
  }
}
