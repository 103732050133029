import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';
import { environment } from '../../environments/environment';
import {
  catchError,
  EMPTY,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { CalculationsBundle } from './lib/calculations-bundle';
import { ProjectsService } from '../projects/projects.service';
import { VehicleFactory } from '../vehicle/lib/vehicle-factory';
import { LoadFactory } from '../load/lib/load-factory';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends HttpService {
  protected url = environment.apiUrl + '/database/projects/{uuid}/reports';

  private cache$: Observable<CalculationsBundle[]>[] = [];
  private update$ = new Subject<string>();
  private updates$: Observable<CalculationsBundle[]>;

  constructor(
    http: HttpClient,
    private vehicleFactory: VehicleFactory,
    private loadFactory: LoadFactory
  ) {
    super(http);
    this.updates$ = this.update$.pipe(
      mergeMap((projectId) => this.fetchReportsData(projectId))
    );
  }

  getReports$(projectId: string): Observable<CalculationsBundle[]> {
    if (!projectId) {
      return EMPTY;
    }
    if (!this.cache$[projectId]) {
      this.cache$[projectId] = this.fetchReportsData(projectId).pipe(
        shareReplay(1)
      );
    }
    console.log('reports.service.ts: get reports');
    const projectCache: Observable<CalculationsBundle[]> =
      this.cache$[projectId];
    return merge(projectCache, this.updates$);
  }

  public refresh(projectId: string) {
    delete this.cache$[projectId];
  }

  public update(projectId: string) {
    console.log('reports.service.ts: update list for project ', projectId);
    this.update$.next(projectId);
  }

  private fetchReportsData(
    projectId: string
  ): Observable<CalculationsBundle[]> {
    return this.http
      .get<CalculationsBundle[]>(this.getUrlForProject(projectId))
      .pipe(
        map((results) =>
          results.map(
            (r) =>
              new CalculationsBundle(r, this.vehicleFactory, this.loadFactory)
          )
        ),
        catchError(this.handleError('getCubofetchReportsDataids', []))
      );
  }

  private getUrlForProject(uuid: string) {
    return this.url.replace('{uuid}', uuid);
  }
}
