import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  public readonly DEFAULT_COLOR = 0x00d084; // green

  public readonly colors = [
    0xfff176, // light yellow,
    0xff6900, // orange,
    0xfcb900, // gold,
    0xa5d5c2, // teal,
    0x00d084, // green,
    0xdce775, // limegreen
    0x8ed1fc, // lightblue,
    0x0693e3, // blue,
    0x3f51b5, // darkblue,
    0x546e7a, // steelblue,
    0xabb8c3, // grey,
    0x555555, // darkgrey,
    0x111111, // black
    0xeb144c, // red,
    0xf78da7, // pink,
    0x9900ef, // magenta
    0xa1887f, // brown,
    0x3e2723 // dark brown,
  ];
  public color = new BehaviorSubject<number>(this.DEFAULT_COLOR);
  public opened = new BehaviorSubject<boolean>(false);
  constructor() {}

  public get color$(): Observable<number> {
    return this.color.asObservable();
  }

  public get cssColor() {
    return this.getCssValue(this.color.value);
  }

  public randomColor(): number {
    return this.colors[Math.floor(Math.random() * this.colors.length)];
  }

  public get colorsHexStrings(): string[] {
    return this.colors.map((color) => this.getCssValue(color));
  }

  public getHexValue(cssColor: string): number {
    return parseInt(cssColor.replace('#', '0x'), 16);
  }

  public getCssValue(color: number): string {
    return `#${color.toString(16).padStart(6, '0')}`;
  }

  public setFromCss(cssColor: string) {
    this.color.next(this.getHexValue(cssColor));
  }

  public getLightness(cssColor: string) {
    const c = cssColor.substring(1);
    const rgb = parseInt(c, 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    const lightness = (r * 299 + g * 587 + b * 114) / 1000;
    return lightness;
  }
}
