import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { BufferGeometry, Float32BufferAttribute } from 'three';
import { HashShape } from './hash-shape';

export class HashShapeMesh extends LoadMesh {
  private a: number;
  private b: number;
  private c: number;
  private d: number;
  private e: number;
  private f: number;
  private g: number;
  private h: number;
  private i: number;
  private j: number;

  private height: number;

  public constructor(object: HashShape, settings: LoadDisplaySettings) {
    super('h-shape', object.color, settings, object.uuid);
    this.obj.geometry = new BufferGeometry();
    this.a = object.a;
    this.b = object.b;
    this.c = object.c;
    this.d = object.d;
    this.e = object.e;
    this.f = object.f;
    this.g = object.g;
    this.h = object.h;
    this.i = object.i;
    this.j = object.j;
    this.height = object.height;
    this.init();
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getName(): string {
    return (
      '(' +
      this.a +
      'x' +
      this.b +
      'x' +
      this.c +
      'x' +
      this.d +
      'x' +
      this.e +
      'x' +
      this.f +
      'x' +
      this.g +
      'x' +
      this.h +
      'x' +
      this.i +
      'x' +
      this.j +
      'x' +
      this.height +
      ')'
    );
  }

  private init() {
    const indices = [];
    const vertices = [];
    const a0 = -(this.a + this.b + this.c + this.d + this.e) / 2;
    const a1 = (this.a + this.b + this.c + this.d + this.e) / 2;
    const b0 = -(this.f + this.g + this.h + this.i + this.j) / 2;
    const b1 = (this.f + this.g + this.h + this.i + this.j) / 2;
    const a = this.a;
    const b = this.b;
    const c = this.c;
    const d = this.d;
    const e = this.e;
    const f = this.f;
    const g = this.g;
    const h = this.h;
    const i = this.i;
    const j = this.j;
    const h0 = -this.height / 2;
    const h1 = this.height / 2;

    //bottom
    vertices.push(a0 + a, h0, b1); // 0
    vertices.push(a0 + a + b, h0, b1); // 1
    vertices.push(a0 + a + b, h0, b1 - j); // 2
    vertices.push(a1 - d - e, h0, b1 - j); // 3
    vertices.push(a1 - d - e, h0, b1); // 4
    vertices.push(a1 - e, h0, b1); // 5
    vertices.push(a1 - e, h0, b1 - j); // 6
    vertices.push(a1, h0, b1 - j); // 7
    vertices.push(a1, h0, b1 - j - i); // 8
    vertices.push(a1 - e, h0, b1 - j - i); // 9
    vertices.push(a1 - e, h0, b0 + f + g); // 10
    vertices.push(a1, h0, b0 + f + g); // 11
    vertices.push(a1, h0, b0 + f); // 12
    vertices.push(a1 - e, h0, b0 + f); // 13
    vertices.push(a1 - e, h0, b0); // 14
    vertices.push(a1 - e - d, h0, b0); // 15
    vertices.push(a1 - e - d, h0, b0 + f); // 16
    vertices.push(a0 + a + b, h0, b0 + f); // 17
    vertices.push(a0 + a + b, h0, b0); // 18
    vertices.push(a0 + a, h0, b0); // 19
    vertices.push(a0 + a, h0, b0 + f); // 20
    vertices.push(a0, h0, b0 + f); // 21
    vertices.push(a0, h0, b0 + f + g); // 22
    vertices.push(a0 + a, h0, b0 + f + g); // 23
    vertices.push(a0 + a, h0, b1 - j - i); // 24
    vertices.push(a0, h0, b1 - j - i); // 25
    vertices.push(a0, h0, b1 - j); // 26
    vertices.push(a0 + a, h0, b1 - j); // 27

    vertices.push(a0 + a + b, h0, b1 - j - i); // 28
    vertices.push(a0 + a + b + c, h0, b1 - j - i); // 29
    vertices.push(a0 + a + b + c, h0, b0 + f + g); // 30
    vertices.push(a0 + a + b, h0, b0 + f + g); // 31

    //top
    vertices.push(a0 + a, h1, b1); // 0
    vertices.push(a0 + a + b, h1, b1); // 1
    vertices.push(a0 + a + b, h1, b1 - j); // 2
    vertices.push(a1 - d - e, h1, b1 - j); // 3
    vertices.push(a1 - d - e, h1, b1); // 4
    vertices.push(a1 - e, h1, b1); // 5
    vertices.push(a1 - e, h1, b1 - j); // 6
    vertices.push(a1, h1, b1 - j); // 7
    vertices.push(a1, h1, b1 - j - i); // 8
    vertices.push(a1 - e, h1, b1 - j - i); // 9
    vertices.push(a1 - e, h1, b0 + f + g); // 10
    vertices.push(a1, h1, b0 + f + g); // 11
    vertices.push(a1, h1, b0 + f); // 12
    vertices.push(a1 - e, h1, b0 + f); // 13
    vertices.push(a1 - e, h1, b0); // 14
    vertices.push(a1 - e - d, h1, b0); // 15
    vertices.push(a1 - e - d, h1, b0 + f); // 16
    vertices.push(a0 + a + b, h1, b0 + f); // 17
    vertices.push(a0 + a + b, h1, b0); // 18
    vertices.push(a0 + a, h1, b0); // 19
    vertices.push(a0 + a, h1, b0 + f); // 20
    vertices.push(a0, h1, b0 + f); // 21
    vertices.push(a0, h1, b0 + f + g); // 22
    vertices.push(a0 + a, h1, b0 + f + g); // 23
    vertices.push(a0 + a, h1, b1 - j - i); // 24
    vertices.push(a0, h1, b1 - j - i); // 25
    vertices.push(a0, h1, b1 - j); // 26
    vertices.push(a0 + a, h1, b1 - j); // 27

    vertices.push(a0 + a + b, h1, b1 - j - i); // 28
    vertices.push(a0 + a + b + c, h1, b1 - j - i); // 29
    vertices.push(a0 + a + b + c, h1, b0 + f + g); // 30
    vertices.push(a0 + a + b, h1, b0 + f + g); // 31

    // botom
    indices.push(0, 1, 2); // 1
    indices.push(0, 2, 27); // 2

    indices.push(27, 2, 28); // 3
    indices.push(28, 24, 27); // 4

    indices.push(26, 27, 24); // 5
    indices.push(24, 25, 26); // 6

    indices.push(24, 28, 31); // 7
    indices.push(31, 23, 24); // 8

    indices.push(23, 31, 17); // 9
    indices.push(17, 20, 23); // 10

    indices.push(22, 23, 20); // 11
    indices.push(20, 21, 22); // 12

    indices.push(20, 17, 18); // 13
    indices.push(18, 19, 20); // 14

    indices.push(2, 3, 29); // 15
    indices.push(29, 28, 2); // 16

    indices.push(31, 30, 16); // 17
    indices.push(16, 17, 31); // 18

    indices.push(4, 5, 6); // 19
    indices.push(6, 3, 4); // 20

    indices.push(3, 6, 9); // 21
    indices.push(9, 29, 3); // 22

    indices.push(29, 9, 10); // 23
    indices.push(10, 30, 29); // 24

    indices.push(30, 10, 13); // 25
    indices.push(13, 16, 30); // 26

    indices.push(16, 13, 14); // 27
    indices.push(14, 15, 16); // 28

    indices.push(6, 7, 8); // 29
    indices.push(8, 9, 6); // 30

    indices.push(10, 11, 12); // 31
    indices.push(12, 13, 10); // 32

    //top
    indices.push(32, 33, 34); // 1
    indices.push(32, 34, 59); // 2

    indices.push(59, 34, 60); // 3
    indices.push(60, 56, 59); // 4

    indices.push(58, 59, 56); // 5
    indices.push(56, 57, 58); // 6

    indices.push(56, 60, 63); // 7
    indices.push(63, 55, 56); // 8

    indices.push(55, 63, 49); // 9
    indices.push(49, 52, 55); // 10

    indices.push(54, 55, 52); // 11
    indices.push(52, 53, 54); // 12

    indices.push(52, 49, 50); // 13
    indices.push(50, 51, 52); // 14

    indices.push(34, 35, 61); // 15
    indices.push(61, 60, 34); // 16

    indices.push(63, 62, 48); // 17
    indices.push(48, 49, 63); // 18

    indices.push(36, 37, 38); // 19
    indices.push(38, 35, 36); // 20

    indices.push(35, 38, 41); // 21
    indices.push(41, 61, 35); // 22

    indices.push(61, 41, 42); // 23
    indices.push(42, 62, 61); // 24

    indices.push(62, 42, 45); // 25
    indices.push(45, 48, 62); // 26

    indices.push(48, 45, 46); // 27
    indices.push(46, 47, 48); // 28

    indices.push(38, 39, 40); // 29
    indices.push(40, 41, 38); // 30

    indices.push(42, 43, 44); // 31
    indices.push(44, 45, 42); // 32

    //walls
    indices.push(0, 1, 33);
    indices.push(33, 32, 0);

    indices.push(1, 2, 34);
    indices.push(34, 33, 1);

    indices.push(2, 3, 35);
    indices.push(35, 34, 2);

    indices.push(3, 4, 36);
    indices.push(36, 35, 3);

    indices.push(4, 5, 37);
    indices.push(37, 36, 4);

    indices.push(5, 6, 38);
    indices.push(38, 37, 5);

    indices.push(6, 7, 39);
    indices.push(39, 38, 6);

    indices.push(7, 8, 40);
    indices.push(40, 39, 7);

    indices.push(8, 9, 41);
    indices.push(41, 40, 8);

    indices.push(9, 10, 42);
    indices.push(42, 41, 9);

    indices.push(10, 11, 43);
    indices.push(43, 42, 10);

    indices.push(11, 12, 44);
    indices.push(44, 43, 11);

    indices.push(12, 13, 45);
    indices.push(45, 44, 12);

    indices.push(13, 14, 46);
    indices.push(46, 45, 13);

    indices.push(14, 15, 47);
    indices.push(47, 46, 14);

    indices.push(15, 16, 48);
    indices.push(48, 47, 15);

    indices.push(16, 17, 49);
    indices.push(49, 48, 16);

    indices.push(17, 18, 50);
    indices.push(50, 49, 17);

    indices.push(18, 19, 51);
    indices.push(51, 50, 18);

    indices.push(19, 20, 52);
    indices.push(52, 51, 19);

    indices.push(20, 21, 53);
    indices.push(53, 52, 20);

    indices.push(21, 22, 54);
    indices.push(54, 53, 21);

    indices.push(22, 23, 55);
    indices.push(55, 54, 22);

    indices.push(23, 24, 56);
    indices.push(56, 55, 23);

    indices.push(24, 25, 57);
    indices.push(57, 56, 24);

    indices.push(25, 26, 58);
    indices.push(58, 57, 25);

    indices.push(26, 27, 59);
    indices.push(59, 58, 26);

    indices.push(27, 0, 32);
    indices.push(32, 59, 27);

    // internal walls
    indices.push(28, 29, 61);
    indices.push(61, 60, 28);

    indices.push(29, 30, 62);
    indices.push(62, 61, 29);

    indices.push(30, 31, 63);
    indices.push(63, 62, 30);

    indices.push(31, 28, 60);
    indices.push(60, 63, 31);

    this.obj.geometry.setIndex(indices);
    this.obj.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(vertices, 3)
    );
    this.obj.geometry.computeVertexNormals();

    this.prepareMesh();
  }
}
