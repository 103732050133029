import { LoadFactory } from 'src/app/load/lib/load-factory';
import { SpaceMesh } from '../../../lib/space-mesh';
import { CuboidSpace } from '../../../lib/type/cuboid-space';
import { ContainerMesh } from './container-mesh';

export class Container extends CuboidSpace {
  type = 'container';
  width: number;
  length: number;
  height: number;

  constructor(obj: unknown, loadFactory: LoadFactory) {
    super(obj, loadFactory);
    this.mesh = new ContainerMesh(this);
  }

  get fullName(): string {
    return `${this.name}`;
  }

  get volume(): number {
    return this.area * this.height;
  }

  get area(): number {
    return this.width * this.length;
  }

  get fullDescription(): string {
    return `${this.length}x${this.width}x${this.height}`;
  }

  get isEmpty(): boolean {
    return !(this.length + this.width + this.height > 0);
  }
}
