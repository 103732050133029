import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Profile } from '../lib/model/profile';
import { LoadComponentService } from '../services/load-component.service';
import { UiService } from '../services/ui.service';
import { ProfileService } from '../services/profile.service';
import { Settings } from '../lib/model/settings';
import { CustomLoadService } from '../load/lib/custom-load.service';
import { CustomVehicleService } from '../vehicle/lib/custom-vehicle.service';
import { VehicleService } from '../vehicle/lib/vehicle.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsModalService {
  public profile$: Observable<Profile>;

  constructor(
    private profileService: ProfileService,
    private loadComponentService: LoadComponentService,
    private customLoadService: CustomLoadService,
    private customVehicleService: CustomVehicleService,
    private vehicleApi: VehicleService,
    private uiService: UiService
  ) {
    this.profile$ = profileService.getProfile();
  }

  public updateProfile(profile: Profile) {
    this.profileService.updateProfile(profile);
  }

  public saveSettings(settings: Settings): Observable<Settings> {
    this.uiService.setLengthUnit(settings.lengthUnit);
    this.uiService.setWeightUnit(settings.weightUnit);
    return this.profileService.saveSettings(settings);
  }

  public updateSettingsInView(settings: Settings) {
    this.profileService.updateSettings(settings);
    this.customLoadService.update(true);
    this.customVehicleService.update();
    this.vehicleApi.update();
  }

  close() {
    this.loadComponentService.clear('settings-modal.service.ts');
  }
}
