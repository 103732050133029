import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';

import {
  catchError,
  map,
  mergeMap,
  share,
  shareReplay,
  take
} from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { HttpService } from '../services/http.service';
import { StatusResponse } from '../lib/communication/status.response';
import { GaragePosition } from '../lib/model/garage-position';
import { VehicleContext } from '../lib/model/vehicle-context';
import { v4 as uuidv4 } from 'uuid';
import { LoadComponentService } from '../services/load-component.service';
import { VehicleFactory } from '../vehicle/lib/vehicle-factory';
import { LoadFactory } from '../load/lib/load-factory';

@Injectable({
  providedIn: 'root'
})
export class GarageService extends HttpService {
  private profileUrl = environment.apiUrl + '/database/profile';
  private myGarageUrl = this.profileUrl + '/garage/';

  private cache$: Observable<GaragePosition[]>;
  private update$ = new Subject<void>();
  private updates$: Observable<GaragePosition[]>;

  constructor(
    http: HttpClient,
    private loadComponentService: LoadComponentService,
    private vehicleFactory: VehicleFactory,
    private loadFactory: LoadFactory
  ) {
    super(http);
    this.updates$ = this.update$.pipe(mergeMap(() => this.fetchList()));
  }

  get list$(): Observable<GaragePosition[]> {
    if (!this.cache$) {
      this.cache$ = this.fetchList().pipe(shareReplay(1));
    }
    return merge(this.cache$, this.updates$);
  }

  public update() {
    this.refresh();
    this.update$.next();
  }

  protected refresh() {
    this.cache$ = null;
  }

  protected fetchList(): Observable<GaragePosition[]> {
    return this.http.get<GaragePosition[]>(this.myGarageUrl).pipe(
      take(1),
      map((results) =>
        results.map(
          (r) => new GaragePosition(r, this.vehicleFactory, this.loadFactory)
        )
      ),
      catchError(this.handleError('fetchList', []))
    );
  }

  public saveToMyGarage(
    context: VehicleContext,
    thumbnail: string
  ): Observable<StatusResponse<void>> {
    const calc = {
      uuid: uuidv4(),
      calculationId: context.getUuid(),
      existingLoads: context.getAllLoads(),
      vehicle: context.getVehicle(),
      matrix: context.getMatrix(),
      orbitControlsState: context.getOrbitControlsState(),
      thumbnail
    };
    const httpCall = this.http.post<any>(this.myGarageUrl, calc).pipe(
      catchError(this.handleError('saveToMyGarage', [])),
      share(),
      map(
        (response: any) => new StatusResponse<void>(response.status, undefined)
      )
    );
    return httpCall;
  }

  public removeFromMyGarage(
    calc: GaragePosition
  ): Observable<StatusResponse<void>> {
    const httpCall = this.http
      .delete<any>(this.myGarageUrl + '/' + calc.uuid)
      .pipe(
        catchError(this.handleError('removeFromMyGarage', [])),
        share(),
        map(
          (response: any) =>
            new StatusResponse<void>(response.status, undefined)
        )
      );
    return httpCall;
  }

  close() {
    this.loadComponentService.clear('garage.service.ts');
  }
}
