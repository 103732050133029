import { EdgesGeometry, LineBasicMaterial, LineSegments, Mesh } from 'three';
import { Vector } from '../../../lib/communication/vector';
import { VectorMesh } from './vector-mesh';

export class DebugVectorMesh {
  private vectorMesh: VectorMesh;
  constructor(private vector: Vector) {
    this.vectorMesh = new VectorMesh(vector, {
      opacity: 0.9,
      color: this.randomColor()
    });
    this.vectorMesh.mesh.name = 'debug-vector';
    this.init();
  }

  private init() {
    const mat = new LineBasicMaterial({ color: 0xff0000 });
    const geo = new EdgesGeometry(this.vectorMesh.mesh.geometry, 1);
    const wireframe = new LineSegments(geo, mat);
    this.vectorMesh.mesh.add(wireframe);
  }

  private randomColor(): number {
    return Math.floor(Math.random() * 16777215);
  }

  public get mesh(): Mesh {
    return this.vectorMesh.mesh;
  }
}
