<div [ngSwitch]="uiMode$ | async">
  <ng-container *ngSwitchCase="'standard'">
    <app-form-auto
      [type]="type"
      [events]="eventsSubject.asObservable()"
      (addVehicleEvent)="addVehicle($event)"
    ></app-form-auto>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <app-form-expert
      [type]="type"
      [events]="eventsSubject.asObservable()"
      (addVehicleEvent)="addVehicle($event)"
    ></app-form-expert>
  </ng-container>
</div>
