import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, startWith } from 'rxjs';
import { UiService } from '../services/ui.service';

/*
 * Convert length units from cm to current UI unit
 * Parameter dimensions could be provided for square or metric units
 * Usage:
 *   value | lengthUnit
 *   value | lengthUnit : 2
 *   value | lengthUnit : 3
 * Example if meters are chosen:
 *   {{ 20 | lengthUnit }}
 *   formats to: 0.2
 */
@Pipe({ name: 'lengthUnit' })
export class LengthUnitPipe implements PipeTransform {
  private unit: Observable<string>;

  constructor(private ui: UiService) {
    this.unit = ui.getLengthUnit().pipe(startWith(ui.getCurrentLengthUnit()));
  }

  transform(mmValue: number, dimensions: number = 1): Observable<number> {
    return this.unit.pipe(
      map((toUnit) => {
        const converter = this.ui.getOutputLengthConverter(toUnit);
        let output = converter * mmValue;
        if (dimensions !== 1) {
          output = output / Math.pow(converter, dimensions);
        }
        return output;
      })
    );
  }
}
