import { Component, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-weight',
  templateUrl: './weight.component.html',
  styleUrls: ['./weight.component.less']
})
export class WeightComponent {
  @Input() mode = 'outline';
  private _default: number = null;
  @Input() set default(val: number) {
    this._default = val;
    this.setValue(val);
  }
  get default() {
    return this._default;
  }
  public form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, public ui: UiService) {
    this.createForm();
  }

  public getValue(): number {
    return this.ui.getWeightInDefaultUnit(this.form.controls.weight.value);
  }

  public setValue(val: number) {
    this.form.get('weight').setValue(val);
  }

  public validateForm() {
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  private createForm() {
    this.form = this.formBuilder.group({
      weight: [null, Validators.compose([])]
    });
  }
}
