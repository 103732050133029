import { Load } from 'src/app/load/lib/load';
import { VehicleSuggestionType } from 'src/app/suggest-larger-vehicle/lib/suggestion-type';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';

export class VehicleRequest {
  uuid: string; //uuidv4
  projectId?: string; //uuidv4
  loads: Load[];
  matrix: any;
  currentVehicle: Vehicle;
  type: VehicleSuggestionType;
  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
