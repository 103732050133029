export class Rating {
  calculationId: string;
  historyId?: string;
  rating: number;
  description?: string;

  constructor(data?: any) {
    Object.assign(this, data);
  }
}
