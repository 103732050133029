export class Constants {
  //static readonly SCENE_FLOOR_Y = -1250;
  static readonly DIMENSION_SCALING = 0.01;

  static readonly MAX_CONTEXTS = 100;
  static readonly GRID_SIZE = 50000 * this.DIMENSION_SCALING;
  static readonly GRID_DIVISIONS = 25;

  static readonly MAIN_BG = 0xffffff;
  static readonly GRID_COLOR = 0xcccccc;

  public static roundToScale(num: number) {
    return (
      Math.round((num + Number.EPSILON) * (1 / Constants.DIMENSION_SCALING)) /
      (1 / Constants.DIMENSION_SCALING)
    );
  }

  public static scale(num: number) {
    return num * Constants.DIMENSION_SCALING;
  }
}
