import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpaceComponent } from './space.component';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { FormModule } from './form/form.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { OnlyNumberDirective } from 'src/app/directives/only-number.directive';

@NgModule({
  declarations: [SpaceComponent],
  imports: [
    CommonModule,
    BrowserModule,
    MaterialModule,
    FormModule,
    DirectivesModule
  ],
  exports: [SpaceComponent, OnlyNumberDirective]
})
export class SpaceModule {}
