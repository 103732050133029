import { DatePipe } from '@angular/common';
import { CalculationsBundle } from './calculations-bundle';
import { UiService } from '../../services/ui.service';
import * as XLSX from 'xlsx';

export class ReportGenerator {
  constructor(
    private bundle: CalculationsBundle,
    private datePipe: DatePipe,
    private ui: UiService
  ) {}

  public generate() {
    const fileName = this.getFilename();
    let cnt = 0;

    const wb = XLSX.utils.book_new();
    for (const res of this.bundle.calculations) {
      const data: any = [];
      for (const group of res.loadsCondensed.loads) {
        data.push([
          group.cnt,
          group.load.name,
          this.ui.getLengthInCurrentUnit(group.load.cuboidHull.width),
          this.ui.getLengthInCurrentUnit(group.load.cuboidHull.length),
          this.ui.getLengthInCurrentUnit(group.load.cuboidHull.height),
          this.ui.getWeightInCurrentUnit(group.load.weight)
        ]);
      }
      const wsData = [
        [
          $localize`Liczba ładunków`,
          $localize`Nazwa`,
          $localize`Długość` + ' (' + this.ui.lengthUnit.value + ')',
          $localize`Szerokość` + ' (' + this.ui.lengthUnit.value + ')',
          $localize`Wysokość` + ' (' + this.ui.lengthUnit.value + ')',
          $localize`Waga` + ' (' + this.ui.weightUnit.value + ')'
        ],
        ...data
      ];

      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, ++cnt + ' ' + res.vehicle.fullName);
    }

    XLSX.writeFile(wb, fileName);
  }

  private getFilename() {
    const dateStr = this.datePipe.transform(
      this.bundle.createdAt,
      'yyyyMMdd-HHmm'
    );
    const filename = this.bundle.projectName + '-planning-' + dateStr + '.xlsx';
    return filename.replace(/[ +]/gi, '_').replace(/[^a-z0-9_.-]/gi, '');
  }
}
