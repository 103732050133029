<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h1 mat-dialog-title cdkDragHandle>
    <ng-container i18n>Powiel ładunek</ng-container> {{ data.idx }}
  </h1>
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label><ng-container i18n>Dodaj (sztuk)</ng-container></mat-label>
      <input matInput [(ngModel)]="data.cnt" />
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" i18n>Anuluj</button>
    <button mat-button [mat-dialog-close]="data" cdkFocusInitial i18n>
      Zastosuj
    </button>
  </div>
</div>
