import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileResponse } from '../lib/communication/file.response';
import { CustomLoadService } from '../load/lib/custom-load.service';
import { HttpService } from '../services/http.service';
import { LoadComponentService } from '../services/load-component.service';

@Injectable({
  providedIn: 'root'
})
export class ImportFileService extends HttpService {
  constructor(
    private loadComponentService: LoadComponentService,
    private customLoadService: CustomLoadService,
    protected http: HttpClient
  ) {
    super(http);
  }

  close() {
    this.loadComponentService.clear('import-file.service.ts');
  }

  openMyCuboids() {
    this.close();
  }

  updateCustomLoadList() {
    this.customLoadService.update(true);
  }

  getCurrentCustomLoads() {
    return this.customLoadService.loads$;
  }

  uploadFile(
    file: File,
    projectId: string,
    actionType: string
  ): Observable<FileResponse> {
    const formData = new FormData();
    formData.append('cuboids', file);
    formData.append('actionType', actionType);
    formData.append('projectId', projectId);
    return this.http
      .post<FileResponse>(
        environment.apiUrl + '/file/upload-custom-cuboids',
        formData,
        {}
      )
      .pipe(map((response) => new FileResponse(response)));
  }
}
