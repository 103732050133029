import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import { InfoService } from 'src/app/info/info.service';
import { InfoType } from 'src/app/info/lib/info-type';
import { FlooringLevelSettings } from 'src/app/vehicle/space/flooring-level/lib/flooring-level-settings';
import { Space } from 'src/app/vehicle/space/lib/space';
import { FlooringLevelComponent } from '../../../settings-modal/flooring-level/form/flooring-level.component';
import { Load } from '../../lib/load';
import { ProtrusionComponent } from '../protrusion/protrusion.component';
import { FlooringService } from './flooring.service';
import { FlooringType } from './flooring.type';

@Component({
  selector: 'app-flooring',
  templateUrl: './flooring.component.html',
  styleUrls: ['./flooring.component.less']
})
export class FlooringComponent implements OnDestroy {
  public opened: boolean;
  @ViewChild('protrusion') protrusion: ProtrusionComponent;
  @ViewChild('levels') levels: FlooringLevelComponent;
  @Input() mode: string = 'add';

  @Input() set item(item: Load) {
    this._item = item;
    this.setValues(
      item?.floorableTop,
      item?.floorableBottom,
      item?.protrusionLength
    );
  }

  get item(): Load {
    return this._item;
  }

  private _item: Load;

  private unsubscribe$ = new Subject<boolean>();

  get protrusionActive(): boolean {
    switch (this.type) {
      case FlooringType.bottom:
      case FlooringType.all:
        return true;
      case FlooringType.none:
      case FlooringType.top:
        return false;
      default:
        return false;
    }
  }
  get flooringLevelActive(): boolean {
    return (
      this.mode !== 'create' &&
      this.mode !== 'edit' &&
      this.type !== FlooringType.none
    );
  }
  public form: UntypedFormGroup;

  private formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
  private type: FlooringType;

  public get floorableTop(): boolean {
    return this.form.get('top').value;
  }
  public get floorableBottom(): boolean {
    return this.form.get('bottom').value;
  }

  constructor(
    private service: FlooringService,
    private infoService: InfoService
  ) {
    this.form = this.formBuilder.group({
      all: [false, Validators.compose([])],
      top: [false, Validators.compose([])],
      bottom: [false, Validators.compose([])]
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  change(name: string, event: MatCheckboxChange) {
    const checked = event.checked;

    if (name === 'all') {
      this.form.patchValue({ top: checked, bottom: checked });
    } else if (!checked) {
      this.form.patchValue({ all: false });
    } else {
      if (this.form.controls.top.value && this.form.controls.bottom.value) {
        this.form.patchValue({ all: true });
      }
    }
    this.updateType();
  }

  private updateType() {
    const top = this.form.get('top').value;
    const bottom = this.form.get('bottom').value;
    this.updateTypeFromValues(top, bottom);
  }

  private updateTypeFromValues(top: boolean, bottom: boolean) {
    const all = top && bottom;
    if (all) {
      this.type = FlooringType.all;
      return;
    }
    if (top) {
      this.type = FlooringType.top;
    } else if (bottom) {
      this.type = FlooringType.bottom;
    } else {
      this.type = FlooringType.none;
    }
  }

  public getValue(): FlooringType {
    return this.type;
  }

  public setValues(top: boolean, bottom: boolean, protrusion: number) {
    this.form.patchValue({ top: top, bottom: bottom, all: top && bottom });
    this.updateType();
    if (this.protrusionActive) {
      console.log('protrusion', protrusion);
      this.service.protrusionLength = this.service.protrusionWidth = protrusion;
      if (this.protrusion) {
        this.protrusion.setValue(protrusion);
      }
    }
  }

  public getProtrusionLength(): number {
    if (this.protrusion) {
      return this.protrusion.length;
    }
    return 0;
  }
  public getProtrusionWidth(): number {
    if (this.protrusion) {
      return this.protrusion.width;
    }
    return 0;
  }

  public getSpaceSettings(space: Space): FlooringLevelSettings {
    return this.levels
      ? this.levels.getSpaceSettings(space)
      : new FlooringLevelSettings();
  }

  get valid(): boolean {
    let valid = this.form.valid;
    if (this.levels) {
      valid = valid && this.levels.valid;
    }
    return valid;
  }

  public toggleHelp() {
    this.infoService.toggle(InfoType.Flooring);
  }
}
