import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadComponentService } from '../services/load-component.service';

@Component({
  selector: 'app-project-comment',
  templateUrl: './project-comment.component.html',
  styleUrls: ['./project-comment.component.less']
})
export class ProjectCommentComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private componentService: LoadComponentService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected close() {
    this.componentService.clear('project-comment.component.ts');
  }

  protected saved() {
    this.close();
  }
}
