import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ColorEvent } from 'ngx-color';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent
} from '../confirmation-dialog/confirmation-dialog.component';
import { ContextMenuService } from './context-menu.service';
import { ContextMenuServiceCommunicationModel } from './lib/context-menu-service-communication-model';

import { UiService } from '../services/ui.service';
import { ChangeLoadSizeComponent } from '../change-load-size/change-load-size.component';
import { CopyLoadComponent } from '../copy-load/copy-load.component';
import { Subject, takeUntil } from 'rxjs';
import { Load } from '../load/lib/load';
import { ChangeLoadSize } from './lib/change-load-size.type';
import { ColorService } from '../services/color.service';
import { loadMaterial } from '../lib/helper/load-material';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.less']
})
export class ContextMenuComponent implements OnInit, OnDestroy {
  protected color: number = 0x0;
  public colorPanelOpenState = false;
  public flooringPanelOpenState = false;
  public model: ContextMenuServiceCommunicationModel;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private uiService: UiService,
    private service: ContextMenuService,
    protected colors: ColorService,
    private profileService: ProfileService
  ) {
    this.color = colors.color.value;
  }

  ngOnInit(): void {
    this.service
      .modelChanged()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((model) => {
        this.model = model;
        this.color = this.model.color;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public rotateHorizontal() {
    if (!this.model.canRotateHorizontal) {
      return;
    }
    this.closeMenu();
    this.service.rotateHorizontal(); //podmiana w gravityloads koniecznie
  }

  public rotateVertical() {
    if (!this.model.canRotateVertical) {
      return;
    }
    this.closeMenu();
    this.service.rotateVertical(); //podmiana w gravityloads koniecznie
  }

  public deleteLoad() {
    this.closeMenu();
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          load: this.model.selectedLoads.map((l) => l.idx).join(', '),
          action: ConfirmationDialogAction.deleteLoad
        }
      }
    );

    confirmationDialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((confirmed) => {
        if (confirmed === true) {
          this.service.deleteLoad();
        } else {
          this.service.close();
        }
      });
  }

  public floorableOptionChange(name: string, event: MatCheckboxChange) {
    this.closeMenu();
    const checked = event.checked;
    const load: Load = this.model.selectedLoad;
    load[name] = checked;
    this.service.changeFlooring({
      floorableTop: load.floorableTop,
      floorableBottom: load.floorableBottom
    });
  }

  public colorChangeCompleted(event: ColorEvent) {
    this.closeMenu();
    this.color = this.colors.getHexValue(event.color.hex);
    this.model.selectedLoad.mesh.obj.material = loadMaterial(this.color, {
      loadBordersIntensity:
        this.profileService.currentSettings.loadBordersIntensity,
      loadTransparency: this.profileService.currentSettings.loadTransparency
    });
    this.service.changeColor(this.color);
  }

  public closeMenu() {
    // console.log('close menu');
    this.service.close();
  }

  public changeSize() {
    this.closeMenu();

    //to trzeba dla każdego typu Irregular SHapes z osobna

    const data: ChangeLoadSize = {
      idx: this.model.selectedLoad.idx,
      // póki co dla cuboid
      length: this.model.selectedLoad.cuboidHull.length,
      width: this.model.selectedLoad.cuboidHull.width,
      height: this.model.selectedLoad.cuboidHull.height,
      weight: this.model.selectedLoad.weight
    };
    const changeSizeComponentRef = this.dialog.open(ChangeLoadSizeComponent, {
      data
    });
    changeSizeComponentRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: ChangeLoadSize) => {
        if (result === null || typeof result === 'undefined') {
          this.service.close();
        } else {
          result.length = this.uiService.getLengthInDefaultUnit(result.length);
          result.width = this.uiService.getLengthInDefaultUnit(result.width);
          result.height = this.uiService.getLengthInDefaultUnit(result.height);
          result.weight = this.uiService.getWeightInDefaultUnit(result.weight);
          this.service.changeSize(result);
        }
      });
  }

  public copy() {
    this.closeMenu();
    const data = {
      idx: this.model.selectedLoad.idx,
      cnt: 1
    };
    const copyLoadComponentRef = this.dialog.open(CopyLoadComponent, {
      data
    });
    copyLoadComponentRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result === null || typeof result === 'undefined') {
          this.service.close();
        } else {
          this.service.copyLoad(result);
        }
      });
  }
}
