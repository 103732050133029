import { LoadFactory } from 'src/app/load/lib/load-factory';
import { VehicleFactory } from 'src/app/vehicle/lib/vehicle-factory';
import { v4 as uuidv4 } from 'uuid';
import { PositionerResponse } from '../../lib/communication/positioner.response';

export class CalculationsBundle {
  uuid: string;
  createdAt: Date;
  projectId: string;
  projectName: string;
  calculations: PositionerResponse[];

  constructor(
    obj: any,
    vehicleFactory: VehicleFactory,
    loadFactory: LoadFactory
  ) {
    Object.assign(this, obj);
    if (!this.uuid) {
      this.uuid = uuidv4();
    }
    if (!this.createdAt) {
      this.createdAt = new Date();
    }
    this.calculations = (this.calculations || []).map(
      (c) => new PositionerResponse(c, vehicleFactory, loadFactory)
    );
  }

  get name(): string {
    return this.calculations.map((c) => c.vehicle.fullName).join(', ');
  }

  get loadsCount(): number {
    return this.allLoads.length;
  }

  get allLoads() {
    return this.calculations.reduce(
      (prev, calc) => [...prev, ...calc.allLoads],
      []
    );
  }
}
