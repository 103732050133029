import { Load } from '../../load/lib/load';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { PositionerRequest } from '../../lib/communication/positioner.request';

export class DuplicateVehicleRequest extends PositionerRequest {
  constructor(loads: Load[], context: VehicleContext) {
    super({
      historyUuid: context.getHistoryUuid(),
      uuid: context.getUuid(),
      initialTimestamp: context.getInitialTimestamp(),
      message: 'test',
      // existingLoads: context.getExistingLoads(),
      existingLoads: [],
      loads: context.getLoads(),
      addedLoads: loads,
      vehicle: context.getVehicle(),
      matrix: context.getMatrix(),
      orbitControlsState: context.getOrbitControlsState(),
      projectId: context.getProjectId(),
      settings: context.getSettings()
    });
  }
}
