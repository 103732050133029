import { Load } from 'src/app/load/lib/load';
import { LoadFactory } from 'src/app/load/lib/load-factory';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { VehicleFactory } from 'src/app/vehicle/lib/vehicle-factory';
import { Vector } from './vector';

export class VehicleResponse {
  uuid: string; //uuidv4
  message: string;
  loads: Load[];
  vehicles: Vehicle[];
  matrix: Vector[];

  constructor(
    obj: any,
    vehicleFactory: VehicleFactory,
    loadFactory: LoadFactory
  ) {
    Object.assign(this, obj);
    if (this.vehicles) {
      this.vehicles = this.vehicles.map((v) => vehicleFactory.recreate(v));
    }
    if (this.loads) {
      this.loads = this.loads.map((l) => loadFactory.recreateLoad(l));
    }
  }
}
