<div *ngIf="form.Axles.length === 0">
  <ng-container i18n>Nie zdefiniowano osi</ng-container>&nbsp;
  <ng-container *ngIf="type === 'vehicle'" i18n>pojazdu</ng-container>
  <ng-container *ngIf="type !== 'vehicle'" i18n>naczepy</ng-container>
</div>
<app-axle
  *ngFor="let axleForm of form.Axles | orderBy : 'axle.order'"
  [form]="axleForm"
  (deleteAxleEvent)="deleteAxle($event)"
  (axleChangedEvent)="applyAxle($event)"
>
</app-axle>
<div style="text-align: right">
  <button
    mat-mini-fab
    class="action text-primary"
    (click)="addAxle()"
    i18n-aria-label
    aria-label="Dodaj oś"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
