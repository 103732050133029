import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { EmptyVehicle } from './empty-vehicle';
import {
  BufferGeometry,
  DirectionalLight,
  Float32BufferAttribute,
  Material,
  Mesh,
  MeshStandardMaterial,
  Vector3
} from 'three';
import { Constants } from '../../../lib/constants';

export class EmptyVehicleMesh extends VehicleMesh {
  private indices = [];
  private vertices = [];

  private length: number;
  private height: number;
  private width: number;

  public constructor(empty: EmptyVehicle) {
    super('empty', empty);
    this.init();
  }

  public getLength(): number {
    return this.length * 1;
  }

  public getWidth(): number {
    return this.width * 1;
  }

  public getHeight(): number {
    return this.height * 1;
  }

  private init() {
    this.mesh.geometry = new BufferGeometry();

    const material = new MeshStandardMaterial({ color: 0x838996 });
    //const material = new MeshStandardMaterial({ color: 0x838996 });
    material.transparent = true;
    material.depthWrite = false;
    material.opacity = 0.5;
    material.metalness = 1;
    material.roughness = 0.5;

    // const position = new Vector3(0, Constants.SCENE_FLOOR_Y, 0);

    // this.addGLTF(material, position, 100);
    // this.addGLTF();
    // this.material.metalness = 1;
    // this.material.roughness = 0.5;
    // this.getBufferGeometry();
  }

  private getBufferGeometry() {}
}
