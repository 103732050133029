<div *ngIf="isMobile; else desktop" class="mobile-info">
  <img src="assets/images/logo.png" alt="SmartLoad" />
  <p i18n>
    <b>Smartload.pl</b> umożliwia rozmieszczanie na ekranie minimalnie 14 cali
    lub większym. Na smartfon można wyeksportować gotowy projekt załadunku.
    Prosimy o przejście na laptop lub komputer stacjonarny.
  </p>
</div>

<ng-template #desktop>
  <div clsss="page" *ngIf="user | async; let user">
    <app-header></app-header>
    <div class="main-content">
      <app-info></app-info>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-loader
    *ngIf="uiService.showLoader$ | async"
    (close)="uiService.setLoading(false)"
  ></app-loader>
</ng-template>
