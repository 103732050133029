import { Observable, throwError } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { MessageService } from '../../messenger/message.service';
import { Message } from 'src/app/messenger/message';
import { TimeoutError } from './timeout-error';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private messenger: MessageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse | TimeoutError) => {
        if (
          err instanceof HttpErrorResponse &&
          (err.status === 401 || err.status === 403)
        ) {
          this.messenger.critical(
            new Message(
              $localize`Zaloguj się ponownie`,
              $localize`Sesja wygasła, prosimy zalogować się ponownie.`
            )
          );
        } else if (err instanceof TimeoutError) {
          this.messenger.critical(
            new Message($localize`Wystąpił problem`, err.message)
          );
        } else {
          this.messenger.error(
            new Message($localize`Wystąpił problem`, err.message)
          );
        }
        return throwError(() => err);
      })
    );
  }
}
