import { Vector3 } from 'three';
import { VectorMesh } from '../../../vehicle/space/lib/vector-mesh';
import { TextLabel } from '../TextLabel';

export abstract class VectorDimensionLabel extends TextLabel {
  public constructor(protected vector: VectorMesh, value: number, scale = 1.0) {
    super(
      value + '',
      new Vector3(),
      0,
      'cuboid-label fs14' + ' ' + 'scale-' + scale.toString().replace('.', '_')
    );
    this.updateLabelPosition();
  }

  protected abstract updateLabelPosition(): void;
}
