import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { Warehouse } from './warehouse';
import { BufferGeometry, Float32BufferAttribute } from 'three';
import { Constants as Config } from 'src/app/config/constants';

/*export class WarehouseMesh extends VehicleMesh {
  private indices = [];
  private vertices = [];

  public constructor(warehouse: Warehouse) {
    super('warehouse', warehouse);

    this.init();
  }

  private init() {
    this.mesh.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    console.log(
      'warehouse length',
      this.vehicle.length,
      'warehouse width',
      this.vehicle.width,
      'warehouse.height',
      this.vehicle.height
    );
    // const x0 = -this.vehicle.length / 2;
    // const y0 = -this.vehicle.height / 2;
    // const z0 = -this.vehicle.width / 2;
    const x0 = 0;
    const y0 = 0;
    const z0 = 0;
    const x1 = x0 + this.vehicle.length;
    const y1 = y0 + this.vehicle.height;
    const z1 = z0 + this.vehicle.width;

    let groupStart = 0;
    let groupCount = 0;

    // const x1 = this.vehicle.length;
    // const y1 = this.vehicle.height;
    // const z1 = this.vehicle.width;

    this.vertices.push(x0, y0, z0);

    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    this.buildWireframe();

    this.addSpaces();
    // this.addAxles();
  }

  
}*/

export class WarehouseMesh extends VehicleMesh {
  private warehouse: Warehouse;

  private indices = [];
  private vertices = [];

  private length: number;
  private height: number;
  private width: number;

  public constructor(warehouse: Warehouse) {
    super('warehouse', warehouse);

    this.length = warehouse.length * Config.DIMENSION_SCALING;
    this.height = warehouse.height * Config.DIMENSION_SCALING;
    this.width = warehouse.width * Config.DIMENSION_SCALING;

    this.warehouse = warehouse;
    this.init();
  }

  private init() {
    this.mesh.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    const x0 = 0;
    const y0 = 0;
    const z0 = 0;
    this.vertices.push(x0, y0, z0);

    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    this.buildWireframe();
    this.addSpaces();
  }

  //ten startX tylko tu wykorzystywany inaczej order niepotrebny
  /*protected addSpaces() {
    this.mesh.geometry.computeBoundingBox();

    for (const space of this.vehicle.spaces) {
      const mesh = space.mesh;
      this.mesh.geometry.computeBoundingBox();

      mesh.position.y = space.offset.y;

      mesh.position.x = space.offset.x;
      mesh.position.z = space.offset.z;

      mesh.meshObj.geometry.computeBoundingBox();
      this.boundingBoxes.push(mesh.meshObj.geometry.boundingBox);

      this.mesh.add(mesh.meshObj);
    }
  }*/

  protected addSpaces(startX?: number, startY?: number) {
    this.mesh.geometry.computeBoundingBox();

    let spaceOffsetX = 0;
    startX ?? this.mesh.geometry.boundingBox.max.x;

    console.log('this.mesh.position', this.mesh.position);
    for (const space of this.vehicle.spaces) {
      const mesh = space.mesh;
      this.mesh.geometry.computeBoundingBox();
      /*      let offsetY = startY ?? this.mesh.geometry.boundingBox.min.y;
      if (offsetY === -Infinity) {
        offsetY = startY;
        if (typeof offsetY === 'undefined') {
          console.log('mesh starting point not set');
        }
      }*/

      // mesh.position.y = offsetY;

      console.log(
        'space.offset.x',
        space.offset.x,
        'spaceOffsetX',
        spaceOffsetX
      );

      //mesh.position.copy(this.mesh.position);
      mesh.position.x = space.offset.x * Config.DIMENSION_SCALING;
      mesh.position.y = space.offset.y * Config.DIMENSION_SCALING;
      mesh.position.z = space.offset.z * Config.DIMENSION_SCALING;
      // mesh.position.x = space.offset.x + spaceOffsetX;

      // console.log('spaceOffsetX', spaceOffsetX);

      mesh.meshObj.geometry.computeBoundingBox();
      this.boundingBoxes.push(mesh.meshObj.geometry.boundingBox);

      this.mesh.add(mesh.meshObj);
      spaceOffsetX +=
        space.length * Config.DIMENSION_SCALING +
        this.vehicle.trailerSpacing * Config.DIMENSION_SCALING;
    }
  }
}
