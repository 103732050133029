import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, map, take } from 'rxjs';
import { VehicleContext } from '../lib/model/vehicle-context';
import { LoadComponentService } from '../services/load-component.service';
import { ContextService } from '../vehicle/context/context.service';
import { Vehicle } from '../vehicle/lib/vehicle';
import {
  MatSelectionList,
  MatSelectionListChange
} from '@angular/material/list';
import { MatOption } from '@angular/material/core';
import { SceneService } from '../scene/scene.service';

@Component({
  selector: 'app-pallets-loading-modal',
  templateUrl: './pallets-loading-modal.component.html',
  styleUrls: ['./pallets-loading-modal.component.less']
})
export class PalletsLoadingModalComponent implements OnInit {
  @ViewChild('pallets') selectedPallets: MatSelectionList;
  @ViewChild('selectAll') allSelected: MatOption;
  protected pallets$: Observable<VehicleContext[]>;
  protected error: string = null;
  constructor(
    contextService: ContextService,
    private sceneService: SceneService,
    private componentService: LoadComponentService
  ) {
    this.pallets$ = contextService.getAvailableVehicles().pipe(
      map((contexts) => {
        return contexts.filter((context) => context.canBeLoadedOnVehicle());
      })
    );
  }

  ngOnInit(): void {}

  close() {
    this.componentService.clear('PalletsLoadingModalComponent');
  }

  onSelection(event: MatSelectionListChange) {
    const selected = event.source.selectedOptions.selected.length;
    if (selected > 0) {
      this.error = null;
    }
  }

  selectAllPallets() {
    if (this.allSelected.selected) {
      this.selectedPallets.selectAll();
    } else {
      this.selectedPallets.deselectAll();
    }
  }

  onSubmit() {
    const selected: VehicleContext[] =
      this.selectedPallets.selectedOptions.selected
        .filter((option) => option.value instanceof VehicleContext)
        .map((option) => option.value);
    if (selected.length === 0) {
      this.error = $localize`Zaznacz przynajmniej jedną paletę`;
      return;
    }
    this.sceneService
      .loadPallets(selected)
      .pipe(take(1))
      .subscribe((result) => {
        this.close();
      });
  }
}
