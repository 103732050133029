<a
  href="{{ subscriptionsUrl }}"
  target="_blank"
  class="subscription-info"
  *ngIf="profile$ | async; let profile"
>
  <ng-container i18n>Funkcje premium do dnia:</ng-container>
  <span>
    {{ profile.expireOn ? (profile.expireOn | date: 'dd.MM.YYYY') : '∞' }}
  </span>
</a>
