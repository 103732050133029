//import THREE = require("three");
import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { BufferGeometry, Float32BufferAttribute } from 'three';
import { TShape } from './t-shape';

export class TShapeMesh extends LoadMesh {
  private a: number;
  private b: number;
  private c: number;
  private d: number;
  private e: number;

  private height: number;
  public constructor(object: TShape, settings: LoadDisplaySettings) {
    super('t-shape', object.color, settings, object.uuid);
    this.obj.geometry = new BufferGeometry();
    this.a = object.a;
    this.b = object.b;
    this.c = object.c;
    this.d = object.d;
    this.e = object.e;
    this.height = object.height;
    this.init();
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getName(): string {
    return (
      '(' +
      this.a +
      'x' +
      this.b +
      'x' +
      this.c +
      'x' +
      this.d +
      'x' +
      this.height +
      ')'
    );
  }

  private init() {
    const indices = [];
    const vertices = [];
    const a0 = -this.a / 2;
    const a1 = this.a / 2;
    const b0 = -(this.b + this.d) / 2;
    const b1 = (this.b + this.d) / 2;
    const b = this.b;
    const c = this.c;
    const d = this.d;
    const e = this.e;
    const h0 = -this.height / 2;
    const h1 = this.height / 2;

    // loading space
    vertices.push(a0, h0, b0); // 0
    vertices.push(a1, h0, b0); // 1
    vertices.push(a1, h0, b0 + b); // 2
    vertices.push(a0 + c + e, h0, b0 + b); // 3
    vertices.push(a0 + c + e, h0, b1); // 4
    vertices.push(a0 + c, h0, b1); // 5
    vertices.push(a0 + c, h0, b0 + b); // 6
    vertices.push(a0, h0, b0 + b); // 7

    vertices.push(a0, h1, b0); // 8
    vertices.push(a1, h1, b0); // 9
    vertices.push(a1, h1, b0 + b); // 10
    vertices.push(a0 + c + e, h1, b0 + b); // 11
    vertices.push(a0 + c + e, h1, b1); // 12
    vertices.push(a0 + c, h1, b1); // 13
    vertices.push(a0 + c, h1, b0 + b); // 14
    vertices.push(a0, h1, b0 + b); // 15

    // botom
    indices.push(6, 7, 0);
    indices.push(6, 0, 1);
    indices.push(6, 1, 2);
    indices.push(6, 3, 4);
    indices.push(6, 4, 5);

    //top
    indices.push(14, 15, 8);
    indices.push(14, 8, 9);
    indices.push(14, 9, 10);
    indices.push(14, 11, 12);
    indices.push(14, 12, 13);

    //walls

    indices.push(0, 1, 8);
    indices.push(9, 8, 1);

    indices.push(1, 2, 9);
    indices.push(10, 9, 2);

    indices.push(2, 3, 10);
    indices.push(11, 10, 3);

    indices.push(3, 4, 11);
    indices.push(12, 11, 4);

    indices.push(4, 5, 12);
    indices.push(13, 12, 5);

    indices.push(5, 6, 13);
    indices.push(14, 13, 6);

    indices.push(6, 7, 14);
    indices.push(15, 14, 7);

    indices.push(7, 0, 15);
    indices.push(8, 15, 0);

    this.obj.geometry.setIndex(indices);
    this.obj.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(vertices, 3)
    );
    this.obj.geometry.computeVertexNormals();

    this.prepareMesh();
  }
}
