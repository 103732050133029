import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonModule as SpaceCommonModule } from '../../common/common.module';
import { FormComponent } from './form/form.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { OnlyNumberDirective } from 'src/app/directives/only-number.directive';

@NgModule({
  declarations: [FormComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SpaceCommonModule,
    ScrollingModule,
    DirectivesModule
  ],
  exports: [FormComponent, OnlyNumberDirective]
})
export class BoxModule {}
