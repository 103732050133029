import { Load } from '../../load/lib/load';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { PositionerRequest } from '../../lib/communication/positioner.request';
import { ChangeLoadSize } from '../../context-menu/lib/change-load-size.type';

export class ChangeSizeRequest extends PositionerRequest {
  constructor(loadsToUpdate: Load[], context: VehicleContext) {
    super({
      uuid: context.getUuid(),
      historyUuid: context.getHistoryUuid(),
      initialTimestamp: context.getInitialTimestamp(),
      message: 'test',
      // existingLoads: context.getExistingLoads(),
      existingLoads: [],
      loads: context.getLoads(),
      addedLoads: loadsToUpdate,
      vehicle: context.getVehicle(),
      matrix: context.getMatrix(),
      maxLoadWidth: 0,
      action: 'change-size',
      orbitControlsState: context.getOrbitControlsState(),
      projectId: context.getProjectId(),
      settings: context.getSettings()
    });
  }
}
