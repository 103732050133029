<mat-expansion-panel (opened)="opened = true" (closed)="opened = false">
  <mat-expansion-panel-header>
    <mat-panel-title i18n>Ustawienia ładunku</mat-panel-title>
  </mat-expansion-panel-header>
  <div class="settings-container">
    <div [formGroup]="form" class="options">
      <mat-checkbox formControlName="preserveOrder" i18n
        >Zachowaj kolejność ładowania</mat-checkbox
      >
    </div>
  </div>
</mat-expansion-panel>
