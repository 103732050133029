import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  take,
  map,
  Subject,
  catchError,
  mergeMap,
  shareReplay,
  merge,
  filter
} from 'rxjs';
import { HttpService } from '../..//services/http.service';
import { environment } from '../../../environments/environment';
import { Load } from './load';
import { LoadFactory } from './load-factory';

@Injectable({
  providedIn: 'root'
})
export class LoadService extends HttpService {
  protected url = environment.apiUrl + '/database/load';
  protected listUrl = this.url + '/list';

  protected sortByProp = 'volume';
  private cache$: Observable<Load[]>;
  private update$ = new Subject<void>();
  private updates$: Observable<Load[]>;

  constructor(protected http: HttpClient, private loadFactory: LoadFactory) {
    super(http);
    this.listUrl = this.url + '/list';
    this.updates$ = this.update$.pipe(mergeMap(() => this.fetchLoads()));
  }

  get loads$(): Observable<Load[]> {
    if (!this.cache$) {
      this.cache$ = this.fetchLoads().pipe(shareReplay(1));
    }
    return merge(this.cache$, this.updates$).pipe(
      map((loads) => this.sortedBy(loads, this.sortByProp))
    );
  }

  getByShape(shape: string): Observable<Load[]> {
    return this.loads$.pipe(
      map((list) => list.filter((l) => l.shape === shape))
    );
  }

  public update(refresh: boolean = false) {
    if (refresh) {
      this.refresh();
    }
    this.update$.next();
  }

  public refresh() {
    this.cache$ = null;
  }

  protected fetchLoads(): Observable<Load[]> {
    return this.http.get<Load[]>(this.listUrl).pipe(
      take(1),
      map((results) =>
        results.map((r) => this.loadFactory.recreateLoad(r)).filter((l) => !!l)
      ),
      // tap((cuboids) => // console.log('cuboids fetched ' + cuboids.length)),
      catchError(this.handleError('getCuboids', []))
    );
  }

  protected sortedBy(items: Load[], prop: string): Load[] {
    return items.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
  }
}
