export const environment = {
  production: false,
  apiUrl: 'https://wr-server3d.evomedia.pl/api/v1',
  authUrl: 'https://wr-accounts.evomedia.pl',
  profileUrl: 'https://wr-smartload.evomedia.pl/moje-konto',
  //upgradePlanUrl: 'https://wr-smartload.evomedia.pl/pl/moje-konto/wybierz-plan',
  upgradePlanUrl: 'https://wr-smartload.evomedia.pl/pl/moje-konto/wybierz-plan',
  subscriptionsUrl: 'https://wr-smartload.evomedia.pl/moje-konto/subskrypcje',
  authRealm: 'smartload',
  authClient: '3d-app-wr',
  disableWarehouses: false,
  disablePalletsAsLoad: false,
  disableMassDistribution: true
};
