export class LabelComponentModel {
  public showNumber = true;
  public showName = false;
  public showWeight = false;
  public showDimensions = false;
  public showFlooring = false;
  public showGravityCenter = false;
  public fontSize = 0;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }

  displayLoadRelatedLabels(): boolean {
    return (
      this.showNumber ||
      this.showName ||
      this.showWeight ||
      this.showDimensions ||
      this.showFlooring
    );
  }
}
