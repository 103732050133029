<div class="modal-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1>Mój garaż</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <div *ngIf="list$ | async; let list">
        <div class="list">
          <div *ngIf="list.length === 0">
            <strong i18n>Brak rozmieszczeń w Twoim garażu.</strong> <br />
            <ng-container i18n
              >Aby zapisać rozmieszczenie użyj przycisku 'Zapisz układ jako
              szablon'.</ng-container
            ><br />
          </div>
          <div *ngFor="let calc of list" class="row">
            <img
              src="{{ calc.thumbnail }}"
              alt="{{ calc.vehicle?.fullName }}"
              class="thumbnail"
            />
            <div class="info">
              <div>
                <div>
                  {{ calc.vehicle?.fullName }}
                </div>
              </div>
              <div class="date">
                {{ calc.createdAt | date : 'dd.MM.YYYY HH:mm' }}
              </div>
              <div class="actions">
                <button
                  mat-raised-button
                  class="mat-button mat-accent btn-secondary"
                  type="button"
                  (click)="openCalculation(calc)"
                  i18n
                >
                  Otwórz
                </button>
                <button
                  mat-raised-button
                  class="mat-button mat-accent btn-secondary"
                  type="button"
                  (click)="removeCalculation(calc)"
                  i18n
                >
                  Usuń
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
