import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, startWith } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';

/*
 * Convert weight units from kg to current UI unit
 * Usage:
 *   value | weightUnit
 * Example if tonnes:
 *   {{ 2000 | weightUnit }}
 *   formats to: 2
 */
@Pipe({ name: 'weightUnit' })
export class WeightUnitPipe implements PipeTransform {
  private unit: Observable<string>;

  constructor(private ui: UiService) {
    this.unit = ui.getWeightUnit().pipe(startWith(ui.getCurrentWeightUnit()));
  }

  transform(gValue: number): Observable<number> {
    return this.unit.pipe(
      map((toUnit) => this.ui.convertWeightToUnit(gValue, toUnit))
    );
  }
}
