<div class="history-buttons">
  <button
    mat-raised-button
    color="default"
    (click)="historyBack()"
    *ngIf="undoButtonActive"
    i18n-matTooltip
    matTooltip="Cofnij ostatnią akcję"
    [matTooltipPosition]="'left'"
    [matTooltipShowDelay]="500"
    #undoTooltip="matTooltip"
  >
    <mat-icon class="center-vertical">undo</mat-icon>
  </button>
  <button
    mat-raised-button
    color="default"
    (click)="historyForward()"
    *ngIf="redoButtonActive"
    i18n-matTooltip
    matTooltip="Ponów ostatnią akcję"
    [matTooltipPosition]="'above'"
    [matTooltipShowDelay]="500"
    #redoTooltip="matTooltip"
  >
    <mat-icon class="center-vertical">redo</mat-icon>
  </button>
</div>
