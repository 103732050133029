import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlooringComponent } from './type/flooring/flooring.component';
import { ProtrusionComponent } from './type/protrusion/protrusion.component';
import { InfoComponent } from './info.component';
import { InfoService } from './info.service';
import { MaterialModule } from '../material.module';
import { BlockRotationComponent } from './type/block-rotation/block-rotation.component';
import { VerticalRotationComponent } from './type/vertical-rotation/vertical-rotation.component';

@NgModule({
  declarations: [
    InfoComponent,
    FlooringComponent,
    ProtrusionComponent,
    BlockRotationComponent,
    VerticalRotationComponent
  ],
  imports: [CommonModule, MaterialModule],
  exports: [InfoComponent],
  providers: [InfoService]
})
export class InfoModule {}
