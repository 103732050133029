import { Vector3 } from 'three';
import { VectorDimensionLabel } from './VectorDimensionLabel';
import { Constants as Config } from 'src/app/config/constants';

export class VectorWidthLabel extends VectorDimensionLabel {
  protected updateLabelPosition(): void {
    const position = new Vector3();
    this.vector.mesh.getWorldPosition(position);
    position.x += (this.vector.length / 2 - 30) * Config.DIMENSION_SCALING;
    position.y -= (this.vector.height / 2) * Config.DIMENSION_SCALING;
    this.setPosition(position);
  }
}
