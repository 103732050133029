import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';

import { Observable, Subject, takeUntil } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { Cylinder } from '../lib/cylinder';
import { LoadService as LoadUiService } from '../../../load.service';
import { LoadFactory } from 'src/app/load/lib/load-factory';

@Component({
  selector: 'app-cylinder-form',
  templateUrl: './form.component.html',
  styleUrls: ['../../../load.component.less', './form.component.less']
})
export class FormComponent implements OnInit, OnDestroy {
  @Input() events: Observable<void>;
  @Output() addLoadEvent = new EventEmitter<any>();
  // @Input() events: Observable<void>;

  // @ViewChild('weight') weightElement: ElementRef;
  // @ViewChild('cnt') cntElement: ElementRef;

  @ViewChild('search') searchElement: ElementRef;

  public dataPanelOpenState = false;
  public form: UntypedFormGroup;
  public listOpened = false;
  private item: Cylinder;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private loadUiService: LoadUiService,

    private fb: UntypedFormBuilder,
    public ui: UiService,
    private loadFactory: LoadFactory
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.onSubmit());
    this.loadUiService.selected$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((items) => {
        this.fillForm(items[0].load as Cylinder);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  toggleList() {
    this.listOpened = !this.listOpened;
    if (this.listOpened) {
      setTimeout(() => {}, 0);
    }
  }

  fillForm(item: Cylinder): void {
    if (!item) {
      return;
    }
    this.item = this.loadFactory.recreateLoad({
      ...item,
      type: 'cylinder'
    }) as Cylinder;
    (this.form.controls.cylinder as UntypedFormControl)?.setValue(
      this.item.name
    );
    (this.form.controls.radius as UntypedFormControl)?.setValue(
      this.ui.getLengthInCurrentUnit(this.item.radius)
    );
    (this.form.controls.height as UntypedFormControl)?.setValue(
      this.ui.getLengthInCurrentUnit(this.item.height)
    );
  }

  onSubmit() {
    if (!this.form.valid) {
      const errors = this.getValidationErrors();
      // console.log('errors', errors);
      // console.log('form not valid');
    } else {
      // console.log('form valid');
      const item = this.item;
      item.name = this.form.controls.cylinder.value;
      item.radius = this.ui.getLengthInDefaultUnit(
        +this.form.controls.radius.value
      );
      item.height = this.ui.getLengthInDefaultUnit(
        +this.form.controls.height.value
      );
      this.addLoadEvent.emit(item);
    }
  }

  private getValidationErrors() {
    const errors: any = {};
    Object.keys(this.form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        errors[key] = {};
        Object.keys(controlErrors).forEach((keyError) => {
          errors[key][keyError] = controlErrors[keyError];
        });
      }
    });
    return errors;
  }

  private createForm() {
    this.form = this.fb.group({
      cylinder: [null, Validators.required],
      radius: [null, Validators.compose([Validators.required])],
      height: [null, Validators.compose([Validators.required])]
    });
  }
}
