<mat-tab-group
  #tabs
  class="big"
  mat-align-tabs="center"
  animationDuration="0ms"
  (selectedTabChange)="tabChanged($event)"
  [selectedIndex]="currentTabIndex"
>
  <mat-tab *ngFor="let item of items" label="{{ item.name }}">
    <ng-template mat-tab-label>
      <div>{{ item.name }}</div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
