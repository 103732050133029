<div class="modal-box file-import-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Edytuj {{ context?.getVehicle().name }}</h1>
      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <app-name #name [default]="context?.getVehicle().name || ''"></app-name>
      <ng-container #spaces></ng-container>
      <div class="buttons">
        <button mat-raised-button (click)="onSubmit()" cdkFocusInitial>
          <mat-icon>save</mat-icon>
          <ng-container i18n>Zapisz</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
