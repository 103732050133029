import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { User, UserModel } from '../lib/model/user';
import {
  KeycloakEvent,
  KeycloakEventType,
  KeycloakService
} from 'keycloak-angular';
import { from } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private user = new ReplaySubject<User>(1);

  public tokenExpired$ = new Observable<KeycloakEvent>();
  public tokenRefreshed$ = new Observable<KeycloakEvent>();

  constructor(private keycloak: KeycloakService) {
    try {
      this.keycloak
        .getKeycloakInstance()
        .loadUserInfo()
        .then((value: any) => {
          const user = new UserModel(value);
          this.user.next(user);
        });
    } catch (error) {
      console.error(error);
      this.user.next(null);
    }

    this.tokenExpired$ = from(this.keycloak.keycloakEvents$).pipe(
      filter((event) => event.type === KeycloakEventType.OnTokenExpired)
    );

    this.tokenRefreshed$ = from(this.keycloak.keycloakEvents$).pipe(
      filter((event) => event.type === KeycloakEventType.OnAuthRefreshSuccess)
    );
  }

  getUser(): Observable<User> {
    return this.user.asObservable();
  }

  updateToken() {
    this.keycloak.updateToken().then(() => {
      this.setTokenFromKeycloakInstance();
    });
  }

  logout() {
    this.keycloak.logout();
  }

  refreshToken() {
    this.keycloak.updateToken(70);
  }

  setTokenFromKeycloakInstance() {}
}
