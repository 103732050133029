import { Component, Input, OnInit } from '@angular/core';
import { map, Observable } from 'rxjs';
import { CalculationsBundle } from './lib/calculations-bundle';
import { ReportsService } from './reports.service';
import { ReportGenerator } from './lib/report-generator';
import { DatePipe } from '@angular/common';
import { UiService } from '../services/ui.service';

@Component({
  selector: 'app-settings-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less']
})
export class ReportsComponent implements OnInit {
  @Input() projectId: string;
  public reports$: Observable<CalculationsBundle[]>;
  constructor(
    private reporstService: ReportsService,
    private datePipe: DatePipe,
    private uiService: UiService
  ) {}

  ngOnInit(): void {
    console.log('settings-modal/reports.ts: init');
    this.reports$ = this.reporstService
      .getReports$(this.projectId)
      .pipe(
        map((res) => res.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)))
      );
  }

  update() {
    this.reporstService.update(this.projectId);
  }

  download(report: CalculationsBundle) {
    const generator = new ReportGenerator(
      report,
      this.datePipe,
      this.uiService
    );
    generator.generate();
  }
}
