<div class="modal-box suggest-larger-vehicle-box">
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header border-bottom" cdkDragHandle>
      <span i18n>Podsumowanie załadunku</span>
      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <div class="modal-box__content">
      <div>
        <div class="summary">
          <ng-container i18n>Rozmieszczono na:</ng-container>
          <strong>{{ items.length }} x {{ vehicleName }}</strong>
        </div>
        <strong> </strong>
        <ng-container i18n
          >Ładunek rozplanowano wg poniższego zestawienia:</ng-container
        ><br />

        <button (click)="exportExcel()" mat-raised-button color="primary">
          <mat-icon>download</mat-icon>
          <ng-container i18n>Pobierz XLSX</ng-container>
        </button>
        <mat-tab-group class="condensed">
          <mat-tab
            *ngFor="let item of items; index as i"
            label="{{ i + 1 }}"
            role="tab"
          >
            <div class="vehicle-header">
              {{ item.vehicle.fullName }} ({{
                item.vehicle | vehicleDimensions | async
              }}) :
            </div>

            <table mat-table [dataSource]="item.loadsCondensed.loads">
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef i18n>Nazwa</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.cnt }} x {{ element.load.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="dimensions">
                <th mat-header-cell *matHeaderCellDef i18n>Wymiary</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.load | dimensions | async }}
                </td>
              </ng-container>

              <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef i18n>Waga</th>
                <td mat-cell *matCellDef="let element">
                  {{
                    element.load.weight | weightUnit | async | number : '0.0-2'
                  }}
                  {{ ui.getWeightUnit() | async }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedLoadColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedLoadColumns"
              ></tr>
            </table>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
