<div class="modal-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Rozkład mas</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <!-- TODO -->
      <div class="buttons">
        <button
          mat-raised-button
          class="btn-accent"
          type="button"
          (click)="close()"
        >
          <ng-container i18n>Zamknij</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
