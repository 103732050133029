import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { Load } from '../../lib/load';
import { LoadEventObject } from '../../lib/load-event-object';
import { LoadShape } from '../../lib/load-shape';
import { AmountComponent } from '../amount/amount.component';
import { ColorComponent } from '../color/color.component';
import { FlooringComponent } from '../flooring/flooring.component';
import { WeightComponent } from '../weight/weight.component';

@Component({
  selector: 'app-form-auto',
  templateUrl: './auto.component.html',
  styleUrls: ['./auto.component.less']
})
export class AutoComponent {
  @Input() shape: LoadShape;
  @Input() events: Observable<void>;

  @Output() addLoadEvent = new EventEmitter<LoadEventObject[]>();

  constructor(private uiService: UiService) {}

  eventsSubject: Subject<void> = new Subject<void>();
  load: Load = null;

  @ViewChild('amount') amount: AmountComponent;
  @ViewChild('weight') weight: WeightComponent;

  @ViewChild('color') color: ColorComponent;
  @ViewChild('flooring') flooring: FlooringComponent;

  onSubmit() {
    this.emitEventToChild();
  }

  emitEventToChild() {
    this.eventsSubject.next();
  }

  itemSelected(item: Load) {
    // console.log('auto.component.ts: item selected', item);
    this.load = item;
    if (item.weight) {
      this.weight.setValue(this.uiService.getWeightInCurrentUnit(item.weight));
    }
  }

  addLoad(item: Load) {
    const cnt = this.amount.getValue();
    if (cnt > 3000) {
      // console.log('FIXME: dodać reagowanie na walidację podformularzy');
      return;
    }
    const weight = this.uiService.getWeightInDefaultUnit(
      +this.weight.getValue()
    );
    const color = this.color.getValue();
    const protrusionLength = this.flooring.getProtrusionLength();
    const protrusionWidth = this.flooring.getProtrusionWidth();

    item.weight = weight;
    item.floorableTop = this.flooring.floorableTop;
    item.floorableBottom = this.flooring.floorableBottom;
    item.protrusionLength = protrusionLength;
    item.protrusionWidth = protrusionWidth;
    item.color = color;

    const eventObject = new LoadEventObject();
    eventObject.load = item;
    eventObject.cnt = cnt;
    this.addLoadEvent.emit([eventObject]);
  }
}
