import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { UiService } from '../../../services/ui.service';
import { Vehicle } from '../../lib/vehicle';

/*
 * Display vehicle dimensions in current length unit
 * Usage:
 *   value | vehicleDimensions
 * Example:
 *  const vehicle = { width: 800, height: 1000, length: 1200 };
 *   {{ vehicle | vehicleDimensions }}
 *   output when length unit is cm: 120x80x100 cm
 */
@Pipe({ name: 'vehicleDimensions' })
export class VehicleDimensionsPipe implements PipeTransform {
  private unit: Observable<string>;

  constructor(private ui: UiService, private numberPipe: DecimalPipe) {
    this.unit = ui.getLengthUnit();
  }

  transform(vehicle: Vehicle, numberFormat: string = '0.0-2'): Subject<string> {
    // return new ReplaySubject<string>(1);
    // console.log(vehicle.name, vehicle, vehicle.dimensions);
    const observable = new ReplaySubject<string>(1);
    this.unit.subscribe((toUnit) => {
      const converter = this.ui.getOutputLengthConverter(toUnit);
      let output = '';
      for (const d of vehicle.dimensions) {
        const formattedDimension = this.numberPipe.transform(
          converter * d,
          numberFormat
        );
        output += formattedDimension + 'x';
      }
      output = output.substring(0, output.length - 1);
      output += ' ' + toUnit;
      observable.next(output);
    });
    return observable;
  }
}
