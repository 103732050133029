<div class="modal-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag style="min-width: 320px">
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Osie pojazdu</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content" style="--input-width: 270px">
      <ng-container [ngSwitch]="vehicle.type">
        <ng-container *ngSwitchCase="'bus'" [ngTemplateOutlet]="bus">
        </ng-container>
        <ng-container *ngSwitchCase="'truck'" [ngTemplateOutlet]="truck">
        </ng-container>
        <ng-container *ngSwitchDefault>
          ng-template *ngSwitchDefault does not exist vehicle type
          {{ vehicle.type }}
        </ng-container>
      </ng-container>
    </div>

    <div class="buttons">
      <button mat-raised-button (click)="onSubmit()" cdkFocusInitial>
        <mat-icon>save</mat-icon>
        <ng-container i18n>Zapisz</ng-container>
      </button>
    </div>
  </div>
</div>

<ng-template #bus>
  <app-space type="vehicle" [form]="form.Vehicle"></app-space>
</ng-template>

<ng-template #truck>
  <mat-tab-group>
    <mat-tab label="Pojazd" i18n-label>
      <app-space type="vehicle" [form]="form.Vehicle"></app-space>
    </mat-tab>

    <mat-tab
      *ngFor="let spaceForm of form.Spaces | orderBy : 'space.order'"
      label="Naczepa {{ spaceForm.space.order + 1 }}"
      ><app-space type="space" [form]="spaceForm"></app-space>
    </mat-tab>
  </mat-tab-group>
</ng-template>
