import { Injectable } from '@angular/core';
import { Camera, Vector3, Group } from 'three';
import { UiService } from '../services/ui.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LabelComponentModel } from './label.component.model';
import { LabelRenderer } from '../lib/text-label/LabelRenderer';
import {
  TextLabel,
  LoadCenterLabel,
  LoadLengthLabel,
  LoadWidthLabel,
  LoadHeightLabel,
  FlooringTopLabel,
  FlooringBottomLabel,
  ProtrusionBottomLabel,
  FlooringLevelLabel,
  VehicleAxleLabel,
  SpaceAxleLabel,
  GridAxisLabel
} from '../lib/text-label';
import { MyScene } from '../scene/lib/MyScene';
import { VehicleContext } from '../lib/model/vehicle-context';
import { Grid3DLines } from '../lib/helper/grid-3d-lines';
import { Load } from '../load/lib/load';
import { VectorMesh } from '../vehicle/space/lib/vector-mesh';
import {
  VectorLengthLabel,
  VectorHeightLabel,
  VectorWidthLabel
} from '../lib/text-label/vector';
import { Constants as Config } from '../config/constants';

@Injectable({
  providedIn: 'root'
})
export class LabelService {
  private canvas: HTMLCanvasElement;

  private shouldRefresh = new BehaviorSubject<boolean>(null);
  private labelRenderer: LabelRenderer;
  private scene: MyScene;
  private camera: Camera;
  private loadLabels: Group;
  private spaceLabels: Group;
  private gridAxisLabels: Group;
  private matrixLabels: Group;

  private loads: Load[];
  private context: VehicleContext;
  private radius: number;
  private vector: VectorMesh;

  private scale = 1.0;

  private model = new BehaviorSubject<LabelComponentModel>(
    new LabelComponentModel()
  );

  constructor(private uiService: UiService) {}

  public init(
    canvas: HTMLCanvasElement,
    scene: MyScene,
    camera: Camera,
    scale = 1.0
  ) {
    this.canvas = canvas;
    this.scene = scene;
    this.camera = camera;
    this.scale = scale;

    this.labelRenderer = new LabelRenderer();
    this.adjustRendererDimensions();
    this.labelRenderer.attach();

    this.reinitializeLoadRelatedLabels();
    this.reinitializeSpaceRelatedLabels();
    this.reinitializeMatrixLabels();

    this.render();
  }

  public getModel(): Observable<LabelComponentModel> {
    return this.model.asObservable();
  }

  public setModel(model: LabelComponentModel) {
    this.model.next(model);
    this.drawLoadRelatedLabels();
    this.shouldRefresh.next(true);
  }

  public initModel(model: LabelComponentModel) {
    this.model.next(model);
  }

  public setData(context: VehicleContext, loads: Load[], radius: number) {
    //console.log('set Data called', loads.length);
    this.context = context;
    this.loads = loads;
    this.radius = radius;
    this.updateLabels();
  }

  public showMatrixLabels(vector: VectorMesh) {
    this.vector = vector;
    this.drawMatrixLabels();
    this.shouldRefresh.next(true);
  }

  public getAllLabels(): TextLabel[] {
    return []
      .concat(this.loadLabels.children)
      .concat(this.spaceLabels.children)
      .concat(this.gridAxisLabels.children)
      .concat(this.matrixLabels.children);
  }

  public shouldRefreshInvoked(): Observable<boolean> {
    return this.shouldRefresh.asObservable();
  }

  public setSize(width, height) {
    this.labelRenderer.setSize(width, height);
  }

  public updateLabels() {
    if (!this.scene) {
      return;
    }
    //console.log('update Labels Called');
    this.adjustRendererDimensions();
    this.drawLoadRelatedLabels();
    this.drawSpaceRelatedLabels();
    this.drawMatrixLabels();
  }

  public render() {
    //console.log('render labels');
    if (this.labelRenderer) {
      this.labelRenderer.render(this.scene, this.camera);
    }
  }

  private drawMatrixLabels() {
    this.reinitializeMatrixLabels();
    if (!this.vector) {
      return;
    }
    const length = new VectorLengthLabel(
      this.vector,
      this.uiService.getLengthInCurrentUnit(this.vector.length),
      this.scale
    );
    const height = new VectorHeightLabel(
      this.vector,
      this.uiService.getLengthInCurrentUnit(this.vector.height),
      this.scale
    );
    const width = new VectorWidthLabel(
      this.vector,
      this.uiService.getLengthInCurrentUnit(this.vector.width),
      this.scale
    );
    this.addLabel(this.matrixLabels, length);
    this.addLabel(this.matrixLabels, height);
    this.addLabel(this.matrixLabels, width);
  }

  private drawLoadRelatedLabels() {
    const settings = this.model.value;
    this.reinitializeLoadRelatedLabels();
    if (!settings.displayLoadRelatedLabels()) {
      return;
    }
    for (const load of this.loads) {
      this.drawLoadCenterLabels(load);
      if (settings.showDimensions) {
        this.drawLoadDimensions(load);
      }
      if (settings.showFlooring) {
        this.drawLoadFlooring(load);
      }
    }
  }

  private drawSpaceRelatedLabels() {
    this.reinitializeSpaceRelatedLabels();
    this.drawAxleLabels();

    for (const space of this.context.getVehicle().enabledSpaces) {
      if (
        this.context.getSettings().grid.show &&
        this.context.getSettings().grid.showLabels
      ) {
        const grids = space.mesh.getGridHelpers();
        if (grids.length > 0) {
          this.drawGridAxisLabels(grids[0]);
        }
      }

      if (!space.settings || !space.settings.maxHeightEnabled) {
        continue;
      }
      const label = new FlooringLevelLabel(space, this.uiService, this.scale);
      this.addLabel(this.spaceLabels, label);
    }
  }

  private drawGridAxisLabels(grid: Grid3DLines) {
    const absoluteGridPosition = new Vector3();
    grid.obj.getWorldPosition(absoluteGridPosition);
    grid.xLabelsPositions.forEach((pos) => {
      const label = new GridAxisLabel(
        new Vector3(
          absoluteGridPosition.x + pos.x,
          absoluteGridPosition.y + pos.y,
          absoluteGridPosition.z + pos.z
        ),
        pos.x / Config.DIMENSION_SCALING,
        this.context.getSettings().grid.unit,
        this.uiService,
        this.scale
      );
      this.addLabel(this.gridAxisLabels, label);
    });
    grid.yLabelsPositions.forEach((pos) => {
      const label = new GridAxisLabel(
        new Vector3(
          absoluteGridPosition.x + pos.x,
          absoluteGridPosition.y + pos.y,
          absoluteGridPosition.z + pos.z
        ),
        pos.y / Config.DIMENSION_SCALING,
        this.context.getSettings().grid.unit,
        this.uiService,
        this.scale
      );
      this.addLabel(this.gridAxisLabels, label);
    });
    grid.zLabelsPositions.forEach((pos) => {
      const label = new GridAxisLabel(
        new Vector3(
          absoluteGridPosition.x + pos.x,
          absoluteGridPosition.y + pos.y,
          absoluteGridPosition.z + pos.z
        ),
        pos.z / Config.DIMENSION_SCALING,
        this.context.getSettings().grid.unit,
        this.uiService,
        this.scale
      );
      this.addLabel(this.gridAxisLabels, label);
    });
  }

  private drawAxleLabels() {
    const vehicle = this.context.getVehicle();
    for (const axle of vehicle.axles) {
      const label = new VehicleAxleLabel(
        axle,
        vehicle.mesh.position.x,
        this.uiService,
        this.scale
      );
      this.addLabel(this.spaceLabels, label);
    }

    for (const space of vehicle.spaces) {
      for (const axle of space.axles) {
        const label = new SpaceAxleLabel(
          axle,
          vehicle.mesh.position.x + space.mesh.position.x,
          this.uiService,
          this.scale
        );
        this.addLabel(this.spaceLabels, label);
      }
    }
  }

  private drawLoadCenterLabels(load: Load) {
    const label = new LoadCenterLabel(
      load,
      this.model.value,
      this.uiService,
      this.scale
    );
    this.addLabel(this.loadLabels, label);
  }

  private drawLoadDimensions(load: Load) {
    this.addLabel(
      this.loadLabels,
      new LoadLengthLabel(
        load,
        this.uiService.getLengthInCurrentUnit(load.cuboidHull.length),
        this.scale
      )
    );
    this.addLabel(
      this.loadLabels,
      new LoadWidthLabel(
        load,
        this.uiService.getLengthInCurrentUnit(load.cuboidHull.width),
        this.scale
      )
    );
    this.addLabel(
      this.loadLabels,
      new LoadHeightLabel(
        load,
        this.uiService.getLengthInCurrentUnit(load.cuboidHull.height),
        this.scale
      )
    );
  }

  private drawLoadFlooring(load: Load) {
    this.addLabel(this.loadLabels, new FlooringTopLabel(load, this.scale));
    this.addLabel(this.loadLabels, new FlooringBottomLabel(load, this.scale));
    if (load.floorableBottom) {
      //this.addLabel(this.loadLabels, new ProtrusionBottomLabel(gravityLoad, this.scale)); // wyłączone pokazywanie nawisu
    }
  }

  private addLabel(group: Group, label: TextLabel) {
    label.setRadius(this.radius);
    group.add(label);
  }

  private reinitializeLoadRelatedLabels() {
    this.removeGroup(this.loadLabels);
    this.loadLabels = this.initGroup();
  }

  private reinitializeMatrixLabels() {
    this.removeGroup(this.matrixLabels);
    this.matrixLabels = this.initGroup();
  }

  private reinitializeSpaceRelatedLabels() {
    this.removeGroup(this.spaceLabels);
    this.spaceLabels = this.initGroup();
    this.removeGroup(this.gridAxisLabels);
    this.gridAxisLabels = this.initGroup();
  }

  /**
   * Rozmiar musi być aktualizowany w momencie dodawania ładunków.
   * Jeśli dodanie ładunku spowoduje pojawienie się pionowego paska przewijania (z powodu listy ładunków z lewej),
   * to rozmiar canvas się zmieni, więc trzeba zmienić i kontener etykiet, żeby nie pojawiał się dodatkowo poziomy pasek przewijania.
   */
  private adjustRendererDimensions() {
    if (!this.labelRenderer) {
      return;
    }
    this.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
  }

  private removeGroup(group: Group) {
    while (group && group.children.length > 0) {
      const object = group.children[0];
      object.parent.remove(object);
    }
    this.scene.remove(group);
  }

  private initGroup() {
    const group = new Group();
    this.scene.add(group);
    return group;
  }
}
