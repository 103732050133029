import { Space } from './space';
import { Constants } from '../../lib/constants';
import { Statistics } from 'src/app/lib/model/statistics';
import { BoxGeometry, Mesh } from 'three';
import { Constants as Config } from 'src/app/config/constants';

export class FreeFloorMesh extends Mesh {
  protected space: Space;
  protected stats: Statistics;

  private length: number;
  private width: number;

  constructor(space: Space, stats: Statistics) {
    super();
    this.position.x = stats.oxLDM * Config.DIMENSION_SCALING;
    this.position.y = 0;
    this.position.z = 0;

    this.name = 'free-floor-mesh';
    this.space = space;
    this.stats = stats;
    this.material = Constants.HIGHLIGHT_MATERIAL;
    this.init();
  }

  private init() {
    this.length = this.stats.fxLDM * Config.DIMENSION_SCALING;
    this.width = this.space.width * Config.DIMENSION_SCALING;

    this.geometry = new BoxGeometry(
      this.length,
      1 * Config.DIMENSION_SCALING,
      this.width
    );
    this.translateX(this.length / 2);
    this.translateZ(this.width / 2);
    //this.translateY(-this.space.height / 2 + 1);
  }
}
