import {
    DoubleSide,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    PlaneGeometry,
    Quaternion,
    Raycaster,
    Vector3
} from 'three';

const _raycaster = new Raycaster();

const _tempVector = new Vector3();


const _changeEvent = { type: 'change' };
const _mouseDownEvent = { type: 'mouseDown' };
const _mouseUpEvent = { type: 'mouseUp', mode: null };
const _objectChangeEvent = { type: 'objectChange' };

class TransformControls extends Object3D {

    constructor(camera, domElement) {

        super();

        if (domElement === undefined) {

            console.warn('THREE.TransformControls: The second parameter "domElement" is now mandatory.');
            domElement = document;

        }

        this.isTransformControls = true;

        this.visible = false;
        this.domElement = domElement;
        this.domElement.style.touchAction = 'none'; // disable touch scroll

        const _plane = new TransformControlsPlane();
        this._plane = _plane;
        this.add(_plane);

        const scope = this;

        // Defined getter, setter and store for a property
        function defineProperty(propName, defaultValue) {

            let propValue = defaultValue;

            Object.defineProperty(scope, propName, {

                get: function () {

                    return propValue !== undefined ? propValue : defaultValue;

                },

                set: function (value) {

                    if (propValue !== value) {

                        propValue = value;
                        _plane[propName] = value;

                        scope.dispatchEvent({ type: propName + '-changed', value: value });
                        scope.dispatchEvent(_changeEvent);

                    }

                }

            });

            scope[propName] = defaultValue;
            _plane[propName] = defaultValue;
        }

        // Define properties with getters/setter
        // Setting the defined property will automatically trigger change event
        // Defined properties are passed down to plane

        defineProperty('camera', camera);
        defineProperty('object', undefined);
        defineProperty('enabled', true);
        defineProperty('axis', null);
        defineProperty('mode', 'translate');
        defineProperty('translationSnap', null);
        defineProperty('rotationSnap', null);
        defineProperty('scaleSnap', null);
        defineProperty('space', 'world');
        defineProperty('size', 1);
        defineProperty('dragging', false);
        defineProperty('showX', true);
        defineProperty('showY', true);
        defineProperty('showZ', true);

        // Reusable utility variables

        const worldPosition = new Vector3();
        const worldPositionStart = new Vector3();
        const worldQuaternion = new Quaternion();
        const worldQuaternionStart = new Quaternion();
        const cameraPosition = new Vector3();
        const cameraQuaternion = new Quaternion();
        const pointStart = new Vector3();
        const pointEnd = new Vector3();
        const rotationAxis = new Vector3();
        const rotationAngle = 0;
        const eye = new Vector3();

        // TODO: remove properties unused in plane and gizmo

        defineProperty('worldPosition', worldPosition);
        defineProperty('worldPositionStart', worldPositionStart);
        defineProperty('worldQuaternion', worldQuaternion);
        defineProperty('worldQuaternionStart', worldQuaternionStart);
        defineProperty('cameraPosition', cameraPosition);
        defineProperty('cameraQuaternion', cameraQuaternion);
        defineProperty('pointStart', pointStart);
        defineProperty('pointEnd', pointEnd);
        defineProperty('rotationAxis', rotationAxis);
        defineProperty('rotationAngle', rotationAngle);
        defineProperty('eye', eye);

        this._offset = new Vector3();
        this._startNorm = new Vector3();
        this._endNorm = new Vector3();
        this._cameraScale = new Vector3();

        this._parentPosition = new Vector3();
        this._parentQuaternion = new Quaternion();
        this._parentQuaternionInv = new Quaternion();
        this._parentScale = new Vector3(1, 1, 1);

        this._worldScaleStart = new Vector3();
        this._worldQuaternionInv = new Quaternion();
        this._worldScale = new Vector3();

        this._positionStart = new Vector3();
        this._quaternionStart = new Quaternion();
        this._scaleStart = new Vector3();

        this._getPointer = getPointer.bind(this);
        this._onPointerDown = onPointerDown.bind(this);
        this._onPointerHover = onPointerHover.bind(this);
        this._onPointerMove = onPointerMove.bind(this);
        this._onPointerUp = onPointerUp.bind(this);

        this.domElement.addEventListener('pointerdown', this._onPointerDown);
        this.domElement.addEventListener('pointermove', this._onPointerHover);
        this.domElement.addEventListener('pointerup', this._onPointerUp);

    }

    // updateMatrixWorld  updates key transformation variables
    updateMatrixWorld() {

        if (this.object !== undefined) {

            this.object.updateMatrixWorld();

            if (this.object.parent === null) {

                console.error('TransformControls: The attached 3D object must be a part of the scene graph.');

            } else {

                this.object.parent.matrixWorld.decompose(this._parentPosition, this._parentQuaternion, this._parentScale);

            }

            this.object.matrixWorld.decompose(this.worldPosition, this.worldQuaternion, this._worldScale);

            this._parentQuaternionInv.copy(this._parentQuaternion).invert();
            this._worldQuaternionInv.copy(this.worldQuaternion).invert();

        }

        this.camera.updateMatrixWorld();
        this.camera.matrixWorld.decompose(this.cameraPosition, this.cameraQuaternion, this._cameraScale);

        if (this.camera.isOrthographicCamera) {

            this.camera.getWorldDirection(this.eye).negate();

        } else {

            this.eye.copy(this.cameraPosition).sub(this.worldPosition).normalize();

        }

        super.updateMatrixWorld(this);

    }

    pointerHover(pointer) {

        if (this.object === undefined || this.dragging === true) return;

        _raycaster.setFromCamera(pointer, this.camera);

    }

    pointerDown(pointer) {
        if (this.object === undefined || this.dragging === true) return;
        _mouseDownEvent.pointer = pointer;
        if (pointer.button !== 0) {
            this.dispatchEvent(_mouseDownEvent);
            return;
        }

        _raycaster.setFromCamera(pointer, this.camera);

        const planeIntersect = intersectObjectWithRay(this._plane, _raycaster, true);

        if (planeIntersect) {
            if (!this.object.parent) {
                return;
            }
            this.object.updateMatrixWorld();
            this.object.parent.updateMatrixWorld();

            this._positionStart.copy(this.object.position);
            this._quaternionStart.copy(this.object.quaternion);
            this._scaleStart.copy(this.object.scale);

            this.object.matrixWorld.decompose(this.worldPositionStart, this.worldQuaternionStart, this._worldScaleStart);

            this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart);

        }
        this.dragging = true;
        _mouseDownEvent.mode = this.mode;
        this.dispatchEvent(_mouseDownEvent);


    }

    simulatePointerDown(pointer) {
        this.dragging = false;
        this.pointerDown(pointer);
    }

    pointerMove(pointer) {

        const axis = this.axis;
        const object = this.object;


        if (object === undefined || axis === null || this.dragging === false || pointer.button !== - 1) return;
        if (object.userData.locked) {
            return;
        }
        _raycaster.setFromCamera(pointer, this.camera);

        const planeIntersect = intersectObjectWithRay(this._plane, _raycaster, true);
        if (!planeIntersect) return;

        if (object.userData.changedY) {
            this._positionStart.y = object.userData.changedY;
            object.userData.changedY = undefined;
            this.object.matrixWorld.decompose(this.worldPositionStart, this.worldQuaternionStart, this._worldScaleStart);
            this._positionStart.copy(this.object.position);
            this._quaternionStart.copy(this.object.quaternion);
            this._scaleStart.copy(this.object.scale);
            this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart);
            //this.simulatePointerDown(pointer);
            //return;
        }
        if (object.userData.changedX && object.userData.changedZ) {
            object.userData.changedX = undefined;
            object.userData.changedZ = undefined;
            this.object.matrixWorld.decompose(this.worldPositionStart, this.worldQuaternionStart, this._worldScaleStart);
            this._positionStart.copy(this.object.position);
            this._quaternionStart.copy(this.object.quaternion);
            this._scaleStart.copy(this.object.scale);
            //this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart);

            //this.simulatePointerDown(pointer);
            //return;
            /*this._positionStart.copy(this.object.position);
            this._quaternionStart.copy(this.object.quaternion);
            this._scaleStart.copy(this.object.scale);

            this.object.matrixWorld.decompose(this.worldPositionStart, this.worldQuaternionStart, this._worldScaleStart);
            //this.pointStart.copy(planeIntersect.point).sub(this.worldPositionStart);
            //return;*/
        }


        this.pointEnd.copy(planeIntersect.point).sub(this.worldPositionStart);

        this._offset.copy(this.pointEnd).sub(this.pointStart);
        //console.log('offset', this._offset, planeIntersect.point, this._parentQuaternion, this._parentScale);

        if (axis.indexOf('X') === - 1) this._offset.x = 0;
        if (axis.indexOf('Y') === - 1) this._offset.y = 0;
        if (axis.indexOf('Z') === - 1) this._offset.z = 0;

        this._offset.applyQuaternion(this._parentQuaternionInv).divide(this._parentScale);

        //console.log('changed by controls before', object.position, this._positionStart, this._offset, this.pointStart, this.pointEnd);
        object.position.copy(this._offset).add(this._positionStart);
        //console.log('changed by controls after', object.position);


        this.dispatchEvent(_changeEvent);
        this.dispatchEvent(_objectChangeEvent);

    }

    pointerUp(pointer) {

        if (pointer.button !== 0) return;

        if (this.dragging && (this.axis !== null)) {
            _mouseUpEvent.pointer = pointer;
            _mouseUpEvent.mode = this.mode;
            this.dispatchEvent(_mouseUpEvent);

        }

        this.dragging = false;
        //this.axis = null;

    }

    dispose() {

        this.domElement.removeEventListener('pointerdown', this._onPointerDown);
        this.domElement.removeEventListener('pointermove', this._onPointerHover);
        this.domElement.removeEventListener('pointermove', this._onPointerMove);
        this.domElement.removeEventListener('pointerup', this._onPointerUp);

        this.traverse(function (child) {

            if (child.geometry) child.geometry.dispose();
            if (child.material) child.material.dispose();

        });

    }

    // Set current object
    attach(object) {

        this.object = object;
        this.visible = true;

        return this;

    }

    // Detach from object
    detach() {

        this.object = undefined;
        this.visible = false;
        //this.axis = null;

        return this;

    }

    reset() {

        if (!this.enabled) return;

        if (this.dragging) {

            this.object.position.copy(this._positionStart);
            this.object.quaternion.copy(this._quaternionStart);
            this.object.scale.copy(this._scaleStart);

            this.dispatchEvent(_changeEvent);
            this.dispatchEvent(_objectChangeEvent);

            this.pointStart.copy(this.pointEnd);

        }

    }

    getRaycaster() {

        return _raycaster;

    }

    setSize(size) {

        this.size = size;

    }


}

// mouse / touch event handlers

function getPointer(event) {

    if (this.domElement.ownerDocument.pointerLockElement) {

        return {
            x: 0,
            y: 0,
            button: event.button,
            mouseEvent: event
        };

    } else {

        const rect = this.domElement.getBoundingClientRect();

        return {
            x: (event.clientX - rect.left) / rect.width * 2 - 1,
            y: - (event.clientY - rect.top) / rect.height * 2 + 1,
            button: event.button,
            mouseEvent: event
        };

    }

}

function onPointerHover(event) {

    if (!this.enabled) return;

    switch (event.pointerType) {

        case 'mouse':
        case 'pen':
            this.pointerHover(this._getPointer(event));
            break;

    }

}

function onPointerDown(event) {
    if (!this.enabled) return;

    if (!document.pointerLockElement) {

        this.domElement.setPointerCapture(event.pointerId);

    }

    this.domElement.addEventListener('pointermove', this._onPointerMove);

    this.pointerHover(this._getPointer(event));
    this.pointerDown(this._getPointer(event));

}

function onPointerMove(event) {

    if (!this.enabled) return;
    this.pointerMove(this._getPointer(event));

}

function onPointerUp(event) {

    if (!this.enabled) return;

    this.domElement.releasePointerCapture(event.pointerId);

    this.domElement.removeEventListener('pointermove', this._onPointerMove);

    this.pointerUp(this._getPointer(event));

}

function intersectObjectWithRay(object, raycaster, includeInvisible) {

    const allIntersections = raycaster.intersectObject(object, true);

    for (let i = 0; i < allIntersections.length; i++) {

        if (allIntersections[i].object.visible || includeInvisible) {

            return allIntersections[i];

        }

    }

    return false;

}

//

// Reusable utility variables

const _alignVector = new Vector3(0, 1, 0);
const _identityQuaternion = new Quaternion();
const _dirVector = new Vector3();
const _tempMatrix = new Matrix4();

const _unitX = new Vector3(1, 0, 0);
const _unitY = new Vector3(0, 1, 0);
const _unitZ = new Vector3(0, 0, 1);

const _v1 = new Vector3();
const _v2 = new Vector3();
const _v3 = new Vector3();



//

class TransformControlsPlane extends Mesh {

    constructor() {

        super(
            new PlaneGeometry(100000, 100000, 2, 2),
            new MeshBasicMaterial({ visible: false, wireframe: true, side: DoubleSide, transparent: true, opacity: 0.1, toneMapped: false })
        );

        this.isTransformControlsPlane = true;

        this.type = 'TransformControlsPlane';

    }

    updateMatrixWorld(force) {

        let space = this.space;

        this.position.copy(this.worldPosition);

        if (this.mode === 'scale') space = 'local'; // scale always oriented to local rotation

        _v1.copy(_unitX).applyQuaternion(space === 'local' ? this.worldQuaternion : _identityQuaternion);
        _v2.copy(_unitY).applyQuaternion(space === 'local' ? this.worldQuaternion : _identityQuaternion);
        _v3.copy(_unitZ).applyQuaternion(space === 'local' ? this.worldQuaternion : _identityQuaternion);

        // Align the plane for current transform mode, axis and space.

        _alignVector.copy(_v2);

        switch (this.mode) {

            case 'translate':
            case 'scale':
                switch (this.axis) {

                    case 'X':
                        _alignVector.copy(this.eye).cross(_v1);
                        _dirVector.copy(_v1).cross(_alignVector);
                        break;
                    case 'Y':
                        _alignVector.copy(this.eye).cross(_v2);
                        _dirVector.copy(_v2).cross(_alignVector);
                        break;
                    case 'Z':
                        _alignVector.copy(this.eye).cross(_v3);
                        _dirVector.copy(_v3).cross(_alignVector);
                        break;
                    case 'XY':
                        _dirVector.copy(_v3);
                        break;
                    case 'YZ':
                        _dirVector.copy(_v1);
                        break;
                    case 'XZ':
                        _alignVector.copy(_v3);
                        _dirVector.copy(_v2);
                        break;
                    case 'XYZ':
                    case 'E':
                        _dirVector.set(0, 0, 0);
                        break;

                }

                break;
            case 'rotate':
            default:
                // special case for rotate
                _dirVector.set(0, 0, 0);

        }

        if (_dirVector.length() === 0) {

            // If in rotate mode, make the plane parallel to camera
            this.quaternion.copy(this.cameraQuaternion);

        } else {

            _tempMatrix.lookAt(_tempVector.set(0, 0, 0), _dirVector, _alignVector);

            this.quaternion.setFromRotationMatrix(_tempMatrix);

        }

        super.updateMatrixWorld(force);

    }

}

export { TransformControls, TransformControlsPlane };