<div class="space">
  <div>
    <mat-menu #menu>
      <button
        mat-menu-item
        *ngFor="let type of types; index as i"
        (click)="selectedVehicleType(type)"
      >
        <ng-container
          *ngTemplateOutlet="
            vehicleTypeTemplate;
            context: { name: type.name, icon: type.icon }
          "
        ></ng-container>
      </button>
    </mat-menu>
  </div>

  <ng-template #vehicleTypeTemplate let-name="name" let-icon="icon">
    <span class="icon">
      <img src="../../assets/images/{{ icon }}" alt="" />
    </span>
    <span>{{ name }}</span>
  </ng-template>

  <app-form
    [type]="type"
    [events]="eventsSubject.asObservable()"
    (addVehicleEvent)="addVehicle($event)"
  >
  </app-form>
</div>
