import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormComponent } from './form/form.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule as LoadCommonModule } from '../../common/common.module';
import { SelectComponent } from './select/select.component';
import { SelectListComponent } from './select-list/select-list.component';

@NgModule({
  declarations: [FormComponent, SelectComponent, SelectListComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LoadCommonModule,
    ScrollingModule,
    BrowserModule,
    BrowserAnimationsModule
  ],
  exports: [FormComponent, SelectComponent, SelectListComponent]
})
export class CuboidModule {}
