import { Load } from 'src/app/load/lib/load';
import { Vector3 } from 'three';
import { TextLabel } from './TextLabel';

export abstract class LoadDimensionLabel extends TextLabel {
  public constructor(protected load: Load, value: number, scale = 1.0) {
    super(value + '', new Vector3(), 0, '', scale);
    this.updateLabelPosition();
  }

  protected abstract updateLabelPosition(): void;
}
