import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HashShape } from '../lib/hash-shape';
import { LoadService } from '../../../lib/load.service';
import { Load } from 'src/app/load/lib/load';
import { LoadService as LoadUiService } from '../../../load.service';

@Component({
  selector: 'app-hash-shape-select',
  templateUrl: './select.component.html',
  styleUrls: ['../../../load.component.less', './select.component.less']
})
export class SelectComponent implements OnInit, OnDestroy {
  public items: HashShape[];
  public listOpened = false;
  public item: HashShape;
  public searchText: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: LoadService,
    private loadUiService: LoadUiService
  ) {}

  ngOnInit(): void {
    this.service.loads$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list: Load[]) => {
        this.items = list
          .filter((l: Load) => l.shape === 'hash-shape')
          .map((l: Load) => l as HashShape);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSearchText() {}
  toggleList() {
    this.listOpened = !this.listOpened;
    if (this.listOpened) {
      setTimeout(() => {
        // this.searchElement.nativeElement.focus();
      }, 0);
    }
  }

  select(item: HashShape): void {
    this.item = item;
    //this.loadUiService.updateSelected([item]);
  }
}
