<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <button mat-button mat-dialog-close class="close">
    <mat-icon>close</mat-icon>
  </button>
  <h1 mat-dialog-title cdkDragHandle>
    {{ title }}
  </h1>
  <mat-dialog-content>
    {{ content }}
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">
      <mat-icon>close</mat-icon><ng-container i18n>Anuluj</ng-container>
    </button>
    <button
      mat-button
      [mat-dialog-close]="data.confirmed"
      cdkFocusInitial
      color="primary"
    >
      <mat-icon>check</mat-icon>
      <ng-container i18n>Potwierdź</ng-container>
    </button>
  </div>
</div>
