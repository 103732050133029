import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { Observable, share, shareReplay, Subject, takeUntil } from 'rxjs';
import { FleetSelectFormComponent } from '../fleet-select/form/fleet-select-form.component';
import { Settings } from '../lib/model/settings';
import { Project } from '../projects/lib/project';
import { ProjectsService } from '../projects/projects.service';
import { SettingsModalService } from './settings-modal.service';
import { MenuCategory } from './lib/menu-category.enum';
import { FileValidator } from 'ngx-material-file-input';

interface LengthUnit {
  value: string;
  viewValue: string;
}

interface WeightUnit {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.less']
})
export class SettingsModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(FleetSelectFormComponent)
  fleetSelectComponent: FleetSelectFormComponent;

  settings: Settings;
  isLoading = false;

  lengthUnits: LengthUnit[] = [
    { value: 'mm', viewValue: $localize`milimetry` },
    { value: 'cm', viewValue: $localize`centrymetry` },
    { value: 'm', viewValue: $localize`metry` },
    { value: 'in', viewValue: $localize`cale` },
    { value: 'ft', viewValue: $localize`stopy` }
  ];

  weightUnits: WeightUnit[] = [
    { value: 'kg', viewValue: $localize`kilogramy` },
    { value: 't', viewValue: $localize`tony` },
    { value: 'lb', viewValue: $localize`funty` },
    { value: 'ts', viewValue: $localize`długie tony` },
    { value: 'ta', viewValue: $localize`krótkie tony` }
  ];

  menuCategory: MenuCategory = MenuCategory.Units;

  /**
   * 1MB
   */
  readonly maxLogoSize = 1048576;

  // do szablonu
  public get MenuCategory() {
    return MenuCategory;
  }
  protected project$: Observable<Project>;

  fleetError$ = new Subject<boolean>();

  public form = this.fb.group({
    lengthUnit: new UntypedFormControl(null, [Validators.required]),
    weightUnit: new UntypedFormControl(null, [Validators.required]),
    shareLoads: new UntypedFormControl(null, [Validators.required]),
    shareVehicles: new UntypedFormControl(null, [Validators.required]),
    autoAddCustomLoads: new UntypedFormControl(null, [Validators.required]),
    autoReload: new UntypedFormControl(null, [Validators.required]),
    loadBordersIntensity: new UntypedFormControl(null, [Validators.required]),
    loadTransparency: new UntypedFormControl(null, [Validators.required]),
    logo: new UntypedFormControl(null, [
      FileValidator.maxContentSize(this.maxLogoSize)
    ])
  });

  private unsubscribe$ = new Subject<void>();

  constructor(
    private service: SettingsModalService,
    private projectsService: ProjectsService,
    private fb: UntypedFormBuilder
  ) {
    this.project$ = this.projectsService.currentProject$.pipe(shareReplay());
  }

  ngOnInit(): void {
    this.service.profile$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile) => {
        this.isLoading = false;
        this.settings = profile.settings;
        (this.form.controls.lengthUnit as UntypedFormControl).setValue(
          this.settings.lengthUnit
        );
        (this.form.controls.weightUnit as UntypedFormControl).setValue(
          this.settings.weightUnit
        );
        (this.form.controls.shareLoads as UntypedFormControl).setValue(
          this.settings.shareLoads
        );
        (this.form.controls.shareVehicles as UntypedFormControl).setValue(
          this.settings.shareVehicles
        );
        (this.form.controls.autoAddCustomLoads as UntypedFormControl).setValue(
          this.settings.autoAddCustomLoads
        );
        (this.form.controls.autoReload as UntypedFormControl).setValue(
          this.settings.autoReload
        );
        (
          this.form.controls.loadBordersIntensity as UntypedFormControl
        ).setValue(this.settings.loadBordersIntensity);
        (this.form.controls.loadTransparency as UntypedFormControl).setValue(
          this.settings.loadTransparency * 10
        );
      });
  }

  ngAfterViewInit(): void {
    this.fleetSelectComponent.updateValues(this.settings.usedVehicles || []);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async onSubmit() {
    if (!this.fleetSelectComponent.isValid()) {
      this.fleetError$.next(true);
      return;
    }
    this.fleetError$.next(false);
    this.isLoading = true;
    let logo: string = null;
    const fileInput = this.form.get('logo').value;
    if (fileInput && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      logo = (await this.readLogoAsync(file)) as string;
    }
    this.service
      .saveSettings(
        new Settings({
          ...this.settings,
          lengthUnit: this.form.controls.lengthUnit.value,
          weightUnit: this.form.controls.weightUnit.value,
          shareLoads: this.form.controls.shareLoads.value,
          shareVehicles: this.form.controls.shareVehicles.value,
          autoAddCustomLoads: this.form.controls.autoAddCustomLoads.value,
          autoReload: this.form.controls.autoReload.value,
          loadBordersIntensity: this.form.controls.loadBordersIntensity.value,
          loadTransparency: this.form.controls.loadTransparency.value / 10,
          usedVehicles: this.fleetSelectComponent.getValues(),
          logo: logo
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newSettings) => {
        this.service.updateSettingsInView(newSettings);
        this.close();
      });
  }

  close(): void {
    this.service.close();
  }

  protected removeLogo() {
    this.form.get('logo').setValue(null);
    this.settings.logo = null;
  }

  private readLogoAsync(file: any) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }
}
