import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { SceneService } from '../scene/scene.service';
import { Load } from './lib/load';

import { LoadEventObject } from './lib/load-event-object';
import { LoadShape } from './lib/load-shape';
import { LoadService } from './load.service';

@Component({
  selector: 'app-load-module',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.less']
})
export class LoadComponent implements OnDestroy {
  eventsSubject: Subject<void> = new Subject<void>();
  public shape: LoadShape;
  public shapes: Array<LoadShape>;
  public saveForm: UntypedFormGroup;
  public fb: UntypedFormBuilder = new UntypedFormBuilder();

  public listType = 'none'; // system | user | none - lista typowe lub własne lub widok wyboru
  // userFormType = 'new'; // new | history - formularz Zdefiniuj nowy | lista Moje poprzednie

  private unsubscribe$ = new Subject<void>();

  constructor(private service: LoadService) {
    this.saveForm = this.fb.group({
      name: [null, Validators.required]
    });

    this.shapes = this.service.loadShapes;
    this.shape = this.shapes.find((x) => x.type === 'cuboid');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public selectedLoadShape(shape: LoadShape) {
    this.shape = shape;
  }

  createLoad(load: Load) {
    // console.log('createLoadEvent', load);
  }
  //addLoad to calculation
  addLoad(eventObject: LoadEventObject[]) {
    this.service
      .addLoadAmount(eventObject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe();
  }
}
