import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MenuState } from './lib/menu-state';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  // private menuType = new Subject<string>();
  private menuState = new Subject<MenuState>();

  constructor() {}

  get menuState$(): Observable<MenuState> {
    return this.menuState.asObservable();
  }

  openLoadings() {
    this.menuState.next(MenuState.loadingsOpen);
  }

  openVehicles() {
    this.menuState.next(MenuState.vehiclesOpen);
  }
  close() {
    this.menuState.next(MenuState.closed);
  }
}
