<div class="modal-box file-import-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Wybierz palety do załadunku</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <mat-selection-list
        #pallets
        class="text-left"
        (selectionChange)="onSelection($event)"
      >
        <mat-list-option
          #selectAll
          title="Zaznacz wszystkie"
          class="header-line"
          (click)="selectAllPallets()"
          i18n
          >Paleta</mat-list-option
        >
        <mat-list-option
          *ngFor="let pallet of pallets$ | async"
          [value]="pallet"
        >
          {{ pallet.getVehicle().fullName }}
          {{ pallet.getVehicle() | vehicleDimensions | async }}
        </mat-list-option>
      </mat-selection-list>
      <div class="buttons">
        <div *ngIf="error" class="error">{{ error }}</div>
        <button
          mat-raised-button
          class="btn-primary"
          type="button"
          (click)="onSubmit()"
        >
          <ng-container i18n>Załaduj</ng-container>
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
