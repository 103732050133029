import {
  FormBuilder,
  FormControl,
  FormRecord,
  UntypedFormGroup
} from '@angular/forms';

export class FleetForm extends UntypedFormGroup {
  get SystemTypes() {
    return this.controls.systemTypes as FormRecord;
  }

  get CustomTypes() {
    return this.controls.customTypes as FormRecord;
  }

  constructor(
    systemTypes: { value: string; name: string; selected: boolean }[],
    customTypes: { value: string; name: string; selected: boolean }[],
    private readonly fb: FormBuilder = new FormBuilder()
  ) {
    super(
      fb.group({
        systemTypes: fb.record({}),
        customTypes: fb.record({})
      }).controls
    );
    systemTypes.forEach(({ value, selected }) => {
      this.SystemTypes.addControl(value, new FormControl(selected));
    });
    customTypes.forEach(({ value, selected }) => {
      this.CustomTypes.addControl(value, new FormControl(selected));
    });
  }

  get valid() {
    const selectedCnt =
      Object.keys(this.SystemTypes.controls).filter(
        (c) => this.SystemTypes.controls[c].value === true
      ).length +
      Object.keys(this.CustomTypes.controls).filter(
        (c) => this.CustomTypes.controls[c].value === true
      ).length;
    return super.valid && selectedCnt > 0;
  }

  markAllAsTouched() {
    super.markAllAsTouched();
  }

  updateValues(selected: string[]) {
    Object.keys(this.SystemTypes.controls).forEach((type) => {
      this.SystemTypes.controls[type].setValue(selected.includes(type));
    });
    Object.keys(this.CustomTypes.controls).forEach((type) => {
      this.CustomTypes.controls[type].setValue(selected.includes(type));
    });
  }

  getSelectedTypes(): string[] {
    return Object.keys(this.SystemTypes.value)
      .filter((type) => this.SystemTypes.value[type])
      .concat(
        Object.keys(this.CustomTypes.value).filter(
          (type) => this.CustomTypes.value[type]
        )
      );
  }
}
