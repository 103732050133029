import { Load } from './load';

export class LoadEventObject {
  load: Load;
  cnt: number;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
