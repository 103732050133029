import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlyNumberDirective } from './only-number.directive';
import { LengthUnitPipe } from './length-unit.pipe';
import { WeightUnitPipe } from './weight-unit.pipe';

@NgModule({
  declarations: [OnlyNumberDirective, LengthUnitPipe, WeightUnitPipe],
  imports: [CommonModule],
  exports: [OnlyNumberDirective, LengthUnitPipe, WeightUnitPipe]
})
export class DirectivesModule {}
