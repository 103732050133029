/* eslint-disable @typescript-eslint/naming-convention */
import { Load } from './load';
import {
  Cuboid,
  Cylinder,
  LShape,
  TShape,
  HShape,
  HashShape,
  PalletLoad
} from '../type';

import { Injectable } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { Type } from '@angular/core';
import { ColorService } from 'src/app/services/color.service';

@Injectable({
  providedIn: 'root'
})
export class LoadFactory {
  readonly types = {
    '1/2 palety Euro': $localize`1/2 palety Euro`,
    GitterBox: $localize`GitterBox`,
    'Paleta Euro': $localize`Paleta Euro`,
    'Paleta przemysłowa (ISO)': $localize`Paleta przemysłowa (ISO)`,
    'Pojemnik IBC': $localize`Pojemnik IBC`,
    'Powiększona paleta Euro': $localize`Powiększona paleta Euro`
  };

  private typeMap: { [key: string]: Type<Load> } = {
    cuboid: Cuboid,
    undefined: Cuboid,
    cylinder: Cylinder,
    'l-shape': LShape,
    't-shape': TShape,
    'h-shape': HShape,
    'hash-shape': HashShape,
    EPAL1: PalletLoad,
    EPAL2: PalletLoad,
    EPAL3: PalletLoad,
    EPAL6: PalletLoad,
    EPAL7: PalletLoad,
    'EPAL-CP1': PalletLoad,
    'EPAL-CP2': PalletLoad,
    'EPAL-CP3': PalletLoad,
    'EPAL-CP4': PalletLoad,
    'EPAL-CP5': PalletLoad,
    'EPAL-CP6': PalletLoad,
    'EPAL-CP7': PalletLoad,
    'EPAL-CP8': PalletLoad,
    'EPAL-CP9': PalletLoad
  };

  public constructor(
    private profileService: ProfileService,
    private colors: ColorService
  ) {}
  recreateLoad(obj: any): Load {
    if (obj && this.types[obj.name]) {
      obj.name = this.types[obj.name];
    }
    obj.shape = obj.shape || 'undefined';

    if (typeof this.typeMap[obj.shape] === 'undefined') {
      console.error('unknown Load type', obj.shape);
      return null;
    }
    if (!obj.color) {
      obj.color = this.colors.randomColor();
    }
    return new this.typeMap[obj.shape](obj, {
      loadBordersIntensity:
        this.profileService.currentSettings.loadBordersIntensity,
      loadTransparency: this.profileService.currentSettings.loadTransparency
    });
  }
}
