<div
  [formGroup]="form"
  novalidate
  (ngSubmit)="onSubmit()"
  class="load-form full-width"
>
  <br />

  <mat-expansion-panel
    #dataPanel
    (opened)="dataPanelOpenState = true"
    (closed)="dataPanelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong i18n>Dostosuj wymiary</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field>
      <input
        matInput
        i18n-placeholder
        placeholder="Nazwa"
        formControlName="cylinder"
        autocomplete="off"
      />
      <mat-error *ngIf="form.controls['cylinder'].hasError('required')">
        <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
        <strong><ng-container i18n>wymagane</ng-container></strong>
      </mat-error>
    </mat-form-field>
    <div class="row">
      <mat-form-field>
        <input
          matInput
          i18n-placeholder
          placeholder="Promień ({{ ui.getLengthUnit() | async }})"
          formControlName="radius"
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['radius'].hasError('required')">
          <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
          <strong><ng-container i18n>wymagane</ng-container></strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          i18n-placeholder
          placeholder="Wysokość ({{ ui.getLengthUnit() | async }})"
          formControlName="height"
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['height'].hasError('required')">
          <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
          <strong><ng-container i18n>wymagane</ng-container></strong>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-expansion-panel>
</div>
