<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="label" i18n>
    Oceń rozmieszczenie, aby pomóc ulepszyć nasze algorytmy:
  </div>
  <div class="stars">
    <div
      *ngFor="let star of stars"
      [ngClass]="[star.class]"
      (click)="selectStar(star.id)"
    >
      <mat-icon>{{ star.icon }}</mat-icon>
    </div>
  </div>

  <div *ngIf="ratingError" class="error" i18n>
    Proszę ocenić rozmieszczenie (1 - słabe, 5 - bardzo dobre)
  </div>
  <div>
    <mat-form-field>
      <textarea
        matInput
        rows="3"
        i18n-placeholder
        placeholder="Dodatkowe informacje"
        [formControl]="description"
        autocomplete="off"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="spacer"></div>
  <div class="buttons">
    <button
      mat-raised-button
      class="btn-outlined btn-secondary save"
      (click)="sendRating()"
      i18n
    >
      Oceń
    </button>
    <button
      mat-raised-button
      class="btn-secondary mat-accent btn-outlined"
      (click)="closeDialog()"
      i18n
    >
      Anuluj
    </button>
  </div>
</div>
