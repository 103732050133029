<div
  class="tool"
  *ngIf="isNotEmptyContext()"
  [class.enabled]="tools.measurement === true"
  i18n-matTooltip
  matTooltip="Zmierz wolną przestrzeń"
  [matTooltipPosition]="'right'"
  [matTooltipShowDelay]="250"
  #measurementTooltip="matTooltip"
>
  <button mat-button (click)="toggleMeasurementTool()">
    <mat-icon>straighten</mat-icon>
  </button>
</div>
