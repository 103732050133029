//import THREE = require("three");
import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { BufferGeometry, Float32BufferAttribute } from 'three';
import { LShape } from './l-shape';

export class LShapeMesh extends LoadMesh {
  private a: number;
  private b: number;
  private c: number;
  private d: number;

  private height: number;

  public constructor(object: LShape, settings: LoadDisplaySettings) {
    super('l-shape', object.color, settings, object.uuid);
    this.obj.geometry = new BufferGeometry();
    this.a = object.a;
    this.b = object.b;
    this.c = object.c;
    this.d = object.d;
    this.height = object.height;
    this.init();
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getName(): string {
    return (
      '(' +
      this.a +
      'x' +
      this.b +
      'x' +
      this.c +
      'x' +
      this.d +
      'x' +
      this.height +
      ')'
    );
  }

  private init() {
    const indices = [];
    const vertices = [];
    const a0 = -this.a / 2;
    const a1 = this.a / 2;
    const b0 = -this.b / 2;
    const b1 = this.b / 2;
    const c = this.c;
    const d = this.d;
    const h0 = -this.height / 2;
    const h1 = this.height / 2;

    // loading space
    vertices.push(a0, h0, b1); // 0 right top front
    vertices.push(a1, h0, b1); // 1 right top back
    vertices.push(a1, h0, b1 - d); // 2 right bottom back
    vertices.push(a0 + c, h0, b1 - d); // 3 right bottom front
    vertices.push(a0 + c, h0, b0); // 4 left bottom front
    vertices.push(a0, h0, b0); // 5 left top front

    vertices.push(a0, h1, b1); // 6 right top front
    vertices.push(a1, h1, b1); // 7 right top back
    vertices.push(a1, h1, b1 - d); // 8 right bottom back
    vertices.push(a0 + c, h1, b1 - d); // 9 right bottom front
    vertices.push(a0 + c, h1, b0); // 10 left bottom front
    vertices.push(a0, h1, b0); // 11 left top front
    // botom
    indices.push(0, 1, 3); // doors bottom triangle
    indices.push(1, 2, 3); // doors top triangle

    indices.push(0, 3, 5); // front side bottom triangle
    indices.push(3, 4, 5); // front side top triangle

    // top
    indices.push(6, 7, 9); // doors bottom triangle
    indices.push(7, 8, 9); // doors top triangle

    indices.push(6, 9, 11); // front side bottom triangle
    indices.push(9, 10, 11); // front side top triangle

    // walls
    indices.push(0, 1, 6);
    indices.push(7, 6, 1);

    indices.push(1, 2, 7);
    indices.push(8, 7, 2);

    indices.push(2, 3, 8);
    indices.push(9, 8, 3);

    indices.push(3, 4, 9);
    indices.push(10, 9, 4);

    indices.push(4, 5, 10);
    indices.push(11, 10, 5);

    indices.push(5, 0, 11);
    indices.push(6, 11, 0);
    // groupCount = 5 * 6;
    // this.object.addGroup(groupStart, groupCount, 0);

    this.obj.geometry.setIndex(indices);
    this.obj.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(vertices, 3)
    );
    this.obj.geometry.computeVertexNormals();

    this.prepareMesh();

    // this.object = new THREE.CyliderGeometry(
    // this.radius,
    // this.radius,
    // this.height,
    // 128
    // );
  }
}
