import { CuboidHull } from 'src/app/load/lib/cuboid-hull';
import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { Load } from '../../../lib/load';
import { HashShapeMesh } from './hash-shape-mesh';

export class HashShape extends Load {
  shape = 'hash-shape';
  a: number;
  b: number;
  c: number;
  d: number;
  e: number;
  f: number;
  g: number;
  h: number;
  i: number;
  j: number;
  height: number;

  get cuboidHull(): CuboidHull {
    return new CuboidHull({
      width: this.a + this.b + this.c + this.d + this.e,
      length: this.f + this.g + this.h + this.i + this.j,
      height: this.height
    });
  }

  get volume(): number {
    return this.area * this.height;
  }
  get area(): number {
    return (
      (this.a + this.b + this.c + this.d + this.e) * (this.g + this.i) +
      (this.f + this.g + this.h + this.i + this.j) * (this.b + this.d)
    );
  }

  get fullName(): string {
    return `Hash ${this.name}`;
  }
  get fullDescription(): string {
    return `Hash ${this.a}x${this.b}x${this.c}x${this.d}`;
  }

  get descriptiveDimensions(): number[] {
    return [this.a, this.b, this.c, this.d];
  }

  createMesh(settings: LoadDisplaySettings): LoadMesh {
    this.mesh = new HashShapeMesh(this, settings);
    return this.mesh;
  }
}
