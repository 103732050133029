import { Load } from './load';
export class LoadListItem {
  highlight = false;

  set selected(val: boolean) {
    this._selected = val;
    this.highlight = val;
  }

  get selected(): boolean {
    return this._selected;
  }

  public constructor(
    public load: Load,
    public cnt: number = 1,
    private _selected: boolean = false
  ) {}
}
