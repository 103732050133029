<div class="active-criteria" *ngIf="currentVehicle?.isVehicleChosen()">
  <ng-container *ngIf="settings.showCriteria; else collapsed">
    <div class="list">
      <ng-container *ngFor="let setting of activeSettings">
        <ng-container
          *ngTemplateOutlet="
            pill;
            context: {
              $implicit: setting.label,
              enabled: setting.on,
              onClick: setting.onClick
            }
          "
        ></ng-container>
      </ng-container>
    </div>
    <div>
      <span (click)="toggle()" class="text-primary show-hide"
        ><ng-container i18n>Ukryj aktywne ustawienia</ng-container>
        <mat-icon>expand_less</mat-icon></span
      >
    </div>
  </ng-container>
  <ng-template #collapsed>
    <span (click)="toggle()" class="text-primary show-hide"
      ><ng-container i18n>Pokaż aktywne ustawienia</ng-container>
      <mat-icon>expand_more</mat-icon></span
    >
  </ng-template>
</div>

<ng-template #pill let-setting let-enabled="enabled" let-onClick="onClick">
  <div
    class="pill"
    [class.clickable]="onClick"
    (click)="onClick ? onClick() : undefined"
  >
    {{ setting }} - <strong i18n *ngIf="enabled === true">wł.</strong
    ><strong i18n *ngIf="enabled === false">wył.</strong>
  </div>
</ng-template>
