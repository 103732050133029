import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorEvent } from 'ngx-color';
import { ColorService } from '../../../services/color.service';

@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.less']
})
export class ColorComponent {
  public opened: boolean;

  @Input() public color: string;
  @Input() public mode: string = 'default';
  @Output() emitColor = new EventEmitter<string>();

  protected showColors = false;

  constructor(protected colors: ColorService) {
    this.color = colors.cssColor;
    this.opened = colors.opened.value;
  }

  onChange(event: ColorEvent) {
    this.color = event.color.hex;
    this.colors.setFromCss(event.color.hex);
    this.showColors = false;
    this.emitColor.emit(this.color);
  }

  public getValue(): number {
    console.log('color value', this.color);
    return this.colors.getHexValue(this.color + '');
  }
}
