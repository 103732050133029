import { v4 as uuidv4 } from 'uuid';

export class Project {
  uuid: string;
  directoryUuid?: string;
  userId: string;
  name?: string;
  seqNumber?: string;
  comment?: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(obj: Partial<Project>) {
    Object.assign(this, obj);
    if (this.uuid === undefined) {
      this.uuid = uuidv4();
    }
    if (this.createdAt) {
      this.createdAt = new Date(this.createdAt);
    }
    if (this.updatedAt) {
      this.updatedAt = new Date(this.updatedAt);
    }
  }

  toString() {
    return this.name ?? this.seqNumber ?? '';
  }
}
