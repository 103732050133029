<div class="text-right with-img">
  <img
    src="assets/images/vertical-rotation.png"
    alt="Obracaj dowolnie"
    i18n-alt
  />
</div>
<div class="help-main">
  <h2 class="primary" i18n>Obracaj dowolnie</h2>
  <p i18n>
    Ładunek może zostać obrócony w dowolnym kierunku lub położony na bok / tył,
    jeśli pozwoli to na lepsze rozmieszczenie.
  </p>
  <p i18n>
    Możesz użyć tej opcji, jeśli ładunek nie wymaga transportu w konkretnej
    pozycji stojącej / leżącej.
  </p>
</div>
