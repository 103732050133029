import { GridSettings } from '../grid-settings/grid-settings';
//to powinny byc ustawienia kalkulacji a nie pojazdu
export class Settings {
  preserveOrder = false;
  randomizeColors = false;
  fullLevels = false;
  grid?: GridSettings;
  constructor(obj?: any) {
    Object.assign(this, obj);
    this.grid = new GridSettings(obj?.grid ?? {});
  }
}
