import {
  trigger,
  state,
  style,
  transition,
  animate,
  animateChild,
  query,
  keyframes
} from '@angular/animations';

export const onMainContentChange = trigger('onMainContentChange', [
  state(
    'close',
    style({
      marginTop: '-50px'
    })
  ),
  state(
    'open',
    style({
      marginTop: '0px'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in'))
]);

export const animateText = trigger('animateText', [
  state(
    'hide',
    style({
      display: 'none',
      opacity: 0
    })
  ),
  state(
    'show',
    style({
      display: 'block',
      opacity: 1
    })
  ),
  transition('close => open', animate('350ms ease-in')),
  transition('open => close', animate('200ms ease-out'))
]);

export const flashBackground = trigger('flashBackground', [
  transition(
    '* => show',
    animate(
      '1000ms ease-in-out',
      keyframes([
        style({ 'background-color': 'rgba(var(--clr-primary-rgb), 0.4)' }),
        style({ 'background-color': 'transparent' })
      ])
    )
  )
]);

export const enterLeaveAnimation = trigger('enterLeaveAnimation', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)', opacity: 0 }),
    animate('250ms', style({ transform: 'translateY(0%)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ transform: 'translateY(0%)', opacity: 1 }),
    animate('250ms', style({ transform: 'translateY(-100%)', opacity: 0 }))
  ])
]);
