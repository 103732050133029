import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { UiService } from 'src/app/services/ui.service';
import { Axle } from '../../lib/axle';

export class AxleForm extends UntypedFormGroup {
  readonly offset = this.get('offset') as UntypedFormControl;

  constructor(
    readonly axle: Axle,
    readonly maxOffset: number,
    private uiService: UiService,
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder()
  ) {
    super(
      fb.group({
        offset: [
          axle.offset ? uiService.getLengthInCurrentUnit(axle.offset) : '',
          [
            Validators.required,
            Validators.min(0),
            Validators.max(uiService.getLengthInCurrentUnit(maxOffset))
          ]
        ]
      }).controls
    );
    this.offset.valueChanges.subscribe((val) => {
      axle.offset = this.uiService.getLengthInDefaultUnit(val);
    });
  }
}
