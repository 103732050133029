import { LoadDimensionLabel } from './LoadDimensionLabel';
import { Constants as Config } from 'src/app/config/constants';

export class LoadWidthLabel extends LoadDimensionLabel {
  protected updateLabelPosition(): void {
    const position = this.load.mesh.position.clone();
    position.x -=
      (this.load.cuboidHull.length / 2 - 20) * Config.DIMENSION_SCALING;
    position.y -= (this.load.cuboidHull.height / 2) * Config.DIMENSION_SCALING;
    this.setPosition(position);
  }
}
