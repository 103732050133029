import {
  BoxGeometry,
  Color,
  DoubleSide,
  Mesh,
  MeshLambertMaterial,
  MeshLambertMaterialParameters
} from 'three';
import { Vector } from '../../../lib/communication/vector';
import { v4 as uuidv4 } from 'uuid';
import { Constants as Config } from 'src/app/config/constants';

export class VectorMesh {
  public readonly length: number;
  public readonly width: number;
  public readonly height: number;

  public readonly mesh: Mesh;
  public readonly uuid: string;

  private readonly normalOpacity = 0.1;
  private readonly hoverOpacity = 0.7;

  constructor(
    private vector: Vector,
    options?: Partial<MeshLambertMaterialParameters>
  ) {
    this.uuid = uuidv4();
    this.vector = vector;
    this.length = this.vector.xLength;
    this.width = this.vector.zLength;
    this.height = this.vector.yLength || 75;

    this.mesh = new Mesh();
    this.mesh.userData['uuid'] = this.uuid;
    this.mesh.position.x =
      (vector.x + vector.xLength / 2) * Config.DIMENSION_SCALING;
    this.mesh.position.y =
      (vector.y + vector.yLength / 2) * Config.DIMENSION_SCALING;
    this.mesh.position.z =
      (vector.z + vector.zLength / 2) * Config.DIMENSION_SCALING;

    this.mesh.name = 'vector-mesh';
    this.mesh.material = new MeshLambertMaterial({
      transparent: true,
      opacity: this.normalOpacity,
      side: DoubleSide,
      depthWrite: false,
      ...(options || {})
    });
    this.mesh.visible = false;
    this.init();
  }

  protected init() {
    this.mesh.geometry = new BoxGeometry(
      this.length * Config.DIMENSION_SCALING,
      this.height * Config.DIMENSION_SCALING,
      this.width * Config.DIMENSION_SCALING
    );

    /*
    const mat = new LineBasicMaterial({ color: 0xff0000 });
    const geo = new EdgesGeometry(this.mesh.geometry, 1);
    const wireframe = new LineSegments(geo, mat);
    this.mesh.add(wireframe);
    */
  }

  public hover() {
    //(this.mesh.material as MeshLambertMaterial).color = new Color(0xfe9800);
    (this.mesh.material as MeshLambertMaterial).opacity = this.hoverOpacity;
  }

  public unhover() {
    (this.mesh.material as MeshLambertMaterial).opacity = this.normalOpacity;
  }

  public show() {
    this.mesh.visible = true;
  }

  public hide() {
    this.mesh.visible = false;
  }
}
