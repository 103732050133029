import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './form.component';
import { CommonModule as VehicleCommonModule } from '../common/common.module';
import { CreateComponent } from './create/create.component';
import { SelectListComponent } from './select-list/select-list.component';
import { NameComponent } from './name/name.component';
import { ExpertComponent } from './expert/expert.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { TypeComponent } from './type/type.component';
import { SpaceModule } from '../space/space.module';
import { SettingsComponent } from './settings/settings.component';
import { HelperModule } from 'src/app/lib/helper/helper.module';
import { GridSettingsComponent } from './grid-settings/grid-settings.component';

@NgModule({
  declarations: [
    FormComponent,
    CreateComponent,
    SelectListComponent,
    NameComponent,
    ExpertComponent,
    TypeComponent,
    SettingsComponent,
    GridSettingsComponent
  ],
  imports: [
    CommonModule,
    HelperModule,
    VehicleCommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SpaceModule
  ],
  exports: [FormComponent, SettingsComponent, NameComponent]
})
export class FormModule {}
