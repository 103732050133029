import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { Vehicle } from './lib/vehicle';
import { VehicleType } from './lib/vehicle-type';
import { VehicleService as VehicleUiService } from './vehicle.service';
import { VehicleService as VehicleApi } from './lib/vehicle.service';
import { Other } from './type/other/lib/other';
import { VehicleFactory } from './lib/vehicle-factory';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.less']
})
export class VehicleComponent implements OnDestroy {
  eventsSubject: Subject<void> = new Subject<void>();
  public type: VehicleType;
  public types: Array<VehicleType>;
  public saveForm: UntypedFormGroup;
  public fb: UntypedFormBuilder = new UntypedFormBuilder();

  public listType = 'none'; // system | user | none - lista typowe lub własne lub widok wyboru
  // userFormType = 'new'; // new | history - formularz Zdefiniuj nowy | lista Moje poprzednie

  private unsubscribe$ = new Subject<void>();
  constructor(
    private api: VehicleApi,
    private vehicleUiService: VehicleUiService,
    private vehicleFactory: VehicleFactory
  ) {
    this.saveForm = this.fb.group({
      name: [null, Validators.required]
    });
    this.types = this.api.getVehicleTypes();

    this.type = this.types.find((x) => x.type === 'box');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public selectedType(type: VehicleType) {
    // console.log('selectedType in space.component.ts', type);
    this.type = type;
  }

  //addLoad to calculation
  addVehicle(vehicle: Vehicle) {
    if (vehicle instanceof Other && vehicle.baseSizeChanged()) {
      vehicle.model = '';
      vehicle = this.vehicleFactory.recreate(vehicle);
    }
    this.vehicleUiService.addToScene(vehicle);
  }
}
