<div class="mb-25 text-left">
  <label><ng-container i18n>Oś</ng-container> #{{ form.axle.order + 1 }}</label>
</div>
<div class="row">
  <div [formGroup]="form">
    <mat-form-field>
      <input
        type="number"
        matInput
        appOnlyNumber
        i18n-placeholder
        placeholder="Odległość od przodu ({{ ui.getLengthUnit() | async }})"
        formControlName="offset"
        autocomplete="off"
        min="0"
        max="{{ ui.getLengthInCurrentUnit(form.maxOffset) }}"
      />
      <mat-error *ngIf="form.controls['offset'].hasError('required')">
        <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
        <strong><ng-container i18n>wymagane</ng-container></strong>
      </mat-error>
      <mat-error *ngIf="form.controls['offset'].hasError('max')">
        <ng-container i18n>Maksymalna odległość to</ng-container>
        <strong
          >{{ ui.getLengthInCurrentUnit(form.maxOffset) }}
          {{ ui.getLengthUnit() | async }}</strong
        >
      </mat-error>
      <mat-error *ngIf="form.controls['offset'].hasError('min')">
        <ng-container i18n>Minimalna odległość to</ng-container>
        <strong>0 {{ ui.getLengthUnit() | async }}</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <button
    mat-icon-button
    color="basic"
    (click)="delete()"
    i18n-title
    i18n-aria-label
    aria-label="Usuń oś"
    title="Usuń oś"
  >
    <mat-icon class="action text-primary">delete</mat-icon>
  </button>
</div>
