import { CuboidHull } from 'src/app/load/lib/cuboid-hull';
import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { Load } from '../../../lib/load';
import { LShapeMesh } from './l-shape-mesh';

export class LShape extends Load {
  shape = 'l-shape';
  a: number;
  b: number;
  c: number;
  d: number;
  height: number;

  get cuboidHull(): CuboidHull {
    return new CuboidHull({
      width: this.a,
      length: this.b,
      height: this.height
    });
  }

  get volume(): number {
    return this.area * this.height;
  }
  get area(): number {
    return this.c * this.b + (this.a - this.c) * this.d;
  }

  get fullName(): string {
    return `L ${this.name}`;
  }
  get fullDescription(): string {
    return `L ${this.a}x${this.b}x${this.c}x${this.d}`;
  }

  get descriptiveDimensions(): number[] {
    return [this.a, this.b, this.c, this.d];
  }

  createMesh(settings: LoadDisplaySettings): LoadMesh {
    this.mesh = new LShapeMesh(this, settings);
    return this.mesh;
  }
}
