import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UiService } from 'src/app/services/ui.service';
import { Vehicle } from '../../../lib/vehicle';
import { Space } from '../../../space/lib/space';
import { Axle } from '../../lib/axle';
import { AxleForm } from './axle.form';

export class SpaceAxlesForm extends UntypedFormGroup {
  get Axles(): AxleForm[] {
    return (this.controls.axles as UntypedFormArray).controls as AxleForm[];
  }

  constructor(
    readonly space: Space | Vehicle,
    private uiService: UiService,
    private readonly fb: UntypedFormBuilder = new UntypedFormBuilder()
  ) {
    super(
      fb.group({
        axles: fb.array(
          space.axles.map(
            (axle) => new AxleForm(axle, space.maxLength, uiService, fb)
          )
        )
      }).controls
    );
  }

  addAxle(axle: Axle) {
    this.Axles.push(
      new AxleForm(axle, this.space.maxLength, this.uiService, this.fb)
    );
    this.reorderAxles();
  }

  removeAxleForm(axle: AxleForm) {
    (this.controls.axles as UntypedFormArray).removeAt(
      ((this.controls.axles as UntypedFormArray).controls as AxleForm[]).findIndex(
        (form) => form === axle
      )
    );
    this.reorderAxles();
  }

  get valid() {
    const invalid = this.Axles.filter((form) => !form.valid);
    return super.valid && invalid.length === 0;
  }

  private reorderAxles() {
    this.Axles.forEach((form, index) => {
      form.axle.order = index;
    });
  }
}
