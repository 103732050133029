import { NgModule } from '@angular/core';
import { CommonModule as AngularCommon } from '@angular/common';
import { LengthUnitPipe } from 'src/app/directives/length-unit.pipe';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { WeightUnitPipe } from 'src/app/directives/weight-unit.pipe';

@NgModule({
  declarations: [],
  imports: [AngularCommon, DirectivesModule],
  exports: [LengthUnitPipe, WeightUnitPipe]
})
export class CommonModule {}
