import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UiService } from 'src/app/services/ui.service';
import { Space } from 'src/app/vehicle/space/lib/space';
import { FlooringLevelSettings } from '../../../../vehicle/space/flooring-level/lib/flooring-level-settings';

export interface SpaceFlooringLevelsFormInterface {
  maxHeightEnabled: FormControl<boolean>;
  maxRowsCntEnabled: FormControl<boolean>;
  height: FormControl<number>;
  rowsCnt: FormControl<number>;
}

export class SpaceFlooringLevelsForm extends FormGroup<SpaceFlooringLevelsFormInterface> {
  public readonly spaceUuid: string;
  private model = new FlooringLevelSettings();
  public constructor(space: Space, private uiService: UiService) {
    super({
      maxHeightEnabled: new FormControl(false, Validators.compose([])),
      maxRowsCntEnabled: new FormControl(false, Validators.compose([])),
      height: new FormControl<number>(
        null,
        Validators.compose([
          Validators.min(0),
          Validators.max(uiService.getLengthInCurrentUnit(space.height))
        ])
      ),
      rowsCnt: new FormControl<number>(
        null,
        Validators.compose([Validators.min(0)])
      )
    });
    this.setValues(space.settings);
    this.spaceUuid = space.uuid;
    this.controls.height.valueChanges.subscribe((val) => {
      if (val > 0) {
        this.controls.maxHeightEnabled.setValue(true);
      }
    });
    this.controls.rowsCnt.valueChanges.subscribe((val) => {
      if (val > 0) {
        this.controls.maxRowsCntEnabled.setValue(true);
      }
    });
  }

  public setValues(settings: FlooringLevelSettings) {
    this.model = new FlooringLevelSettings(settings);
    this.controls.maxHeightEnabled.setValue(settings.maxHeightEnabled);
    this.controls.maxRowsCntEnabled.setValue(settings.maxRowsCntEnabled);
    this.controls.height.setValue(
      settings.height
        ? this.uiService.getLengthInCurrentUnit(settings.height)
        : null
    );
    this.controls.rowsCnt.setValue(settings.rowsCnt ?? null);
  }

  get data(): FlooringLevelSettings {
    this.model.maxHeightEnabled = this.controls.maxHeightEnabled.value;
    this.model.maxRowsCntEnabled = this.controls.maxRowsCntEnabled.value;
    this.model.height = this.controls.height.value
      ? this.uiService.getLengthInDefaultUnit(this.controls.height.value)
      : undefined;
    this.model.rowsCnt = this.controls.rowsCnt
      ? +this.controls.rowsCnt.value
      : undefined;
    return this.model;
  }
}
