import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, map, Observable, startWith } from 'rxjs';

import { UiService } from 'src/app/services/ui.service';
import { Vehicle } from '../../lib/vehicle';

@Pipe({
  name: 'vehicleReadableSize'
})
export class VehicleReadableSizePipe implements PipeTransform {
  private weightUnit: Observable<string>;
  private lengthUnit: Observable<string>;

  constructor(private ui: UiService, private decimalPipe: DecimalPipe) {
    this.weightUnit = ui
      .getWeightUnit()
      .pipe(startWith(ui.getCurrentWeightUnit()));
    this.lengthUnit = ui
      .getLengthUnit()
      .pipe(startWith(ui.getCurrentLengthUnit()));
  }

  transform(vehicle: Vehicle): Observable<string> {
    return combineLatest([this.weightUnit, this.lengthUnit]).pipe(
      map(([weightUnit, lengthUnit]) => {
        return vehicle.spacesForSizeDisplay
          .map((space) => {
            let result = [space.length, space.width, space.height]
              .map((d) =>
                this.decimalPipe.transform(
                  this.ui.convertLengthToUnit(d, lengthUnit),
                  '0.0-2'
                )
              )
              .join('x');
            result += ' ' + lengthUnit;
            if (space.maxLoadingWeight) {
              result += ' | ';
              result += this.decimalPipe.transform(
                this.ui.convertWeightToUnit(space.maxLoadingWeight, weightUnit),
                '0.0-2'
              );
              result += ' ' + weightUnit;
            }
            return result;
          })
          .join(' ');
      })
    );
  }
}
