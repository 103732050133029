import { Directory } from './directory';
import { DirectoryListItem } from './directory-list-item';
import { Project } from './project';

export class ProjectList {
  public directories: DirectoryListItem[];
  constructor(
    private directoryEntries: Directory[],
    public projects: Project[],
    expandedDirectoryUuids: string[] = []
  ) {
    this.directories = directoryEntries.map(
      (entry) =>
        new DirectoryListItem(
          entry,
          expandedDirectoryUuids.includes(entry.uuid)
        )
    );
  }

  public updateDirectoryList(entries: Directory[]) {
    const oldExpanded = this.getExpandedDirectoryUuids();
    const directories = entries.map(
      (entry) => new DirectoryListItem(entry, oldExpanded.includes(entry.uuid))
    );
    this.directories = directories;
  }

  public updateProjectList(entries: Project[]) {
    this.projects = entries;
  }

  public getExpandedDirectoryUuids(): string[] {
    return this.directories
      .filter((d) => d.expanded)
      .map((d) => d.directory.uuid);
  }

  public isEmpty() {
    return this.directories.length + this.projects.length === 0;
  }
}
