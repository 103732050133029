import { Load } from '../../load/lib/load';
import { LoadFactory } from '../../load/lib/load-factory';
import { LoadEventObject } from '../../load/lib/load-event-object';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { PositionerRequest } from '../../lib/communication/positioner.request';
import { Position } from '../../lib/positioner/position';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';

export class AddLoadsRequest extends PositionerRequest {
  constructor(
    loadBundle: LoadEventObject[],
    context: VehicleContext,
    loadFactory: LoadFactory
  ) {
    super();

    let maxIdx = Math.max(0, ...context.getAllLoads().map((load) => load.idx));

    //console.log('maxIdx', maxIdx);
    //console.log('context.getLoads()', context.getLoads());

    Object.assign(this, {
      historyUuid: context.getHistoryUuid(),
      uuid: context.getUuid(),
      initialTimestamp: context.getInitialTimestamp(),
      message: 'test',
      //loads jako loads ktore sa outside
      loads: context.getLoads(),
      // existingLoads: context.getExistingLoads(), //should not be used
      // teraz dodawane loads
      addedLoads: this.loadsFromBundle(loadBundle, maxIdx, loadFactory, context),
      /*loads: context
        .getExistingLoads()
        .concat(this.loadsFromBundle(loadBundle, maxIdx)),*/
      vehicle: context.getVehicle(),
      matrix: context.getMatrix(),
      orbitControlsState: context.getOrbitControlsState(),
      projectId: context.getProjectId(),
      settings: context.getSettings()
    });
    if (loadBundle.length === 1 && loadBundle[0].cnt === -1) {
      this.fillSpace = true;
    }
  }

  private loadsFromBundle(
    loadBundle: LoadEventObject[],
    prevIdxSeqEnd: number,
    loadFactory: LoadFactory,
    context: VehicleContext
  ) {
    const position = new Position();
    const loads: Load[] = [];
    let timestamp = +new Date();
    loadBundle.forEach((bundle) => {
      let cnt = bundle.cnt;
      if (cnt === -1) {
        cnt = this.calculateMaxLoadCountToFillSpace(bundle.load, context.isVehicleChosen() ? context.getVehicle() : null); // fillSpace
      }
      for (let i = 0; i < cnt; i++) {
        const load = loadFactory.recreateLoad({
          ...bundle.load,
          idx: ++prevIdxSeqEnd
        });
        load.generateUuid();
        load.position = position;
        load.initialTimestamp = timestamp++;
        load.mesh = null;
        loads.push(load);
      }
    });

    return loads;
  }

  private calculateMaxLoadCountToFillSpace(load: Load, vehicle: Vehicle) {
    let cnt = 3000;
    if (!vehicle) {
      return cnt;
    }
    if (load.floorableAll) {
      const maxCap = vehicle.volume;
      const loadCap = load.volume;
      cnt = Math.floor(maxCap / loadCap);
    } else {
      const maxCap = vehicle.area;
      const loadCap = load.area;
      cnt = Math.floor(maxCap / loadCap);
    }
    return cnt;
  }
}
