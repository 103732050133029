<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'plain-form'" [ngTemplateOutlet]="simple">
  </ng-container>
  <ng-container *ngSwitchCase="'outline'" [ngTemplateOutlet]="outline">
  </ng-container>
  <ng-container *ngSwitchDefault>
    ng-template *ngSwitchDefault does not exist
  </ng-container>
</ng-container>

<ng-template #outline>
  <div [formGroup]="form">
    <label i18n>Ładowność przestrzeni</label>
    <mat-form-field appearance="outline" class="weight">
      <input
        #weight
        matInput
        appOnlyNumber
        formControlName="weight"
        autocomplete="no"
        name="weight"
      />
      <mat-error *ngIf="form.controls['weight'].hasError('required')">
        <ng-container i18n>Podaj</ng-container><span>&nbsp;</span>
        <strong><ng-container i18n>ładowność</ng-container></strong>
      </mat-error>
      <span class="help">{{ ui.getWeightUnit() | async }}</span>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #simple>
  <div [formGroup]="form">
    <mat-form-field>
      <input
        matInput
        appOnlyNumber
        i18n-placeholder
        placeholder="Ładowność ({{ ui.getWeightUnit() | async }})"
        formControlName="weight"
        autocomplete="off"
      />
      <mat-error *ngIf="form.controls['weight'].hasError('required')">
        <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
        <strong><ng-container i18n>wymagane</ng-container></strong>
      </mat-error>
    </mat-form-field>
  </div>
</ng-template>
