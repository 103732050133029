import { Component, OnInit, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { UiMode, UiService } from '../services/ui.service';
import { User } from '../lib/model/user';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HeaderService } from './header.service';
import { ProjectsService } from '../projects/projects.service';
import { Project } from '../projects/lib/project';
import { getLocaleId } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, OnDestroy {
  uiMode$: Observable<UiMode>;
  user: Observable<User>;
  currentDate: Date;
  project$: Observable<Project>;
  protected currentLanguage = 'PL';
  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(LOCALE_ID) protected locale: string,
    private service: HeaderService,
    private uiService: UiService,
    private auth: AuthService,
    projectsService: ProjectsService
  ) {
    this.project$ = projectsService.currentProject$;
    this.uiMode$ = uiService.getUiMode();
    this.currentLanguage = (locale || 'pl').toUpperCase();
  }

  ngOnInit(): void {
    this.user = this.auth.getUser();
    this.currentDate = new Date();
    setInterval(() => {
      this.currentDate = new Date();
    }, 60000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  logout() {
    this.auth.logout();
  }

  myAccount() {
    window.location.assign(environment.profileUrl);
  }

  myGarage() {
    // console.log('mygarage clicked');
    this.service.showMyGarage();
  }

  settings() {
    // console.log('settings clicked');
    this.service.showSettings();
  }

  changeLanguage(lang: string) {
    const newUrl =
      window.location.protocol + '//' + window.location.host + '/' + lang + '/';
    window.location.replace(newUrl);
  }
}
