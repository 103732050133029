//ustawienia przestrzeni ładunkowej space w obrębie pojazud vehicle
export class FlooringLevelSettings {
  maxHeightEnabled = false;
  maxRowsCntEnabled = false;
  height: number;
  rowsCnt: number;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
