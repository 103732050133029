<div>
  <div [ngSwitch]="space.type">
    <ng-container *ngSwitchCase="'box'">
      <app-box-form
        [events]="events"
        [item]="space"
        (addSpaceEvent)="addSpace($event)"
      ></app-box-form
    ></ng-container>
    <ng-container *ngSwitchCase="'container'">
      <app-container-form
        [events]="events"
        [item]="space"
        (addSpaceEvent)="addSpace($event)"
      ></app-container-form
    ></ng-container>
    <ng-container *ngSwitchCase="'trailer'">
      <app-trailer-form
        [events]="events"
        [item]="space"
        (addSpaceEvent)="addSpace($event)"
      ></app-trailer-form
    ></ng-container>
    <ng-container *ngSwitchDefault>...{{ space.type }}</ng-container>
  </div>
</div>
