import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from '../../../environments/environment';
import { Vehicle } from './vehicle';
import { VehicleFactory } from './vehicle-factory';
import { VehicleService } from './vehicle.service';
import { UiService } from 'src/app/services/ui.service';

@Injectable({
  providedIn: 'root'
})
export class CustomVehicleService extends VehicleService {
  private baseUrl = environment.apiUrl + '/database/profile/custom-vehicle';
  private projectId: string;
  constructor(
    protected http: HttpClient,
    protected vehicleFactory: VehicleFactory,
    protected profileService: ProfileService,
    protected uiService: UiService
  ) {
    super(http, vehicleFactory, profileService, uiService);
    this.url = this.baseUrl;
    this.listUrl = this.url + '/list';
    this.sortByProp = 'createdAt';
  }

  public updateCurrentProject(uuid: string) {
    this.projectId = uuid;
    this.listUrl = this.url + '/list/' + (uuid ?? '');
    if (uuid) {
      this.refresh();
    }
  }

  public create(item: Vehicle): Observable<void> {
    item.projectId = this.projectId;
    return this.http
      .post<string>(this.url, item)
      .pipe(map(() => this.update()));
  }

  public delete(item: Vehicle): Observable<void> {
    // console.log('custom-load.service.ts in cuboid', item);
    const uuid = item.uuid;
    return this.http
      .delete<Vehicle>(this.url + '/' + uuid)
      .pipe(map(() => this.update()));
  }
}
