import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type
} from '@angular/core';
import { Subject } from 'rxjs';
import { VehicleContext } from '../../lib/model/vehicle-context';
import { FreeSpaceService } from '../../vehicle/space/lib/free-space.service';
import { MyScene } from '../lib/MyScene';
import { SceneService } from '../scene.service';

type Tools = {
  [key in 'measurement']: boolean;
};

@Component({
  selector: 'app-scene-tools',
  templateUrl: './scene-tools.component.html',
  styleUrls: ['./scene-tools.component.less']
})
export class SceneToolsComponent implements OnInit, OnDestroy {
  @Input() context: VehicleContext;

  protected tools: Tools = {
    measurement: false
  };

  private unsubscribe$ = new Subject<void>();
  constructor(
    private sceneService: SceneService,
    private freeSpaceService: FreeSpaceService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected isNotEmptyContext() {
    return (
      this.context &&
      this.context.getVehicle() &&
      this.context.getVehicle().type !== 'empty'
    );
  }

  protected toggleMeasurementTool() {
    if (this.tools.measurement === false) {
      this.enableOnly('measurement');
      this.freeSpaceService.enableMeasurement();
    } else {
      this.tools.measurement = false;
      this.freeSpaceService.disableMeasurement();
    }
    this.sceneService.redrawContextWithLabels();
  }

  private enableOnly(tool: keyof Tools) {
    for (const t in this.tools) {
      this.tools[t] = tool === t;
    }
  }
}
