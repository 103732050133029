import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { LoadComponentService } from '../../services/load-component.service';
import { Project } from '../lib/project';
import { ProjectsService } from '../projects.service';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil
} from 'rxjs';
import { Directory } from '../lib/directory';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.less']
})
export class ProjectCreateComponent implements OnInit, OnDestroy {
  private _project: Project;
  @Input() set project(project: Project) {
    this._project = project;
    this.form.get('name').setValue(project?.name || '');
  }
  get project() {
    return this._project;
  }
  public form: UntypedFormGroup;

  protected filteredDirectories$: Observable<Directory[]>;
  protected currentDirectory: Directory;

  private unsubscribe$ = new Subject<void>();
  constructor(
    private componentService: LoadComponentService,
    private projectsService: ProjectsService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.projectsService
      .fetchAllDirectories()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((list) => {
        if (this.project?.directoryUuid) {
          this.currentDirectory = list.find(
            (d) => d.uuid === this.project?.directoryUuid
          );
          console.log('current dir ', this.currentDirectory);
        }
      });
    this.createForm();
  }

  ngOnInit(): void {
    this.filteredDirectories$ = this.form.controls.directory.valueChanges.pipe(
      startWith(''),
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((value) => this.searchDirectory(value || ''))
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close(): void {
    this.componentService.clear('ProjectCreateComponent');
  }

  protected searchDirectory(query: string | Directory) {
    const name = typeof query === 'string' ? query : query?.name;
    return this.projectsService.allDirectories$.pipe(
      map((list) =>
        name
          ? list.filter((dir) =>
              dir.name.toLowerCase().includes((name + '').toLowerCase())
            )
          : list
      )
    );
  }

  protected displayDirectory(dir: Directory): string {
    console.log('displayDirectory', dir);
    return dir && dir.name ? dir.name : '';
  }

  onSubmit() {
    const name = this.form.controls.name.value;
    const directory = this.form.controls.directory.value;
    const directoryUuid =
      directory && directory instanceof Directory ? directory.uuid : null;
    const project =
      this.project ||
      new Project({
        name,
        directoryUuid
      });
    if (this.project) {
      this.project.name = name;
      this.project.directoryUuid = directoryUuid;
      this.updateProject(project);
    } else {
      this.createProject(project);
    }
  }

  private updateProject(project: Project) {
    this.projectsService
      .updateProject(project, false)
      .pipe(
        switchMap(() => this.projectsService.fetchProjects()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((list) => {
        this.projectsService.updateProjectList(list);
        this.projectsService.clearNamesCache();
        this.close();
      });
  }

  private createProject(project: Project) {
    this.projectsService
      .addProject(project)
      .pipe(
        switchMap(() => this.projectsService.fetchProjects()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((list) => {
        this.projectsService.updateProjectList(list);
        this.projectsService.clearNamesCache();
        this.close();
      });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.compose([])],
      directory: [null, Validators.compose([])]
    });
  }
}
