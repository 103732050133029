import { FormArray, FormGroup, UntypedFormArray } from '@angular/forms';
import { UiService } from 'src/app/services/ui.service';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { Space } from 'src/app/vehicle/space/lib/space';
import { SpaceFlooringLevelsForm } from './space-flooring-levels.form';

interface FlooringLevelsFormInterface {
  spaces: FormArray<SpaceFlooringLevelsForm>;
}

export class FlooringLevelsForm extends FormGroup<FlooringLevelsFormInterface> {
  get Spaces(): SpaceFlooringLevelsForm[] {
    return (this.controls.spaces as UntypedFormArray)
      .controls as SpaceFlooringLevelsForm[];
  }
  public constructor(vehicle: Vehicle, uiService: UiService) {
    super({
      spaces: new FormArray<SpaceFlooringLevelsForm>(
        vehicle.enabledSpaces.map(
          (space) => new SpaceFlooringLevelsForm(space, uiService)
        )
      )
    });
  }

  public findForm(space: Space): SpaceFlooringLevelsForm {
    return this.Spaces.find((f) => f.spaceUuid === space.uuid);
  }

  get valid() {
    const invalidSpaces = this.Spaces.filter((form) => !form.valid);
    return super.valid && invalidSpaces.length === 0;
  }

  markAllAsTouched() {
    super.markAllAsTouched();
    this.Spaces.forEach((form) => form.markAllAsTouched);
  }
}
