import { KeycloakService } from 'keycloak-angular';
import { environment } from './../../environments/environment';

export const initializeKeycloak = (keycloak: KeycloakService) => () =>
  keycloak.init({
    config: {
      url: environment.authUrl + '/auth',
      realm: environment.authRealm,
      clientId: environment.authClient
    },
    initOptions: {
      onLoad: 'check-sso',
      //onLoad: 'login-required',
      silentCheckSsoRedirectUri:
        window.location.origin + '/assets/silent-check-sso.html'
    }
  });
