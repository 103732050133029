import {
  EdgesGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineSegments,
  Vector3
} from 'three';
import { Constants } from 'src/app/vehicle/lib/constants';
import { OtherMesh } from '../other-mesh';
import { Other } from '../other';
import { Vector } from 'src/app/lib/communication/vector';

class res {
  indices: number;
  vertices: number;
}
export class EPAL2Mesh extends OtherMesh {
  public constructor(other: Other) {
    super(other);
    console.log('EuroPaletteMesh 1 constructor called', other);
    this.mesh.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.DARK_BROWN_WOOD_MATERIAL,
      Constants.LIGHT_BROWN_WOOD_MATERIAL
    ];
  }

  override getBufferGeometry() {
    const x0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const z1 = z0 + this.width;
    const xc = (x1 - x0) / 2;
    const zc = (z1 - z0) / 2;
    const plankW = this.scale(100);
    const plankH = this.scale(22);
    const plankL = this.width;
    const boxH = this.scale(96);

    let currentY = 0;
    const r = this.scale(17);

    //bottom planks
    const allRounded = [r, r, r, r] as const;
    const leftExternalRadiuses = [r, 0, 0, r] as const;
    const rightExternalRadiuses = [0, r, r, 0] as const;
    const backExternalRadiuses = [r, r, 0, 0] as const;
    const frontExternalRadiuses = [0, 0, r, r] as const;

    //bottom planks
    this.plank(
      new Vector3(x0, currentY, z0),
      plankW,
      plankL,
      plankH,
      1,
      ...leftExternalRadiuses
    );
    this.plank(
      new Vector3(x1 - plankW, currentY, z0),
      plankW,
      plankL,
      plankH,
      1,
      ...rightExternalRadiuses
    );
    const bottomCenterPlanks = [
      new Vector3(x0 + plankW, currentY, z0),
      new Vector3(x0 + plankW, currentY, zc - plankW / 2),
      new Vector3(x0 + plankW, currentY, z1 - plankW)
    ];
    bottomCenterPlanks.map((p) => this.plank(p, plankL, plankW, plankH, 1));

    //boxes
    currentY += plankH;
    const cornerBoxes = [
      new Vector3(x0, currentY, z0),
      new Vector3(x0, currentY, z1 - this.scale(90)),
      new Vector3(x1 - this.scale(160), currentY, z0),
      new Vector3(x1 - this.scale(160), currentY, z1 - this.scale(90))
    ];
    cornerBoxes.forEach((p) => {
      this.plank(p, this.scale(160), this.scale(90), boxH, 2, ...allRounded);
    });
    this.plank(
      new Vector3(x0, currentY, zc - this.scale(90) / 2),
      this.scale(160),
      this.scale(90),
      boxH,
      2
    );
    this.plank(
      new Vector3(x1 - this.scale(160), currentY, zc - this.scale(90) / 2),
      this.scale(160),
      this.scale(90),
      boxH,
      2
    );
    const centerBoxes = [
      new Vector3(xc - this.scale(110) / 2, currentY, z0),
      new Vector3(xc - this.scale(110) / 2, currentY, zc - this.scale(90) / 2),
      new Vector3(xc - this.scale(110) / 2, currentY, z1 - this.scale(90))
    ];
    centerBoxes.forEach((p) => {
      this.plank(p, this.scale(110), this.scale(90), boxH, 2);
    });

    //beforeTopPlanks
    currentY += boxH;
    this.plank(
      new Vector3(x0, currentY, z0),
      this.length,
      plankW,
      plankH,
      1,
      ...backExternalRadiuses
    );
    this.plank(
      new Vector3(x0, currentY, zc - plankW / 2),
      this.length,
      plankW,
      plankH,
      1
    );

    this.plank(
      new Vector3(x0, currentY, z1 - plankW),
      this.length,
      plankW,
      plankH,
      1,
      ...frontExternalRadiuses
    );

    //topPlanks
    currentY += plankH;
    this.plank(
      new Vector3(x0, currentY, -z0),
      this.scale(120),
      plankL,
      plankH,
      2,
      ...leftExternalRadiuses
    ); // left
    this.plank(
      new Vector3(x1 - this.scale(120), currentY, -z0),
      this.scale(120),
      plankL,
      plankH,
      2,
      ...rightExternalRadiuses
    ); // right
    this.plank(
      new Vector3(x0 + this.scale(120), currentY, z0),
      this.scale(127),
      plankL,
      plankH,
      2
    ); // after left
    this.plank(
      new Vector3(x1 - this.scale(120) - this.scale(127), currentY, z0),
      this.scale(127),
      plankL,
      plankH,
      2
    ); /// before right
    const centerPlanks = [
      new Vector3(
        x0 + this.scale(120) + this.scale(127) + this.scale(16),
        currentY,
        z0
      ),
      new Vector3(
        x0 +
          this.scale(120) +
          this.scale(127) +
          this.scale(16) +
          this.scale(120) +
          this.scale(18),
        currentY,
        z0
      ),
      new Vector3(xc - this.scale(120) / 2, currentY, z0),
      new Vector3(
        x1 -
          this.scale(120) -
          this.scale(127) -
          this.scale(16) -
          this.scale(120) -
          this.scale(18) -
          this.scale(120),
        currentY,
        z0
      ),
      new Vector3(
        x1 -
          this.scale(120) -
          this.scale(127) -
          this.scale(16) -
          this.scale(120),
        currentY,
        z0
      )
    ];
    centerPlanks.forEach((p) => {
      this.plank(p, this.scale(120), plankL, plankH, 2);
    });

    /*
    this.plank(new Vector3(-x0 + 120, -y0 + 140, -z0), 127, 1000, 22, 2);
    this.plank(new Vector3(-x0 + 264, -y0 + 140, -z0), 120, 1000, 22, 2);
    this.plank(new Vector3(-x0 + 400, -y0 + 140, -z0), 120, 1000, 22, 2);
    this.plank(new Vector3(-x0 + 540, -y0 + 140, -z0), 120, 1000, 22, 2);
    this.plank(new Vector3(-x0 + 678, -y0 + 140, -z0), 120, 1000, 22, 2);
    this.plank(new Vector3(-x0 + 815, -y0 + 140, -z0), 120, 1000, 22, 2);
    this.plank(new Vector3(-x0 + 953, -y0 + 140, -z0), 127, 1000, 22, 2);
    this.plank(
      new Vector3(-x0 + 1080, -y0 + 140, -z0),
      120,
      1000,
      22,
      2,
      0,
      r,
      r,
      0
    ); //should be rounded
*/
    currentY += plankH;
    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.mesh.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.mesh.add(wireframe);

    this.addSpaces(0, currentY);
  }
}
