import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { VehicleContext } from 'src/app/lib/model/vehicle-context';
import { UiService } from 'src/app/services/ui.service';
import { Vehicle } from '../../lib/vehicle';
import { Axle } from '../lib/axle';
import { FormService } from './form.service';
import { AxlesForm } from './lib/axles.form';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.less']
})
export class FormComponent implements OnDestroy {
  readonly form: AxlesForm;
  readonly context: VehicleContext;

  private unsubscribe$ = new Subject<void>();

  public get vehicle(): Vehicle {
    return this.service.vehicle;
  }
  constructor(uiService: UiService, private service: FormService) {
    this.form = new AxlesForm(this.vehicle, uiService);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close(): void {
    this.service.close();
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const vehicleAxles = this.form.Vehicle.Axles.map(
        (form) => new Axle({ ...form.axle, width: this.vehicle.cabinWidth })
      );
      this.vehicle.axles = vehicleAxles;
      this.vehicle.enabledSpaces.forEach((space) => {
        const spaceForm = this.form.findSpaceForm(space);
        space.axles = spaceForm
          ? spaceForm.Axles.map(
              (form) => new Axle({ ...form.axle, width: space.width })
            )
          : [];
      });
      this.service
        .submit()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.service.updateUiAndClose();
        });
    }
  }
}
