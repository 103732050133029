import {
  Color,
  DoubleSide,
  LineDashedMaterial,
  MeshBasicMaterial,
  MeshLambertMaterial,
  ShaderMaterial
} from 'three';

export class Constants {
  static readonly TRANSPARENT_MATERIAL = new MeshLambertMaterial({
    transparent: true,
    opacity: 0.0,
    depthWrite: false
  });

  static readonly CABIN_MATERIAL = new MeshBasicMaterial({
    transparent: true,
    opacity: 0.1,
    side: DoubleSide,
    depthWrite: false,
    color: 0xffffff
  });

  static readonly FLOOR_MATERIAL = new MeshLambertMaterial({
    transparent: false,
    opacity: 0,
    side: DoubleSide,
    color: 0x757575,
    depthWrite: false
  });

  static readonly HIGHLIGHT_MATERIAL = new MeshLambertMaterial({
    transparent: true,
    opacity: 0.6,
    side: DoubleSide,
    color: 0x00ff00,
    depthWrite: false
  });

  static readonly SUCCESS_AXLE_MATERIAL = new MeshLambertMaterial({
    transparent: true,
    opacity: 0.6,
    side: DoubleSide,
    color: 0x00ff00,
    depthWrite: false
  });

  static readonly NEUTRAL_AXLE_MATERIAL = new MeshLambertMaterial({
    transparent: false,
    opacity: 0,
    side: DoubleSide,
    color: 0x757575,
    depthWrite: false
  });

  static readonly WRONG_AXLE_MATERIAL = new MeshLambertMaterial({
    transparent: true,
    opacity: 0.6,
    side: DoubleSide,
    color: 0xff0000,
    depthWrite: false
  });

  static readonly DARK_BROWN_WOOD_MATERIAL = new MeshBasicMaterial({
    transparent: false,
    opacity: 1,
    side: DoubleSide,
    depthWrite: true,
    color: 0xae846d
  });
  static readonly LIGHT_BROWN_WOOD_MATERIAL = new MeshBasicMaterial({
    transparent: false,
    opacity: 1,
    side: DoubleSide,
    depthWrite: true,
    color: 0xc49e70
  });

  static readonly DISABLED_SPACE_MATERIAL = new MeshBasicMaterial({
    transparent: false,
    opacity: 1,
    side: DoubleSide,
    depthWrite: true,
    color: 0xeeeeee
  });

  static readonly DISABLED_SPACE_LINE_MATERIAL = new LineDashedMaterial({
    color: 0xd2d0c2,
    linewidth: 1,
    scale: 1,
    dashSize: 300,
    gapSize: 100
  });
  /*static readonly DISABLED_SPACE_LINE_MATERIAL = new LineDashedMaterial({
    color: 0x333333,
    linewidth: 1,
    scale: 1,
    dashSize: 300,
    gapSize: 100
  });*/

  static readonly CYLINDER_MATERIAL = new ShaderMaterial({
    uniforms: {
      color1: {
        value: new Color(0xdfaf28) //new Color('red')
      },
      color2: {
        value: new Color(0xedc74f) //new Color('purple')
      }
    },
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
      }
    `,
    fragmentShader: `
      uniform vec3 color1;
      uniform vec3 color2;
  
      varying vec2 vUv;  
      void main() {
        gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
      }
    `
    // wireframe: true //koloruje same krawędzie
  });

  // 1#C3C3C1#CFCFCD#D9D9D7#E1E1DF#E7E7E5White

  static readonly METAL_MATERIAL = new ShaderMaterial({
    uniforms: {
      color1: {
        value: new Color(0xcfcfcd)
      },
      color2: {
        value: new Color(0xd9d9d7)
      }
    },
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
      }
    `,
    fragmentShader: `
      uniform vec3 color1;
      uniform vec3 color2;
  
      varying vec2 vUv;  
      void main() {
        gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
      }
    `
    // wireframe: true //koloruje same krawędzie
  });
  /*
  fajny materiał tylko na krawędzie 
  static readonly CYLINDER_MATERIAL = new ShaderMaterial({
    uniforms: {
      color1: {
        value: new Color('red')
      },
      color2: {
        value: new Color('purple')
      }
    },
    vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
  `,
    fragmentShader: `
    uniform vec3 color1;
    uniform vec3 color2;
  
    varying vec2 vUv;
    
    void main() {
      
      gl_FragColor = vec4(mix(color1, color2, vUv.y), 1.0);
    }
  `,
    wireframe: true
  }); */

  /*new MeshBasicMaterial({
    transparent: false,
    opacity: 1,
    side: DoubleSide,
    depthWrite: true,
    color: 0xdeab27
  });*/
}
