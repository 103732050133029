import { Component, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import {
  MatSelectionList,
  MatSelectionListChange
} from '@angular/material/list';
import { Observable, Subject } from 'rxjs';
import { VehicleContext } from 'src/app/lib/model/vehicle-context';
import { LoadComponentService } from 'src/app/services/load-component.service';
import { ContextService } from 'src/app/vehicle/context/context.service';

@Component({
  selector: 'app-export-list',
  templateUrl: './export-list.component.html',
  styleUrls: ['./export-list.component.less']
})
export class ExportListComponent implements OnInit {
  @ViewChild('contexts') selectedContexts: MatSelectionList;
  @ViewChild('selectAll') allSelected: MatOption;
  contexts$: Observable<VehicleContext[]>;
  error = null;

  constructor(
    private contextService: ContextService,
    private componentService: LoadComponentService
  ) {}

  ngOnInit(): void {
    console.log('pdf-export/export-list component init');
    this.contexts$ = this.contextService.getAvailableVehicles();
  }

  close() {
    this.componentService.clear('ExportListComponent');
  }

  onSelection(event: MatSelectionListChange) {
    const selected = event.source.selectedOptions.selected.length;
    if (selected > 0) {
      this.error = null;
    }
  }

  selectAllContexts() {
    if (this.allSelected.selected) {
      this.selectedContexts.selectAll();
    } else {
      this.selectedContexts.deselectAll();
    }
  }

  onSubmit() {
    const selected: VehicleContext[] =
      this.selectedContexts.selectedOptions.selected
        .filter((option) => option.value instanceof VehicleContext)
        .map((option) => option.value);
    if (selected.length === 0) {
      this.error = $localize`Zaznacz przynajmniej jedną przestrzeń`;
      return;
    }
    this.contextService.startExport(selected);
    this.close();
  }
}
