import { v4 as uuidv4 } from 'uuid';

import { LoadMesh } from './load-mesh';
import { Position } from 'src/app/lib/positioner/position';
import { CuboidHull } from './cuboid-hull';
import { LoadDisplaySettings } from './load-display-settings';

export abstract class Load {
  uuid: string;
  spaceUuid: string;
  position: Position;
  name: string;
  color: number;
  floorableTop: boolean = false;
  floorableBottom: boolean = false;
  weight: number;
  idx: number;
  initialTimestamp: number;
  // fixedHorizontalRotation: boolean; //obrócono ręcznie w poziomie
  // fixedVerticalRotation: boolean; // obrócono ręcznie w pionie

  horizontalRotationFrozen: boolean = false; //brak możliwości obrotu w poziomie // dla algorytmów
  verticalRotationFrozen: boolean = true; // brak możliwości obrotu w pionie // dla algorytmów

  fixedPosition: boolean; // ręcznie przeniesiono ładunek
  rotations: string[] = [];
  loaded: boolean;
  type: string;
  shape: string;
  userDefined = false;
  projectId?: string;
  createdAt?: Date;
  updatedAt?: Date;

  protrusionLength: number = 0; //dopuszczalny % wystawania w długości - default >=99%
  protrusionWidth: number = 0; //dopuszczalny % wystawania w szerokości - default >=99%
  selected: boolean;

  markedForReload: boolean = false;

  displayOrder?: number;

  #mesh: LoadMesh;

  constructor(obj: any, private settings: LoadDisplaySettings) {
    Object.assign(this, obj);
    if (this.uuid === undefined) {
      this.generateUuid();
    }
    this.rotations = [...(this.rotations || [])];

    this.createMesh(settings);
  }

  abstract get cuboidHull(): CuboidHull;
  abstract get fullName(): string;
  abstract get volume(): number;
  abstract get area(): number;
  abstract get fullDescription(): string;

  abstract get descriptiveDimensions(): number[];

  abstract createMesh(settings: LoadDisplaySettings): LoadMesh;

  get mesh(): LoadMesh {
    return this.#mesh;
  }

  set mesh(mesh: LoadMesh) {
    this.#mesh = mesh;
  }

  get floorableAll(): boolean {
    return this.floorableTop && this.floorableBottom;
  }

  set floorableAll(val: boolean) {
    this.floorableTop = val;
    this.floorableBottom = val;
  }

  public is(cls: typeof Load) {
    return this instanceof cls;
  }

  public generateUuid() {
    this.uuid = uuidv4();
  }

  public select() {
    this.selected = true;
    this.mesh.select();
  }

  public unselect() {
    this.selected = false;
    this.mesh.select(false);
  }

  public hover() {
    this.mesh.hover();
  }

  public unhover() {
    this.mesh.unhover();
  }

  public updateSettings(settings: LoadDisplaySettings) {
    if (this.displaySettingsChanged(settings)) {
      this.createMesh(settings);
    }
  }

  public isSameSizeAs(other: Load): boolean {
    return (
      this.cuboidHull.length === other.cuboidHull.length &&
      this.cuboidHull.width === other.cuboidHull.width &&
      this.cuboidHull.height == other.cuboidHull.height
    );
  }

  public requireReloadWhenChangedTo(updated: Load): boolean {
    return (
      !this.isSameSizeAs(updated) ||
      this.floorableTop !== updated.floorableTop ||
      this.floorableBottom !== updated.floorableBottom ||
      this.protrusionLength !== updated.protrusionLength ||
      this.protrusionWidth !== updated.protrusionWidth ||
      this.verticalRotationFrozen !== updated.verticalRotationFrozen ||
      this.horizontalRotationFrozen !== updated.horizontalRotationFrozen
    );
  }

  public markForReload(val: boolean = true) {
    this.markedForReload = val;
  }

  private displaySettingsChanged(newSettings: LoadDisplaySettings) {
    return (
      newSettings.loadBordersIntensity !== this.settings.loadBordersIntensity ||
      newSettings.loadTransparency !== this.settings.loadTransparency
    );
  }
}
