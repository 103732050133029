import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Rating } from '../lib/model/rating';
import { RatingService } from '../services/rating.service';
import { MessageService } from '../messenger/message.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.less']
})
export class RatingComponent implements OnInit, OnDestroy {
  selectedRating: number = null;
  calculationId: string;
  historyId: string;
  ratingError = false;
  description = new UntypedFormControl('');
  stars = [
    {
      id: 1,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 2,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 3,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 4,
      icon: 'star',
      class: 'star-gray star-hover star'
    },
    {
      id: 5,
      icon: 'star',
      class: 'star-gray star-hover star'
    }
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<RatingComponent>,
    private service: RatingService,
    private messenger: MessageService
  ) {
    this.calculationId = data.calculationId;
    this.historyId = data.historyId;
  }

  ngOnInit(): void {
    this.selectedRating = null;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectStar(value: number): void {
    this.stars.filter((star) => {
      if (star.id <= value) {
        star.class = 'star-gold star';
      } else {
        star.class = 'star-gray star';
      }

      return star;
    });

    this.selectedRating = value;
  }

  sendRating() {
    if (this.selectedRating === null) {
      this.ratingError = true;
      return;
    }
    const rating = new Rating({
      calculationId: this.calculationId,
      historyId: this.historyId,
      rating: this.selectedRating,
      description: this.description.value
    });
    this.service
      .rateCalculation(rating)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.status === 'ok') {
          this.messenger.snackSuccess({
            title: $localize`Dodano ocenę. Dziękujemy!`
          });
        } else {
          this.messenger.snackError({
            title: $localize`Wystąpił błąd. Spróbuj ponownie.`
          });
        }
        this.closeDialog();
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
