<div class="modal-box file-import-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <ng-container *ngIf="vehicle">
        <h1 i18n>Edytuj {{ vehicle.name }}</h1>
      </ng-container>
      <ng-container *ngIf="!vehicle">
        <h1 i18n>Zdefiniuj własną przestrzeń ładunkową</h1>
      </ng-container>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <app-type #type [items]="vehicleTypes"></app-type>
      <app-name #name [default]="vehicle?.name || ''"></app-name>
      <ng-container #spaces></ng-container>
      <div *ngIf="canAddSpace()" class="text-right">
        <button
          mat-mini-fab
          color="primary"
          (click)="addSpaceForm()"
          i18n-aria-label
          i18n-title
          aria-label="Kolejna przestrzeń"
          title="Kolejna przestrzeń"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div class="buttons">
        <button mat-raised-button (click)="onSubmit()" cdkFocusInitial>
          <mat-icon>save</mat-icon>
          <ng-container i18n>Zapisz</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>
