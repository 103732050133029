import { Load } from 'src/app/load/lib/load';
import { LoadGroup, LoadGroupType } from './load-group';

export class LoadGroupList {
  public loads: LoadGroup[];

  constructor(private typeRef: LoadGroupType) {
    this.loads = [];
  }

  public addLoad(load: Load, cnt: number = 1): LoadGroupList {
    const found = this.loads.find((group) => group.has(load));
    if (found != null) {
      found.addLoad(load, cnt);
    } else {
      const newGroup = new this.typeRef(load, cnt);
      this.loads.push(newGroup);
    }
    return this;
  }

  public removeLoad(load: Load): LoadGroupList {
    this.loads = this.loads.filter((group) => !group.has(load));
    return this;
  }

  public isEmpty() {
    return this.loads.length === 0;
  }

  public clear() {
    this.loads = [];
  }
}
