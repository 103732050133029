<div class="help-flooring">
  <div class="text-right with-img">
    <img
      src="assets/images/block-rotation.png"
      alt="Zablokuj ułożenie"
      i18n-alt
    />
  </div>
  <div class="help-main">
    <h2 class="primary" i18n>Zablokuj ułożenie</h2>
    <p i18n>
      Ładunek nie będzie obracany w żadnym kierunku, nawet jeśli pozwalałoby to
      na lepsze rozmieszczenie.
    </p>
    <p i18n>
      Możesz użyć tej opcji, jeśli ładunek musi zostać załadowany w konkretny
      sposób, na przykład przy załadunku bocznym długich ładunków.
    </p>
  </div>
</div>
