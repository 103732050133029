import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { InfoService } from 'src/app/info/info.service';
import { InfoType } from 'src/app/info/lib/info-type';
import { Load } from '../../lib/load';

interface RotationForm {
  blockAll: FormControl<boolean>;
  allowAll: FormControl<boolean>;
}

@Component({
  selector: 'app-load-rotation',
  templateUrl: './rotation.component.html',
  styleUrls: ['./rotation.component.less']
})
export class RotationComponent implements OnInit {
  @Input() mode: 'create' | 'add' | 'edit' = 'add';
  @Input() set item(item: Load) {
    this._item = item;
    this.setValues(item);
  }

  get item(): Load {
    return this._item;
  }

  private _item: Load;
  protected opened: boolean;
  protected get canAllowAll(): boolean {
    return this.form.controls.blockAll.value !== true;
  }
  protected get canBlockAll(): boolean {
    return this.form.controls.allowAll.value !== true;
  }
  protected form: FormGroup<RotationForm>;
  constructor(private infoService: InfoService) {
    this.form = new FormGroup({
      blockAll: new FormControl<boolean>(false),
      allowAll: new FormControl<boolean>(true)
    });
  }

  ngOnInit(): void {}

  setValues(load: Load) {
    if (!load) {
      return;
    }
    if (this.mode === 'create') {
      this.form.controls.blockAll.setValue(false);
      this.form.controls.allowAll.setValue(true);
      this.form.controls.allowAll.enable();
      this.form.controls.blockAll.disable();
    } else {
      this.form.controls.blockAll.setValue(false);
      this.form.controls.allowAll.setValue(false);
      this.form.controls.allowAll.enable();
      this.form.controls.blockAll.enable();
    }

    if (load.horizontalRotationFrozen) {
      this.form.controls.blockAll.setValue(true);
      this.form.controls.allowAll.setValue(false);
      this.form.controls.allowAll.disable();
    } else if (!load.verticalRotationFrozen) {
      this.form.controls.allowAll.setValue(true);
      this.form.controls.blockAll.disable();
    }
  }

  public canRotate(): boolean {
    return this.form.controls.blockAll.value !== true;
  }

  public canRotateVertical(): boolean {
    return this.form.controls.allowAll.value;
  }

  protected change(name: string, event: MatCheckboxChange) {
    const checked = event.checked;
    this.form.controls.allowAll.enable();
    this.form.controls.blockAll.enable();
    if (name === 'blockAll' && checked) {
      this.form.patchValue({ allowAll: false });
      this.form.controls.allowAll.disable();
    } else if (name === 'allowAll' && checked) {
      this.form.patchValue({ blockAll: false });
      this.form.controls.blockAll.disable();
    }
  }

  protected toggleAllowAllHelp() {
    this.infoService.toggle(InfoType.AllowVerticalRotation);
  }

  protected toggleBlockAllHelp() {
    this.infoService.toggle(InfoType.BlockRotation);
  }
}
