import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  debounce,
  debounceTime,
  distinctUntilChanged,
  first,
  Subject,
  takeUntil
} from 'rxjs';
import { ProfileService } from '../services/profile.service';
import { LabelComponentModel } from './label.component.model';
import { LabelService } from './label.service';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.less']
})
export class LabelComponent implements OnInit, OnDestroy {
  public model: LabelComponentModel;

  private unsubscribe$ = new Subject<void>();

  private labelFontSize$ = new Subject<number>();

  constructor(
    private service: LabelService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.service.getModel().subscribe((x) => {
      this.model = x;
    });
    this.labelFontSize$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((val) => {
        this.model.fontSize = val;
        this.modelChanged();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public modelChanged() {
    this.profileService
      .saveLabelConfig(this.model)
      .pipe(takeUntil(this.unsubscribe$), first())
      .subscribe((labelConfig) => {
        this.service.setModel(this.model);
        this.profileService.updateLabelConfig(labelConfig);
      });
  }

  public fontIncrease() {
    this.model.fontSize++;
    this.labelFontSize$.next(this.model.fontSize);
    this.updateFontSizeImmediately();
  }

  public fontDecrease() {
    this.model.fontSize--;
    this.labelFontSize$.next(this.model.fontSize);
    this.updateFontSizeImmediately();
  }

  private updateFontSizeImmediately() {
    this.profileService.updateLabelConfig(this.model);
  }
}
