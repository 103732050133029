<div class="element" [style.--fill]="fillColor">
  <div class="load-name">
    <div mat-line title="{{ group.load.name }}">
      {{ group.load.name }}
    </div>
    <div mat-line>
      {{ group.load | dimensions | async }}
    </div>
    <div mat-line>
      {{ group.load.weight | weightUnit | async | number : '0.0-2'
      }}{{ ui.getWeightUnit() | async }}
    </div>
  </div>
  <div class="floorable-info">
    <div
      *ngIf="group.isFloorableBoth()"
      i18n-title
      title="Piętrowalność całkowita"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 20"
        class="floorable-icon"
      >
        <rect x="0" y="0" width="9" height="9" fill="#fe9800"></rect>
        <rect x="0" y="11" width="9" height="9" fill="#fe9800"></rect>
      </svg>
      <span i18n>CAŁK.</span>
    </div>
    <div
      *ngIf="group.isFloorableOnlyBottom()"
      i18n-title
      title="Piętrowalność aktywna"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 20"
        class="floorable-icon"
      >
        <rect x="0" y="0" width="9" height="9" fill="#fe9800"></rect>
        <rect x="0" y="11" width="9" height="9" fill="#000000"></rect>
      </svg>
      <span i18n>AKT.</span>
    </div>
    <div
      *ngIf="group.isFloorableOnlyTop()"
      i18n-title
      title="Piętrowalność pasywna"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 20"
        class="floorable-icon"
      >
        <rect x="0" y="0" width="9" height="9" fill="#000000"></rect>
        <rect x="0" y="11" width="9" height="9" fill="#fe9800"></rect>
      </svg>
      <span i18n>PAS.</span>
    </div>
    <div *ngIf="group.isNonFloorable()" i18n-title title="Niepiętrowalny">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 20"
        class="floorable-icon"
      >
        <rect x="0" y="0" width="9" height="9" fill="#000000"></rect>
        <rect x="0" y="11" width="9" height="9" fill="#000000"></rect>
      </svg>
      <span i18n>NIE</span>
    </div>
  </div>
  <div class="load-count-container">
    <input
      type="number"
      class="load-count"
      step="1"
      min="1"
      max="{{ maxCount }}"
      [(ngModel)]="cnt"
      (ngModelChange)="cntChanged($event)"
    />
    <div class="arrows">
      <button type="button" class="arrow-up" (click)="increaseCount()">
        ▲
      </button>
      <button type="button" class="arrow-down" (click)="decreaseCount()">
        ▼
      </button>
    </div>
  </div>
  <span (click)="remove()" class="remove">
    <mat-icon>delete_forever</mat-icon>
  </span>
</div>
