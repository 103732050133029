import { Vector3 } from 'three';
import { UiService } from '../../services/ui.service';
import { TextLabel } from './TextLabel';

export class GridAxisLabel extends TextLabel {
  public constructor(
    position: Vector3,
    x: number,
    unit: string,
    uiService: UiService,
    protected textScale = 1.0
  ) {
    super(
      uiService.convertLengthToUnit(x, unit) + '\n' + unit,
      position,
      0,
      'grid-axis-label',
      textScale
    );
  }
}
