import { FlooringLabel } from './FlooringLabel';
import { Constants as Config } from 'src/app/config/constants';

export class FlooringBottomLabel extends FlooringLabel {
  updateText(): void {
    this.setText(this.load.floorableBottom ? 'get_app' : 'block');
  }

  updateLabelPosition(): void {
    const position = this.load.mesh.position.clone();
    position.y -=
      (this.load.cuboidHull.height / 2 - 20) * Config.DIMENSION_SCALING;
    this.setPosition(position);
  }

  updateCssClass(): void {
    this.setCssClass(
      'material-label ' +
        (this.load.floorableBottom ? 'color-enabled' : 'color-disabled') +
        ' ' +
        'scale-' +
        this.textScale.toString().replace('.', '_')
    );
  }
}
