import { PositionerResponse } from './positioner.response';

export class HistoryResponse {
  uuid: string;
  action: string;
  current: number;
  calculation: PositionerResponse;
  message: string; //first/last or number

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
