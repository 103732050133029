<ng-template
  *ngIf="(uiMode$ | async) === 'standard'; then standardMode; else advancedMode"
></ng-template>
<ng-template #standardMode>
  <div
    class="vehicle-summary standard"
    [class]="type"
    [class.hide]="!currentVehicle?.getVehicle()"
    [class.expanded]="expanded"
  >
    <div *ngIf="hideList === false">
      <app-context></app-context>
    </div>
    <div
      class="summary-content flex"
      *ngIf="currentVehicle?.getVehicle(); else noSummary"
      #summaryContent
    >
      <div class="vehicle-data flex column">
        <div
          *ngIf="currentVehicle?.getVehicle(); else noVehicleName"
          class="vehicle-name flex fill"
        >
          <strong>{{ currentVehicle?.getVehicle().fullName }}</strong>
        </div>
        <ng-template #noVehicleName>
          <div class="vehicle-name flex fill">
            <strong i18n>Pojazd</strong> <span>---</span>
          </div>
        </ng-template>
        <div
          *ngIf="currentVehicle?.getVehicle(); else noVehicleSize"
          class="vehicle-size"
        >
          {{ currentVehicle?.getVehicle() | vehicleReadableSize | async }}
        </div>
        <ng-template #noVehicleSize>
          <div class="vehicle-size"><strong i18n>Wymiary </strong> ---</div>
        </ng-template>
      </div>

      <div
        *ngIf="
          currentVehicle?.getVehicle()?.enabledSpaces.length > 1;
          else noTrailer
        "
        class="trailer flex column fill"
      >
        <div>
          <ng-container
            *ngTemplateOutlet="
              loadProgress;
              context: {
                usedPercentage: mainStats?.getUsedSpacePercentage(
                  settings?.summaryFloorUnit
                ),
                used: usedLDM,
                total: currentLDM,
                showWeightBar: false,
                unit: settings?.summaryFloorUnit
              }
            "
          >
          </ng-container>
        </div>

        <div>
          <ng-container *ngFor="let stats of trailerStats; let idx = index">
            <ng-container
              *ngTemplateOutlet="
                loadProgress;
                context: {
                  usedPercentage: stats.getUsedSpacePercentage(
                    settings?.summaryFloorUnit
                  ),
                  used: stats.getUsedSpace(settings?.summaryFloorUnit),
                  total: stats.getTotalSpace(settings?.summaryFloorUnit),
                  unit: settings?.summaryFloorUnit,
                  showWeightBar: true,
                  usedWeightPercentage: stats.getUsedWeightPercentage(
                    currentVehicle?.getVehicle()?.enabledSpaces[idx]
                      .maxLoadingWeight
                  ),
                  usedWeight: stats.getUsedWeight(),
                  currentWeight:
                    currentVehicle?.getVehicle()?.enabledSpaces[idx]
                      .maxLoadingWeight
                }
              "
            >
            </ng-container>
          </ng-container>
        </div>
      </div>

      <ng-template #noTrailer class="no-trailer">
        <ng-container
          *ngTemplateOutlet="
            loadProgress;
            context: {
              usedPercentage: mainStats?.getUsedSpacePercentage(
                settings?.summaryFloorUnit
              ),
              used: usedLDM,
              total: currentLDM,
              unit: settings?.summaryFloorUnit,
              showWeightBar: true,
              usedWeightPercentage: mainStats?.getUsedWeightPercentage(
                currentVehicle?.getVehicle()?.enabledSpaces[0]?.maxLoadingWeight
              ),
              usedWeight: usedWeight,
              currentWeight: currentWeight
            }
          "
        >
        </ng-container>
      </ng-template>

      <div class="loadings-count flex">
        <div>
          <span i18n>Liczba ładunków: </span> <strong>{{ currentEP }}</strong>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noSummary>
  <div class="summary-content flex" #summaryContent>
    <div class="vehicle-data flex column">
      <div class="vehicle-name flex fill gap-1">
        <strong i18n>Pojazd</strong> <span>---</span>
      </div>

      <div class="vehicle-size"><strong i18n>Wymiary </strong> ---</div>
    </div>

    <ng-container
      *ngTemplateOutlet="
        loadProgress;
        context: {
          usedLDMPercentage: 0,
          usedLDM: 0,
          currentLDM: 0,
          showWeightBar: true,
          usedWeightPercentage: 0,
          usedWeight: 0,
          currentWeight: 0
        }
      "
    >
    </ng-container>
    <div class="loadings-count flex">
      <div><span i18n>Liczba ładunków: </span> <strong>---</strong></div>
    </div>
  </div>
</ng-template>

<ng-template #advancedMode>
  <div
    class="vehicle-summary"
    [class]="type"
    [class.hide]="!currentVehicle?.getVehicle()"
    [class.expanded]="expanded"
  >
    <div
      *ngIf="type != 'pdf-export'"
      class="expand"
      (click)="expanded = !expanded"
      i18n-matTooltip
      matTooltip="Pokaż / ukryj statystyki"
      [matTooltipPosition]="'left'"
      [matTooltipShowDelay]="400"
      #showBottomPanel="matTooltip"
    >
      <span *ngIf="expanded">&laquo;</span>
      <span *ngIf="!expanded">&raquo;</span>
    </div>
    <div *ngIf="hideList === false">
      <app-context></app-context>
    </div>
    <div
      class="summary-row"
      *ngIf="currentVehicle?.getVehicle()?.type != 'warehouse'"
    >
      <div class="text-right summary-label flex space-between">
        <mat-icon
          class="settings material-icons-outlined"
          (click)="openFloorSettings()"
          i18n-matTooltip
          matTooltip="Ustawienia zajętości przestrzeni"
          matTooltipPosition="right"
          >settings</mat-icon
        >
        <span
          ><ng-container i18n>Przestrzeń</ng-container> [{{
            (settingsService.profile$ | async).settings.summaryFloorUnit
          }}]</span
        >
      </div>
      <div class="summary-value">
        <ng-container
          *ngTemplateOutlet="
            loadProgressAdvanced;
            context: {
              usedPercentage: mainStats?.getUsedSpacePercentage(
                settings?.summaryFloorUnit
              ),
              freePercentage: mainStats?.getFreeSpacePercentage(
                settings?.summaryFloorUnit
              ),
              used: mainStats?.getUsedSpace(settings?.summaryFloorUnit),
              total: mainStats?.getTotalSpace(settings?.summaryFloorUnit),
              free: mainStats?.getFreeSpace(settings?.summaryFloorUnit),
              unit: settings?.summaryFloorUnit,
              showPercentage: settings?.summaryFloorPercentage,
              stats: mainStats
            }
          "
        >
        </ng-container>
        <div
          *ngIf="currentVehicle?.vehicle?.enabledSpaces.length > 1"
          class="free-space"
        >
          <ng-container *ngFor="let stats of trailerStats; let idx = index">
            <ng-container
              *ngTemplateOutlet="
                loadProgressAdvanced;
                context: {
                  usedPercentage: stats.getUsedSpacePercentage(
                    settings?.summaryFloorUnit
                  ),
                  freePercentage: stats.getFreeSpacePercentage(
                    settings?.summaryFloorUnit
                  ),
                  used: stats.getUsedSpace(settings?.summaryFloorUnit),
                  total: stats.getTotalSpace(settings?.summaryFloorUnit),
                  free: stats.getFreeSpace(settings?.summaryFloorUnit),
                  unit: settings?.summaryFloorUnit,
                  showPercentage: settings?.summaryFloorPercentage,
                  stats: stats
                }
              "
            >
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="summary-row">
      <div class="text-right summary-label" i18n>Liczba ładunków</div>
      <div class="summary-value flex">
        <div class="col1">
          <strong>{{ currentEP }}</strong>
        </div>
        <div class="col2">
          <span class="summary-label" i18n>Wolna podłoga [m2]</span>
          <strong>{{ freeFloor }}</strong>
        </div>
      </div>
    </div>
    <div class="summary-row">
      <div class="text-right summary-label">
        <ng-container i18n>Waga ładunku</ng-container> [{{
          ui.getWeightUnit() | async
        }}]
      </div>
      <div class="summary-value flex">
        <div class="col1">
          <strong>
            {{ usedWeight | weightUnit | async | number : '0.0-2' }}
          </strong>
        </div>
        <div class="col2">
          <span class="summary-label" i18n
            >Kubatura ładunku [m3] zajęte / wolne</span
          >
          <strong
            >{{ usedVolume }} /
            <span class="primary">{{ freeVolume }}</span></strong
          >
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #loadProgressAdvanced
  let-usedPercentage="usedPercentage"
  let-freePercentage="freePercentage"
  let-used="used"
  let-total="total"
  let-free="free"
  let-unit="unit"
  let-showPercentage="showPercentage"
  let-stats="stats"
>
  <div class="free-space">
    <span
      class="used"
      [style.width.%]="
        usedPercentage > 0 && usedPercentage < 1 ? 1 : usedPercentage
      "
      ><ng-container i18n
        >Zajęte {{ used }} z {{ total }} {{ unit }}</ng-container
      >
      <ng-container *ngIf="showPercentage">
        ({{ usedPercentage }}%)</ng-container
      >
    </span>
    <div
      class="value"
      (mouseover)="highlightLDM(stats)"
      (mouseout)="removeLDMHighlight()"
      [style.width.%]="
        freePercentage > 0 && freePercentage < 1 ? 1 : freePercentage
      "
    >
      <span
        ><ng-container i18n
          >Wolne {{ free }} z {{ total }} {{ unit }}</ng-container
        >
        <ng-container *ngIf="showPercentage">
          ({{ freePercentage }}%)</ng-container
        ></span
      >
    </div>
  </div>
</ng-template>
<ng-template
  #loadProgress
  let-percentage="usedPercentage"
  let-used="used"
  let-total="total"
  let-percentageWeight="usedWeightPercentage"
  let-usedWeight="usedWeight"
  let-currentWeight="currentWeight"
  let-showWeightBar="showWeightBar"
  let-unit="unit"
>
  <div class="load-progress flex column fill">
    <div class="free-space flex">
      <span class="info">
        {{ percentage }}% ({{ used }} {{ unit }} / {{ total }} {{ unit }})
      </span>
      <div
        class="used"
        [style.width.%]="
          percentage > 100
            ? 100
            : percentage > 0 && percentage < 1
            ? 1
            : percentage
        "
      ></div>
    </div>
    <div *ngIf="showWeightBar" class="free-weight flex">
      <span class="info">
        {{ percentageWeight }}% ({{ usedWeight | weightUnit | async }}
        {{ ui.getWeightUnit() | async }} /
        {{ currentWeight | weightUnit | async
        }}{{ ui.getWeightUnit() | async }})
      </span>

      <ng-container [ngSwitch]="true">
        <div
          *ngSwitchCase="(percentageWeight > 100 ? 100 : percentageWeight) < 30"
          class="used-30"
          [style.width.%]="
            percentageWeight > 100
              ? 100
              : percentageWeight > 0 && percentageWeight < 1
              ? 1
              : percentageWeight
          "
        >
          <ng-container *ngTemplateOutlet="progressBar"></ng-container>
        </div>
        <div
          *ngSwitchCase="
            (percentageWeight > 100 ? 100 : percentageWeight) < 60 &&
            (percentageWeight > 100 ? 100 : percentageWeight) > 30
          "
          class="used-60"
          [style.width.%]="percentageWeight > 100 ? 100 : percentageWeight"
        >
          <ng-container *ngTemplateOutlet="progressBar"></ng-container>
        </div>

        <div
          *ngSwitchCase="
            (percentageWeight > 100 ? 100 : percentageWeight) < 90 &&
            (percentageWeight > 100 ? 100 : percentageWeight) > 60
          "
          class="used-90"
          [style.width.%]="percentageWeight > 100 ? 100 : percentageWeight"
        >
          <ng-container *ngTemplateOutlet="progressBar"></ng-container>
        </div>
        <div
          *ngSwitchDefault
          class="used-100"
          [style.width.%]="percentageWeight > 100 ? 100 : percentageWeight"
        >
          <ng-container *ngTemplateOutlet="progressBar"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="ldm-container flex">
    <mat-icon>view_in_ar</mat-icon>
    <strong class="text-center fill">{{ total }} {{ unit }}</strong>
  </div>
</ng-template>

<ng-template #progressBar>
  <div class="border-overlay"></div>
  <div class="gradient-overlay"></div>
  <div class="bar"></div>
</ng-template>
