import { Component } from '@angular/core';
import { PositionerResponse } from '../lib/communication/positioner.response';
import { UiService } from '../services/ui.service';

import { CalculationSummaryService } from './calculation-summary.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-calculation-summary',
  templateUrl: './calculation-summary.component.html',
  styleUrls: ['./calculation-summary.component.less']
})
export class CalculationSummaryComponent {
  status: string;
  vehicleName: string;

  displayedLoadColumns: string[] = ['name', 'dimensions', 'weight'];

  private calculations: PositionerResponse[];

  constructor(
    private service: CalculationSummaryService,
    public ui: UiService
  ) {
    this.status = 'new';
    this.items = [];
  }

  set items(val: PositionerResponse[]) {
    this.calculations = val;
    this.vehicleName = val.length > 0 ? val[0].vehicle.fullName : '';
  }

  get items() {
    return this.calculations;
  }

  close(): void {
    // console.log('close clicked');
    this.service.close();
  }
  exportExcel() {
    // console.log('exportExcel clicked');
    //let element = document.getElementById('');
    const fileName = 'load-planning.xlsx';
    let cnt = 0;

    const wb = XLSX.utils.book_new();
    for (const res of this.items) {
      const data = [];
      for (const group of res.loadsCondensed.loads) {
        data.push([
          group.cnt,
          group.load.name,
          this.ui.getLengthInCurrentUnit(group.load.cuboidHull.width),
          this.ui.getLengthInCurrentUnit(group.load.cuboidHull.length),
          this.ui.getLengthInCurrentUnit(group.load.cuboidHull.height),
          this.ui.getWeightInCurrentUnit(group.load.weight)
        ]);
      }
      const wsData = [
        [
          $localize`Liczba ładunków`,
          $localize`Nazwa`,
          $localize`Długość` + ' (' + this.ui.lengthUnit.value + ')',
          $localize`Szerokość` + ' (' + this.ui.lengthUnit.value + ')',
          $localize`Wysokość` + ' (' + this.ui.lengthUnit.value + ')',
          $localize`Waga` + ' (' + this.ui.weightUnit.value + ')'
        ],
        ...data
      ];

      const ws = XLSX.utils.aoa_to_sheet(wsData);
      XLSX.utils.book_append_sheet(wb, ws, ++cnt + ' ' + res.vehicle.fullName);
    }

    XLSX.writeFile(wb, fileName);
  }
}
