import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appOnlyNumber]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnlyNumberDirective),
      multi: true
    }
  ]
})
export class OnlyNumberDirective implements ControlValueAccessor {
  @Input() appOnlyNumber = 'float';

  private ignore = [
    'Delete',
    'Backspace',
    'Tab',
    'Escape',
    'Enter',
    'NumpadEnter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown'
  ];

  private decimalPeriods = ['Comma', 'NumpadDecimal', 'Period'];

  private ctrlIgnore = ['KeyA', 'KeyC', 'KeyV', 'KeyX'];
  private _onChange: (value: any) => {};
  constructor(private el: ElementRef) {}

  writeValue(obj: any): void {
    this.setFilteredValue(obj);
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  private setFilteredValue(value: string) {
    let filtered = value ? (value + '').replace(/,/g, '.') : value;
    if (filtered) {
      const firstDotIdx = filtered.indexOf('.');
      if (firstDotIdx > 0) {
        const beforeFirstDot = filtered.substring(0, filtered.indexOf('.'));
        const afterFirstDot = filtered
          .substring(filtered.indexOf('.') + 1)
          .replace(/\./g, '');
        filtered = beforeFirstDot + '.' + afterFirstDot;
      } else {
        filtered = filtered.replace(/\./g, '');
      }
    }

    this.el.nativeElement.value = filtered;
    return filtered;
  }

  @HostListener('input') onInput() {
    let value = this.el.nativeElement.value;
    let newValue = this.setFilteredValue(value);
    this._onChange(newValue);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    const code = e.code;
    const mod = e.ctrlKey || e.metaKey;
    if (
      this.ignore.indexOf(code) >= 0 ||
      (mod && this.ctrlIgnore.indexOf(code) >= 0) ||
      (this.appOnlyNumber !== 'integer' &&
        this.decimalPeriods.indexOf(code) >= 0)
    ) {
      return;
    }
    const num = code.replace('Digit', '').replace('Numpad', '');
    if (e.shiftKey || isNaN(parseInt(num, 10))) {
      e.preventDefault();
    }
  }

  /*
  @HostListener('change', ['$event']) onChange(event) {
    this.el.nativeElement.value = (this.el.nativeElement.value + '').replace(
      ',',
      '.'
    );
  }
  */
}
