import { Load } from 'src/app/load/lib/load';
import { ContextMenuActionType } from './context-menu-action-type.enum';
import { PalletLoad } from 'src/app/load/type';

export class ContextMenuServiceCommunicationModel {
  public selectedLoads: Load[];
  public event: MouseEvent;
  public opened: boolean;
  public action: ContextMenuActionType;
  public value: any;

  public constructor() {
    this.selectedLoads = [];
  }

  public get isMultiMode(): boolean {
    return this.selectedLoads.length > 1;
  }

  public get selectedLoad(): Load | null {
    return this.selectedLoads.length > 0 ? this.selectedLoads[0] : null;
  }

  public set selectedLoad(load: Load) {
    this.selectedLoads = [load];
  }

  public get color(): number | null {
    return this.selectedLoads.length > 0 ? this.selectedLoads[0].color : null;
  }

  public get isFloorableAll(): boolean {
    return this.selectedLoads.length > 0
      ? this.selectedLoads[0].floorableAll
      : false;
  }

  public get isFloorableTop(): boolean {
    return this.selectedLoads.length > 0
      ? this.selectedLoads[0].floorableTop
      : false;
  }
  public get isFloorableBottom(): boolean {
    return this.selectedLoads.length > 0
      ? this.selectedLoads[0].floorableBottom
      : false;
  }

  public set isFloorableAll(val: boolean) {
    this.selectedLoads.length > 0
      ? (this.selectedLoads[0].floorableAll = val)
      : false;
  }

  public set isFloorableTop(val: boolean) {
    this.selectedLoads.length > 0
      ? (this.selectedLoads[0].floorableTop = val)
      : false;
  }
  public set isFloorableBottom(val: boolean) {
    this.selectedLoads.length > 0
      ? (this.selectedLoads[0].floorableBottom = val)
      : false;
  }

  public get canRotateHorizontal(): boolean {
    return this.selectedLoads.findIndex((l) => l.horizontalRotationFrozen) < 0;
  }

  public get canRotateVertical(): boolean {
    return this.selectedLoads.findIndex((l) => l.verticalRotationFrozen) < 0;
  }

  public get canChangeFlooring(): boolean {
    return !this.isMultiMode && !(this.selectedLoad instanceof PalletLoad);
  }

  public get canChangesize(): boolean {
    return !this.isMultiMode && !(this.selectedLoad instanceof PalletLoad);
  }
}
