import { v4 as uuidv4 } from 'uuid';
import { AxleMesh } from './axle-mesh';
import { AxleTriangularMesh } from './shape/axle-triangular';

export class Axle {
  uuid: string;
  offset: number;
  order: number;
  width: number; // tu nie powinno byc ustawiania

  createdAt?: Date;
  updatedAt?: Date;
  distanceFromGravityCenter: number;
  pressure = 0;

  #mesh: AxleMesh;
  get mesh(): AxleMesh {
    return this.#mesh;
  }

  protected set mesh(mesh: AxleMesh) {
    this.#mesh = mesh;
  }

  constructor(obj?: any) {
    Object.assign(this, obj);
    if (this.uuid === undefined) {
      this.uuid = uuidv4();
    }
    this.mesh = new AxleTriangularMesh(this);
  }

  public generateUuid() {
    this.uuid = uuidv4();
  }
}
