<div class="modal-box file-import-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n *ngIf="project">Edytuj projekt</h1>
      <h1 i18n *ngIf="!project">Dodaj nowy projekt</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <div [formGroup]="form" novalidate (ngSubmit)="onSubmit()">
        <div>
          <mat-form-field>
            <input
              cdkFocusInitial
              matInput
              i18-placeholder
              placeholder="Nazwa"
              formControlName="name"
              autocomplete="off"
            />
            <mat-error *ngIf="form.get('name').hasError('required')">
              <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
              <strong><ng-container i18n>wymagane</ng-container></strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label i18n>Folder</mat-label>
            <input
              type="text"
              i18n-placeholder
              i18n-aria-label
              placeholder="Wybierz"
              aria-label="Folder"
              matInput
              formControlName="directory"
              [value]="currentDirectory"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayDirectory"
            >
              <mat-option i18n>(brak)</mat-option>
              <mat-option
                *ngFor="let dir of filteredDirectories$ | async"
                [value]="dir"
                >{{ dir.name }}</mat-option
              >
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="buttons">
          <button mat-raised-button (click)="onSubmit()">
            <mat-icon>save</mat-icon>
            <ng-container i18n>Zapisz</ng-container>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
