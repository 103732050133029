import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  Subject
} from 'rxjs';
import { Raycaster, Camera, Vector2, Intersection } from 'three';
import { VectorMesh } from './vector-mesh';

@Injectable({
  providedIn: 'root'
})
export class FreeSpaceService {
  private measurementEnabled = new BehaviorSubject<boolean>(false);

  private listeners: Array<{
    type: string;
    fn: (e: MouseEvent | Event | any) => any;
  }> = [];
  private domElement?: HTMLElement;
  private vectors: VectorMesh[] = [];

  private raycaster: Raycaster;
  private camera?: Camera;

  private hovered = new Subject<VectorMesh | null>();

  public constructor() {
    this.raycaster = new Raycaster();
  }

  public get hovered$(): Observable<VectorMesh | null> {
    return this.hovered.pipe(
      //throttleTime(100),
      debounceTime(20),
      distinctUntilChanged(),
      map((v) => {
        this.vectors.forEach((u) => u.unhover());
        v?.hover();
        return v;
      })
    );
  }

  public get measurementEnabled$(): Observable<boolean> {
    return this.measurementEnabled.pipe(distinctUntilChanged());
  }

  public get currentMeasurementEnabledValue(): boolean {
    return this.measurementEnabled.value;
  }

  public enableMeasurement() {
    this.measurementEnabled.next(true);
  }

  public disableMeasurement() {
    this.measurementEnabled.next(false);
  }

  public listen(
    vectors: VectorMesh[],
    domElement: HTMLElement,
    camera: Camera
  ) {
    this.stop();
    this.domElement = domElement;
    this.vectors = vectors;
    this.camera = camera;
    this.listeners.push({ type: 'mousemove', fn: (e) => this.onMouseMove(e) });
    this.listeners.forEach((item) => {
      domElement.addEventListener(item.type, item.fn, false);
    });
  }

  public stop() {
    this.listeners.forEach((item) => {
      if (!this.domElement) {
        return;
      }
      this.domElement.removeEventListener(item.type, item.fn, false);
    });
    this.camera = undefined;
    this.domElement = undefined;
    this.listeners = [];
  }

  private onMouseMove(e: MouseEvent) {
    if (!this.measurementEnabled.value) {
      this.hovered.next(null);
      return;
    }
    const canvasBox = this.domElement!.getBoundingClientRect();
    const mouse = new Vector2(
      ((e.clientX - canvasBox.left) / canvasBox.width) * 2 - 1,
      -((e.clientY - canvasBox.top) / canvasBox.height) * 2 + 1
    );
    this.raycaster.setFromCamera(mouse, this.camera!);
    const intersections: Intersection[] = [];
    this.raycaster.intersectObjects(
      this.vectors.map((v) => v.mesh),
      true,
      intersections
    );
    if (intersections.length > 0) {
      const vectorMesh = intersections[0].object;
      const obj = this.vectors.find(
        (v) => v.uuid === vectorMesh.userData['uuid']
      );
      if (obj) {
        this.hovered.next(obj);
      }
    } else {
      this.hovered.next(null);
    }
  }
}
