import { Vector3 } from 'three';
import { VectorDimensionLabel } from './VectorDimensionLabel';
import { Constants as Config } from 'src/app/config/constants';

export class VectorHeightLabel extends VectorDimensionLabel {
  protected updateLabelPosition(): void {
    const position = new Vector3();
    this.vector.mesh.getWorldPosition(position);
    position.x -= (this.vector.length / 2 - 100) * Config.DIMENSION_SCALING;
    position.z += (this.vector.width / 2 - 20) * Config.DIMENSION_SCALING;
    this.setPosition(position);
  }
}
