import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LoadEventObject } from 'src/app/load/lib/load-event-object';
import { UiMode, UiService } from 'src/app/services/ui.service';
import { Space } from '../lib/space';
import { SpaceType } from '../lib/space-type';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.less']
})
export class FormComponent implements OnInit, OnDestroy {
  @Input() events: Observable<void>;
  @Input() space: Space;
  @Output() addSpaceEvent = new EventEmitter<LoadEventObject>();
  private unsubscribe$ = new Subject<void>();

  eventsSubject: Subject<void> = new Subject<void>();

  public uiMode$: Observable<UiMode>;

  constructor(uiService: UiService) {
    this.uiMode$ = uiService.getUiMode();
  }
  ngOnInit(): void {
    console.log('space/form.component.ts:ngOnInit space', this.space);
    this.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.onSubmit());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  onSubmit(): void {
    // console.log('space/form/form.component onSubmit()');
  }

  addSpace(eventObject: LoadEventObject) {
    // console.log('space/form/form.component.ts: addSpace called');
    this.addSpaceEvent.emit(eventObject);
  }
}
