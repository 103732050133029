import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { CommonModule } from '@angular/common';
import { SpaceComponent } from './space/space.component';
import { AxleComponent } from './axle/axle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FormComponent } from './form.component';

import { HelperModule } from 'src/app/lib/helper/helper.module';

@NgModule({
  declarations: [SpaceComponent, AxleComponent, FormComponent],
  imports: [
    CommonModule,
    HelperModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class FormModule {}
