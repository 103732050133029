import { VehicleMesh } from '../../../lib/vehicle-mesh';
import { Container } from './container';
import { BufferGeometry, Float32BufferAttribute } from 'three';
import { Constants as Config } from 'src/app/config/constants';

export class ContainerMesh extends VehicleMesh {
  private container: Container;

  private indices = [];
  private vertices = [];

  private length: number;
  private height: number;
  private width: number;

  public constructor(container: Container) {
    super('container', container);
    if (container.spaces.length > 0) {
      this.length = container.spaces[0].length * Config.DIMENSION_SCALING;
      this.height = container.spaces[0].height * Config.DIMENSION_SCALING;
      this.width = container.spaces[0].width * Config.DIMENSION_SCALING;
    } else {
      this.length = 0;
      this.height = 0;
      this.width = 0;
    }

    this.container = container;
    this.init();
  }

  private init() {
    this.mesh.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    const x0 = 0;
    const y0 = 0;
    const z0 = 0;
    // const x1 = x0 + this.length;
    // const y1 = y0 + this.height;
    // const z1 = z0 + this.width;
    this.vertices.push(x0, y0, z0);
    // this.vertices.push(x0, y0, z0);
    // this.vertices.push(x0, y1, z0);
    // this.vertices.push(x0, y1, z1);

    //this.vertices.push(0, 0, -z0); // bez dodania czegokolwiek nie działa poprawnie określanie pozycji
    /*
    let groupStart = 0;
    let groupCount = 0;

    // cabin
    this.vertices.push(-x0, -y0, z0); // 4 left bottom front
    this.vertices.push(-x0, y0 - this.cabinHeight, z0); // 5 left top front
    this.vertices.push(-x0, y0 - this.cabinHeight, -z0); // 6 left top back
    this.vertices.push(-x0, -y0, -z0); // 7 left bottom back
    this.vertices.push(x0, -y0, z0); // 12 cabin front bottom left
    this.vertices.push(x0, -y0, -z0); // 13 cabin back bottom left
    this.vertices.push(x0, y0, -z0); // 14 cabin back top left
    this.vertices.push(x0, y0, z0); // 15 cabin front top left
    this.vertices.push(-x0, y0 - this.cabinHeight, z0); // 16 cabin back top left
    this.vertices.push(-x0, y0 - this.cabinHeight, -z0); // 17 cabin front top left

    this.indices.push(0, 3, 5, 0, 5, 4); // cabin bottom faces
    this.indices.push(0, 4, 7, 0, 7, 8, 1, 8, 7); // cabin front faces
    this.indices.push(3, 5, 9, 5, 6, 9, 2, 9, 6); // cabin back faces
    this.indices.push(4, 6, 7, 4, 5, 6); // cabin left bottom faces
    this.indices.push(1, 2, 7, 2, 6, 7); // cabin left top faces

    groupStart += groupCount;
    groupCount = 6 * 3 + 9 * 2;
    this.geometry.addGroup(groupStart, groupCount, 2);
    */

    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    this.buildWireframe();

    this.addSpaces();
  }
}
