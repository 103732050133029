import { BufferGeometry, LineBasicMaterial, Mesh, SphereGeometry } from 'three';
import { GravityCenter } from './gravity-center';
import { Constants as Config } from 'src/app/config/constants';

export class GravityCenterMesh extends Mesh {
  private indices = [];
  private vertices = [];
  private gravityCenter: GravityCenter;

  constructor(gravityCenter: GravityCenter) {
    super();
    this.gravityCenter = gravityCenter;
    this.init();
  }

  public getName(): string {
    return (
      '(gravity-center (' +
      this.gravityCenter.position.x +
      ',' +
      this.gravityCenter.position.y +
      ',' +
      this.gravityCenter.position.z +
      ')' +
      this.gravityCenter.weight
    );
  }

  private init() {
    this.geometry = new BufferGeometry();
    this.getBufferGeometry();
  }

  private getBufferGeometry() {
    const geo = new SphereGeometry(
      Config.DIMENSION_SCALING * 50,
      Config.DIMENSION_SCALING * 32,
      Config.DIMENSION_SCALING * 16
    );
    const mat = new LineBasicMaterial({ color: 0xff0000 });
    const sphere = new Mesh(geo, mat);

    mat.polygonOffset = true;
    mat.polygonOffsetFactor = Config.DIMENSION_SCALING * 10;
    mat.polygonOffsetUnits = Config.DIMENSION_SCALING * 10;

    mat.depthTest = true;
    mat.depthWrite = true;

    this.renderOrder = 999;

    this.onBeforeRender = (renderer) => {
      renderer.clearDepth();
    };

    // const wireframe = new THREE.LineSegments(geo, mat);
    this.add(sphere);
  }
}
