import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { BoxGeometry } from 'three';
import { Cuboid } from './cuboid';
import { Constants } from 'src/app/config/constants';

export class CuboidMesh extends LoadMesh {
  private length: number;
  private height: number;
  private width: number;

  public constructor(cuboid: Cuboid, settings: LoadDisplaySettings) {
    super('rectangular', cuboid.color, settings, cuboid.uuid);
    this.length = cuboid.length * Constants.DIMENSION_SCALING;
    this.width = cuboid.width * Constants.DIMENSION_SCALING;
    this.height = cuboid.height * Constants.DIMENSION_SCALING;

    this.init();
  }

  public getLength(): number {
    return this.length * 1;
  }

  public getWidth(): number {
    return this.width * 1;
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getName(): string {
    return '(' + this.length + 'x' + this.width + 'x' + this.height + ')';
  }

  private init() {
    // const rotation = this.getRotationAngle() / Math.PI / 2;
    // // console.log('rotation', rotation);
    // if (rotation === 0 || rotation === 0.5) {
    this.obj.geometry = new BoxGeometry(this.length, this.height, this.width);

    // } else if (rotation === 0.25 || rotation === 0.75) {
    // this.object = new THREE.BoxGeometry(
    // this.width,
    // this.height,
    // this.length
    // );
    // }
    this.prepareMesh();
  }
}
