import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonModule as SpaceCommonModule } from '../common/common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { FormComponent } from './form.component';
import { BoxModule } from '../type/box/box.module';
import { ContainerModule } from '../type/container/container.module';
import { TrailerModule } from '../type/trailer/trailer.module';
import { CreateComponent } from './create/create.component';
import { NameComponent } from './name/name.component';
import { WeightComponent } from './weight/weight.component';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { OnlyNumberDirective } from 'src/app/directives/only-number.directive';

@NgModule({
  declarations: [
    FormComponent,
    CreateComponent,
    NameComponent,
    WeightComponent
  ],
  imports: [
    CommonModule,
    SpaceCommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BoxModule,
    ContainerModule,
    TrailerModule,
    DirectivesModule
  ],
  exports: [FormComponent, OnlyNumberDirective]
})
export class FormModule {}
