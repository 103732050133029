import {
  Color,
  EdgesGeometry,
  LineBasicMaterial,
  LineBasicMaterialParameters,
  LineSegments,
  Mesh,
  Vector3
} from 'three';
import { LoadDisplaySettings } from './load-display-settings';
import { loadMaterial } from '../../lib/helper/load-material';

export abstract class LoadMesh {
  public type: string;

  // protected forcedRotation = false;
  protected description: string;
  protected selected = false;
  public obj: Mesh;

  private rotationX: number;
  private rotationY: number;
  private rotationZ: number;

  private floorable: boolean;

  constructor(
    type: string,
    protected color: number,
    public readonly settings: LoadDisplaySettings,
    uuid: string
  ) {
    this.obj = new Mesh();
    this.obj.userData.uuid = uuid;
    // this.rotationX = obj.rotationX;
    // this.rotationY = obj.rotationY;
    // this.rotationZ = obj.rotationZ;
    this.type = type;
    // console.log('color constructor', color);
    this.obj.material = loadMaterial(color, settings);
    this.obj.name = 'load';
    //this.obj.userData.uuid = load.

    /*this.material.depthTest = true;
    this.material.depthWrite = true;

    this.renderOrder = 1;

    this.onBeforeRender = (renderer) => {
      renderer.clearDepth();
    };*/
  }

  get position(): Vector3 {
    return this.obj.position;
  }

  public getWorldPosition(target: Vector3) {
    return this.obj.getWorldPosition(target);
  }

  public abstract getName(): string;

  public getDescription(): string {
    return this.description;
  }

  public setDescription(value: string) {
    this.description = value;
  }

  public setFloorable(value: boolean) {
    this.floorable = value;
  }
  public getFloorable(): boolean {
    return this.floorable;
  }

  // public getForcedRotation(): boolean {
  // return this.forcedRotation;
  // }
  // public setForcedRotation(value: boolean) {
  // this.forcedRotation = value;
  // }

  public getType() {
    return this.type;
  }

  // public getMesh(): THREE.Mesh {
  // return this.mesh;
  // }
  //public get mesh: THREE.Mesh{
  // return this.mesh;
  // }

  public getMaterial() {
    return this.obj.material;
  }

  protected getBorderColor(): string | number | Color {
    return this.selected ? Color.NAMES.firebrick : Color.NAMES.black;
  }

  protected getLineSettings(): LineBasicMaterialParameters {
    const settings = {
      color: this.getBorderColor(),
      transparent: this.settings.loadBordersIntensity < 10,
      opacity: this.settings.loadBordersIntensity / 10,
      linewidth: 2 // i tak w webgl linie są rysowane w grubości 1
    };
    return settings;
  }

  protected prepareMesh() {
    // this.mesh = new THREE.Mesh(this.object, super.getMaterial());
    //const wireframe = new THREE.WireframeGeometry(this.mesh.geometry);
    const wireframe = new EdgesGeometry(this.obj.geometry);
    const mat = new LineBasicMaterial(this.getLineSettings());
    const line = new LineSegments(wireframe, mat);
    line.name = 'border';
    this.obj.add(line);
  }

  protected createBorderMaterial() {
    return new LineBasicMaterial(this.getLineSettings());
  }

  protected getBorders() {
    return this.obj.children.find((x) => x.name === 'border') as LineSegments;
  }

  public select(value = true) {
    this.selected = value;
    const border = this.getBorders();
    border.material = this.createBorderMaterial();
    if (value && !this.obj.userData['selected']) {
      this.obj.userData['originalColor'] = (
        this.obj.material as any
      ).color.getHex();
    }
    this.obj.userData['selected'] = value;
    (this.obj.material as any).color.set(
      value ? 0xff0000 : this.obj.userData['originalColor']
    );
    (this.obj.material as any).emissive.set(value ? 0x111111 : 0x0);
  }

  public hover() {
    (this.obj.material as any).emissive.set(0x333333);
  }

  public unhover() {
    (this.obj.material as any).emissive.set(
      this.obj.userData['selected'] ? 0x111111 : 0x0
    );
  }

  // protected setMesh(mesh: THREE.Mesh) {
  // this.mesh = mesh;
  // }

  /*public computePosition(xIdx, zIdx, yIdx, minX, minZ, minY) {
    this.getMesh().geometry.computeBoundingBox();
    this.getMesh().position.x = xIdx + minX + this.getXLength() / 2;
    this.getMesh().position.z = zIdx + minZ + this.getZLength() / 2;
    this.getMesh().position.y =
      yIdx +
      minY +
      (this.getMesh().geometry.boundingBox.max.y -
        this.getMesh().geometry.boundingBox.min.y) /
        2; //wysokością na razie się nie zajmujemy
  }*/

  /**
   * Update the velocity, position and orientation for a
   * given timestep.
   *
   * NOTE: this is extremely hot code (4000 arrows: ~240k
   * calls/second). No allocations and preventable calculations
   * here.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public update(dt) {
    // console.log('UPDATE DT', dt);
    // update velocity from 'gravity' towards origin
    /*v3.copy(this.position)
      .multiplyScalar(-Math.PI / this.position.lengthSq());
    this.velocity.add(v3);

    // update position from velocity
    v3.copy(this.velocity).multiplyScalar(dt);
    this.position.add(v3);

    // update rotation from direction of velocity
    v3.copy(this.velocity).normalize();
    this.rotation.setFromUnitVectors(ARROW_FORWARD, v3);

    // write udpated values into attribute-buffers
    this.position.toArray(
        this.buffers.position, this.offsets.position);
    this.rotation.toArray(
        this.buffers.rotation, this.offsets.rotation);*/
  }

  // setRotation() {
  // this.object.rotateX(MathUtils.degToRad(this.rotationX));
  // this.object.rotateY(MathUtils.degToRad(this.rotationY));
  // this.object.rotateZ(MathUtils.degToRad(this.rotationZ));
  // // console.log('setRotation', this.mesh.position);

  // const pivot = new THREE.Group();
  // pivot.position.set(0.0, 0.0, 0);
  // this.mesh.add(pivot);

  // const pivot = new THREE.Object3D();
  // pivot.add(this.mesh);

  // this.mesh.position.set(1000, 0, 0);

  // pivot.setRotationFromEuler(
  // new Euler(
  // MathUtils.degToRad(this.rotationX),
  // MathUtils.degToRad(this.rotationY),
  // /        MathUtils.degToRad(this.rotationZ),
  // 'XYZ'
  // /  )
  // );
  // pivot.rotateX()
  // pivot.add(mesh2);
  // const position = this.mesh.position.clone();
  // this.mesh.position.sub(position);
  // this.mesh.updateMatrix();
  // this.object.translate(0, 0, 0);
  // this.object.center();
  /*this.mesh.setRotationFromEuler(
      new Euler(
        MathUtils.degToRad(this.rotationX),
        MathUtils.degToRad(this.rotationY),
        MathUtils.degToRad(this.rotationZ),
        'XYZ'
      )
    );*/
  // let position = new THREE.Vector3();
  // this.mesh.getWorldPosition(position);
  // // console.log('world position', position);

  // this.mesh.position.sub(position);
  // this.mesh.position.sub(this.mesh.position);
  // this.mesh.rotation.set(
  // MathUtils.degToRad(this.rotationX),
  // MathUtils.degToRad(this.rotationY),
  // MathUtils.degToRad(this.rotationZ),
  // 'XYZ'
  // );

  // this.object.applyMatrix4
  // this.mesh.applyQuaternion(new THREE.Quaternion())

  // var box = new THREE.Box3().setFromObject(this.mesh);
  // box.getCenter(this.mesh.position); // this re-sets the mesh position
  // this.mesh.position.multiplyScalar(-1);

  // const quaternion = new THREE.Quaternion();
  // quaternion.setFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 2);

  // this.mesh.applyQuaternion(quaternion);
  // const vector = new THREE.Vector3(1, 0, 0);
  // vector.applyQuaternion(quaternion);

  // this.mesh.position.add(position);
  // }
}
