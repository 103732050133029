import { LoadDisplaySettings } from 'src/app/load/lib/load-display-settings';
import { LoadMesh } from 'src/app/load/lib/load-mesh';
import { BufferGeometry, Float32BufferAttribute } from 'three';
import { HShape } from './h-shape';

export class HShapeMesh extends LoadMesh {
  private a: number;
  private b: number;
  private c: number;
  private d: number;
  private e: number;
  private f: number;

  private height: number;

  public constructor(object: HShape, settings: LoadDisplaySettings) {
    super('h-shape', object.color, settings, object.uuid);
    this.obj.geometry = new BufferGeometry();
    this.a = object.a;
    this.b = object.b;
    this.c = object.c;
    this.d = object.d;
    this.e = object.e;
    this.f = object.f;

    this.height = object.height;
    this.init();
  }

  public getHeight(): number {
    return this.height * 1;
  }

  public getName(): string {
    return (
      '(' +
      this.a +
      'x' +
      this.b +
      'x' +
      this.c +
      'x' +
      this.d +
      'x' +
      this.height +
      ')'
    );
  }

  private init() {
    const indices = [];
    const vertices = [];
    const a0 = -(this.a + this.b + this.c) / 2;
    const a1 = (this.a + this.b + this.c) / 2;
    const b0 = -(this.d + this.e + this.f) / 2;
    const b1 = (this.d + this.e + this.f) / 2;
    const a = this.a;
    const b = this.b;
    const c = this.c;
    const d = this.d;
    const e = this.e;
    const h0 = -this.height / 2;
    const h1 = this.height / 2;

    // bottom
    vertices.push(a0, h0, b1); // 0
    vertices.push(a0 + a, h0, b1); // 1
    vertices.push(a0 + a, h0, b0 + d + e); // 2
    vertices.push(a0 + a + b, h0, b0 + d + e); // 3
    vertices.push(a0 + a + b, h0, b1); // 4
    vertices.push(a1, h0, b1); // 5
    vertices.push(a1, h0, b0); // 6
    vertices.push(a0 + a + b, h0, b0); // 7
    vertices.push(a0 + a + b, h0, b0 + d); // 8
    vertices.push(a0 + a, h0, b0 + d); // 9
    vertices.push(a0 + a, h0, b0); // 10
    vertices.push(a0, h0, b0); // 11

    // top
    vertices.push(a0, h1, b1); // 0
    vertices.push(a0 + a, h1, b1); // 1
    vertices.push(a0 + a, h1, b0 + d + e); // 2
    vertices.push(a0 + a + b, h1, b0 + d + e); // 3
    vertices.push(a0 + a + b, h1, b1); // 4
    vertices.push(a1, h1, b1); // 5
    vertices.push(a1, h1, b0); // 6
    vertices.push(a0 + a + b, h1, b0); // 7
    vertices.push(a0 + a + b, h1, b0 + d); // 8
    vertices.push(a0 + a, h1, b0 + d); // 9
    vertices.push(a0 + a, h1, b0); // 10
    vertices.push(a0, h1, b0); // 11

    //top
    indices.push(2, 1, 0);
    indices.push(2, 0, 11);
    indices.push(2, 11, 10);
    indices.push(2, 8, 9);
    indices.push(2, 3, 8);
    indices.push(4, 5, 7);
    indices.push(5, 6, 7);

    //bottom
    indices.push(14, 13, 12);
    indices.push(14, 12, 23);
    indices.push(14, 23, 22);
    indices.push(14, 20, 21);
    indices.push(14, 15, 20);
    indices.push(16, 17, 19);
    indices.push(17, 18, 19);

    //walls
    indices.push(0, 1, 12);
    indices.push(13, 12, 1);

    indices.push(1, 2, 13);
    indices.push(14, 13, 2);

    indices.push(2, 3, 14);
    indices.push(15, 14, 3);

    indices.push(3, 4, 15);
    indices.push(16, 15, 4);

    indices.push(4, 5, 16);
    indices.push(17, 16, 5);

    indices.push(5, 6, 17);
    indices.push(18, 17, 6);

    indices.push(6, 7, 18);
    indices.push(19, 18, 7);

    indices.push(7, 8, 19);
    indices.push(20, 19, 8);

    indices.push(8, 9, 20);
    indices.push(21, 20, 9);

    indices.push(9, 10, 21);
    indices.push(22, 21, 10);

    indices.push(10, 11, 22);
    indices.push(23, 22, 11);

    indices.push(0, 11, 12);
    indices.push(12, 11, 23);

    this.obj.geometry.setIndex(indices);
    this.obj.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(vertices, 3)
    );
    this.obj.geometry.computeVertexNormals();

    this.prepareMesh();
  }
}
