<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  [class.embedded]="!showFormElements"
>
  <mat-form-field>
    <textarea
      class="content-form-control"
      cdkFocusInitial
      matInput
      i18n-placeholder
      placeholder="Uwagi"
      formControlName="content"
      [maxlength]="maxLength"
      autocomplete="off"
      [rows]="rows"
      #contentRef
    ></textarea>
    <mat-error
      *ngIf="showFormElements && form.get('content').hasError('maxLength')"
    >
      <ng-container i18n>Maksymalnie {{ maxLength }} znaków</ng-container>
    </mat-error>
  </mat-form-field>
  <div
    *ngIf="showFormElements"
    class="counter"
    [class.error]="form.get('content').value.length > maxLength"
  >
    {{ form.get('content').value.length }} / {{ maxLength }}
  </div>

  <div class="mt-1" *ngIf="showFormElements">
    <button
      mat-raised-button
      type="submit"
      [disabled]="!form.valid"
      class="btn-primary"
    >
      <span i18n>Zapisz</span>
      <mat-icon *ngIf="isLoading">
        <mat-spinner class="progress" color="accent" diameter="24">
        </mat-spinner>
      </mat-icon>
    </button>
  </div>
</form>
