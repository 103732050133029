import { VehicleContext } from '../../lib/model/vehicle-context';

export class ContextStack {
  private stack: VehicleContext[];
  private currentUuid: string = null;
  public constructor(private stackSize: number) {
    this.stack = [];
  }

  public push(context: VehicleContext) {
    const currentIdx = this.stack.findIndex(
      (s) => s.getHistoryUuid() === this.currentUuid
    );
    if (currentIdx >= 0) {
      this.stack = this.stack.splice(0, currentIdx + 1);
      //this.stack.length = currentIdx + 1;
    }
    this.stack.push(context);
    this.stack.splice(0, this.stack.length - this.stackSize * 2);
    this.currentUuid = context.getHistoryUuid();
  }

  public moveCurrentNext(): VehicleContext {
    const currentIdx = this.stack.findIndex(
      (s) => s.getHistoryUuid() === this.currentUuid
    );

    if (currentIdx < 0 && this.stack.length > 0) {
      const current = this.stack[0];
      this.currentUuid = current.getHistoryUuid();
      return current;
    }
    if (currentIdx >= 0 && currentIdx < this.stack.length - 1) {
      const current = this.stack[currentIdx + 1];
      this.currentUuid = current.getHistoryUuid();
      return current;
    }
    return null;
  }

  public moveCurrentPrev(): VehicleContext {
    const currentIdx = this.stack.findIndex(
      (s) => s.getHistoryUuid() === this.currentUuid
    );
    if (currentIdx < 0 && this.stack.length > 0) {
      const current = this.stack[0];
      this.currentUuid = current.getHistoryUuid();
      return current;
    }
    if (currentIdx > 0) {
      const current = this.stack[currentIdx - 1];
      this.currentUuid = current.getHistoryUuid();
      return current;
    }
  }

  public pop(): VehicleContext | undefined {
    return this.stack.pop();
  }

  public last(): VehicleContext | undefined {
    return this.stack.slice(-1)[0];
  }

  public isEmpty(): boolean {
    return this.stack.length === 0;
  }

  public isCurrentLast(): boolean {
    return (
      this.stack.length === 0 ||
      this.stack[this.stack.length - 1].getHistoryUuid() === this.currentUuid
    );
  }

  public canMovePrev(context: VehicleContext): boolean {
    const currentIdx = this.stack.findIndex(
      (s) => s.getHistoryUuid() === context.getHistoryUuid()
    );
    return currentIdx > 0;
  }

  public size() {
    return this.stack.length;
  }
}
