import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FlooringType } from '../flooring/flooring.type';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent {
  public opened: boolean;
  public form: UntypedFormGroup;
  private formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
  private type: FlooringType;

  constructor() {
    this.form = this.formBuilder.group({
      preserveOrder: [false, Validators.compose([])]
    });
  }
}
