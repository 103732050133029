<div class="with-img text-center">
  <img
    src="assets/images/protrusion.png"
    alt="Wystawanie ładunku nad innym ładunkiem"
    i18n-alt
  />
</div>
<div class="help-main">
  <h2 class="primary" i18n>Wystawanie</h2>
  <p i18n>
    Określenie ile % może wystawać ładunek spiętrowany nad innym ładunkiem, aby
    nie opadl na dół (aby "nie spadł")
  </p>
  <p>
    <ng-container i18n>0% - ładunek nie może wystawać</ng-container> <br />
    <ng-container i18n
      >40% - ładunek spadnie dopiero, gdy będzie wystawać więcej niż
      40%</ng-container
    >
  </p>
  <p i18n>Dotyczy któregokolwiek z wymiarów - długości oraz szerokości.</p>
</div>
