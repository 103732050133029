import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Rating } from '../lib/model/rating';
import { HttpService } from './http.service';
import { StatusResponse } from '../lib/communication/status.response';

@Injectable({
  providedIn: 'root'
})
export class RatingService extends HttpService {
  private ratingUrl = environment.apiUrl + '/database/calculation/rating';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public rateCalculation(rating: Rating): Observable<StatusResponse<void>> {
    const httpCall = this.http.post<any>(this.ratingUrl, rating).pipe(
      catchError(this.handleError('rateCalculation', [])),
      map((response: any) => new StatusResponse<void>(response.status, null))
    );
    return httpCall;
  }
}
