import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SceneService } from 'src/app/scene/scene.service';
import { ContextService } from 'src/app/vehicle/context/context.service';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { FlooringLevelSettingsService } from './flooring-level-settings.service';
import { FlooringLevelComponent } from './form/flooring-level.component';
@Component({
  selector: 'app-flooring-levels-modal',
  templateUrl: './flooring-level-modal.component.html',
  styleUrls: ['./flooring-level-modal.component.less']
})
export class FlooringLevelModalComponent implements OnInit, OnDestroy {
  @ViewChild('levels') levels: FlooringLevelComponent;
  private unsubscribe$ = new Subject<void>();
  protected isLoading = false;
  protected get vehicle(): Vehicle {
    return this.contextService.getCurrentContext().getVehicle();
  }

  constructor(
    private service: FlooringLevelSettingsService,
    private sceneService: SceneService,
    private contextService: ContextService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {}

  onSubmit() {
    if (!this.levels.valid) {
      return;
    }
    this.vehicle.enabledSpaces.forEach((space) => {
      const formData = this.levels.getSpaceSettings(space);
      if (formData) {
        space.settings = formData;
      }
    });
    this.isLoading = true;
    this.service.updateVehicle(this.vehicle).subscribe((x) => {
      this.service.updateFlooringLevelInView$.next();
      this.sceneService.reloadLoads().subscribe(() => {
        this.close();
      });
    });
  }

  close(): void {
    this.service.close();
  }
}
