import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from '../services/ui.service';

export interface DialogData {
  idx: string;
  length: number;
  width: number;
  height: number;
  weight: number;
}

@Component({
  selector: 'app-change-load-size',
  templateUrl: './change-load-size.component.html',
  styleUrls: ['./change-load-size.component.less']
})
export class ChangeLoadSizeComponent {
  public uiData: DialogData;
  constructor(
    public dialogRef: MatDialogRef<ChangeLoadSizeComponent>,
    public ui: UiService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.uiData = {
      idx: data.idx,
      length: ui.getLengthInCurrentUnit(data.length),
      width: ui.getLengthInCurrentUnit(data.width),
      height: ui.getLengthInCurrentUnit(data.height),
      weight: ui.getWeightInCurrentUnit(data.weight)
    };
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
