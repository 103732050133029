import { Vector3 } from 'three';
import { Space } from '../../vehicle/space/lib/space';
import { TextLabel } from './TextLabel';
import { UiService } from '../../services/ui.service';
import { Constants as Config } from 'src/app/config/constants';

export class FlooringLevelLabel extends TextLabel {
  public constructor(
    private space: Space,
    private uiService: UiService,
    protected textScale = 1.0
  ) {
    super('', new Vector3(), 0, 'cuboid-label blue fs14', textScale);
    this.updateLabelPosition();
    this.updateText();
  }

  private updateText() {
    let txt = $localize`Poziom piętrowania` + '\n';
    txt +=
      this.uiService.getLengthInCurrentUnit(this.space.settings.height) +
      ' ' +
      this.uiService.getCurrentLengthUnit();
    this.setText(txt);
  }
  private updateLabelPosition() {
    const spaceWorldPosition = this.space.mesh.meshObj.getWorldPosition(
      new Vector3()
    );
    const labelPosition = new Vector3().copy(spaceWorldPosition);
    labelPosition.x += this.space.length * Config.DIMENSION_SCALING;
    labelPosition.z += this.space.width * Config.DIMENSION_SCALING;
    labelPosition.y += this.space.settings.height * Config.DIMENSION_SCALING;
    this.setPosition(labelPosition);
  }
}
