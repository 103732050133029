import { NgModule } from '@angular/core';
import { CommonModule as AngularCommon } from '@angular/common';
import { WeightUnitPipe } from '../../directives/weight-unit.pipe';
import { LoadDimensionsPipe } from './pipe/load-dimensions.pipe';
import { FilterPipe } from './pipe/filter.pipe';
import { DirectivesModule } from '../../directives/directives.module';
import { OnlyNumberDirective } from 'src/app/directives/only-number.directive';
import { LengthUnitPipe } from 'src/app/directives/length-unit.pipe';

@NgModule({
  declarations: [LoadDimensionsPipe, FilterPipe],
  imports: [AngularCommon, DirectivesModule],
  exports: [
    LengthUnitPipe,
    WeightUnitPipe,
    LoadDimensionsPipe,
    FilterPipe,
    OnlyNumberDirective
  ]
})
export class CommonModule {}
