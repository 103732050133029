import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Profile } from '../../../lib/model/profile';
import { LoadComponentService } from '../../../services/load-component.service';
import { UiService } from '../../../services/ui.service';
import { ProfileService } from '../../../services/profile.service';
import { Settings } from '../../../lib/model/settings';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public profile$: Observable<Profile>;

  constructor(
    private profileService: ProfileService,
    private loadComponentService: LoadComponentService,
    private uiService: UiService
  ) {
    this.profile$ = profileService.getProfile();
  }

  public updateProfile(profile: Profile) {
    this.profileService.updateProfile(profile);
  }

  public saveSettings(settings: Settings): Observable<Settings> {
    //this.uiService.setLengthUnit(settings.lengthUnit);
    return this.profileService.saveSettings(settings);
  }

  public updateSettingsInView(settings: Settings) {
    this.profileService.updateSettings(settings);
  }

  close() {
    this.loadComponentService.clear('settings.service.ts');
  }
}
