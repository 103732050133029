<ng-container [ngSwitch]="mode">
  <mat-expansion-panel
    *ngSwitchCase="'mat-expansion-panel'"
    #dataPanel
    (opened)="dataPanelOpenState = true"
    (closed)="dataPanelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong i18n>Dostosuj wymiary przestrzeni</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container [ngTemplateOutlet]="formTemplate"></ng-container>
  </mat-expansion-panel>
  <ng-container *ngSwitchCase="'plain-form'" [ngTemplateOutlet]="formTemplate">
  </ng-container>
</ng-container>

<ng-template #formTemplate>
  <div
    [formGroup]="form"
    novalidate
    (ngSubmit)="onSubmit()"
    class="load-form full-width"
  >
    <div class="row">
      <mat-form-field>
        <input
          matInput
          appOnlyNumber
          required
          i18n-placeholder
          placeholder="Długość ({{ ui.getLengthUnit() | async }})"
          formControlName="length"
          autocomplete="off"
        />
        <mat-error *ngIf="form.get('length').hasError('required')">
          <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
          <strong><ng-container i18n>wymagane</ng-container></strong>
        </mat-error>
        <mat-error *ngIf="form.get('length').hasError('min')">
          <ng-container i18n>Minimum:</ng-container><span>&nbsp;</span>
          <strong
            >{{ ui.getLengthInCurrentUnit(minSize?.x) }}
            {{ ui.getLengthUnit() | async }}
          </strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          appOnlyNumber
          required
          i18n-placeholder
          placeholder="Szerokość ({{ ui.getLengthUnit() | async }})"
          formControlName="width"
          autocomplete="off"
        />
        <mat-error *ngIf="form.get('width').hasError('required')">
          <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
          <strong><ng-container i18n>wymagane</ng-container></strong>
        </mat-error>
        <mat-error *ngIf="form.get('width').hasError('min')">
          <ng-container i18n>Minimum:</ng-container><span>&nbsp;</span>
          <strong
            >{{ ui.getLengthInCurrentUnit(minSize?.z) }}
            {{ ui.getLengthUnit() | async }}
          </strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <input
          matInput
          appOnlyNumber
          required
          i18n-placeholder
          placeholder="Wysokość ({{ ui.getLengthUnit() | async }})"
          formControlName="height"
          autocomplete="off"
        />
        <mat-error *ngIf="form.get('height').hasError('required')">
          <ng-container i18n>Pole</ng-container><span>&nbsp;</span>
          <strong><ng-container i18n>wymagane</ng-container></strong>
        </mat-error>
        <mat-error *ngIf="form.get('height').hasError('min')">
          <ng-container i18n>Minimum:</ng-container><span>&nbsp;</span>
          <strong
            >{{ ui.getLengthInCurrentUnit(minSize?.y) }}
            {{ ui.getLengthUnit() | async }}
          </strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</ng-template>
