<div class="modal-box settings-modal">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Ustawienia poziomów piętrowania</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <app-flooring-levels type="modal" #levels></app-flooring-levels>

      <div class="buttons">
        <button
          mat-raised-button
          type="submit"
          (click)="onSubmit()"
          class="btn-primary mt-2"
        >
          <span i18n>Zapisz </span>
          <mat-icon *ngIf="isLoading">
            <mat-spinner class="progress" color="accent" diameter="24">
            </mat-spinner>
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
