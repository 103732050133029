<h3 *ngIf="order > 0" i18n>Przestrzeń #{{ order + 1 }}</h3>
<app-box-form
  #box
  [events]="eventsSubject.asObservable()"
  [mode]="formMode"
  [item]="space"
  [minSize]="minSize"
></app-box-form>
<app-weight
  #weight
  [mode]="formMode"
  [default]="space ? ui.getWeightInCurrentUnit(space.maxLoadingWeight) : null"
></app-weight>
