import { LoadEventObject } from 'src/app/load/lib/load-event-object';
import { Profile, ProfileInterface } from '../model/profile';

export class FileResponse {
  uuid: string; //uuidv4
  message: string;
  profile: ProfileInterface;
  addedLoads: LoadEventObject[] = [];

  constructor(obj: any) {
    Object.assign(this, obj);
    if (obj?.profile) {
      this.profile = new Profile(obj.profile);
    }
    this.addedLoads = (obj?.addedLoads || []).map(
      (bundle: LoadEventObject) => new LoadEventObject(bundle)
    );
  }
}
