import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ErrorInterceptor } from './error-interceptor';
import { HttpTimeoutInterceptor } from './http-timeout-interceptor';
import { HttpCancelInterceptor } from './http-cancel-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpTimeoutInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: HttpCancelInterceptor, multi: true }
];
