<div class="app-loading" id="app-loading">
  <img
    src="/assets/images/box.gif"
    alt="Ładowanie..."
    i18n-alt
    class="loader-img"
  />
  <div class="long-work-info" [class.show]="showLongWorkInfo">
    <ng-container i18n>Wymagany dodatkowy czas.</ng-container><br />
    <div class="row">
      <span class="timer">{{ timeoutCounter | date: 'm:ss' }}</span>
      <button
        mat-raised-button
        class="cancel-btn mat-button mat-accent btn-secondary"
        type="button"
        (click)="cancel()"
      >
        <ng-container i18n>Anuluj</ng-container>
      </button>
    </div>
  </div>
</div>
