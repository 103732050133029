import { Combination } from 'js-combinatorics';
import { Constants as Config } from 'src/app/config/constants';
import { GravityCenter } from 'src/app/lib/gravity-center/gravity-center';
import { GravityCenterSprite } from 'src/app/lib/gravity-center/gravity-center-sprite';
import { Settings } from 'src/app/lib/model/settings';
import { Statistics } from 'src/app/lib/model/statistics';
import { VehicleContext } from 'src/app/lib/model/vehicle-context';
import { Load } from 'src/app/load/lib/load';
import { Axle } from 'src/app/vehicle/axles/lib/axle';
import { Vehicle } from 'src/app/vehicle/lib/vehicle';
import { FlooringLevelMesh } from 'src/app/vehicle/space/flooring-level/lib/flooring-level-mesh';
import { FreeFloorMesh } from 'src/app/vehicle/space/lib/free-floor-mesh';
import { Space } from 'src/app/vehicle/space/lib/space';
import { VectorMesh } from 'src/app/vehicle/space/lib/vector-mesh';
import { EmptyVehicle } from 'src/app/vehicle/type/empty-vehicle/lib/empty-vehicle';
import {
  AmbientLight,
  Color,
  DirectionalLight,
  GridHelper,
  Material,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  Scene,
  Vector3
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ClickHandlerService } from '../click-handler.service';
import { Vector } from 'src/app/lib/communication/vector';

export class MyScene extends Scene {
  public isSecondaryCopy = false;
  private matrixMeshes: VectorMesh[] = [];

  public constructor() {
    super();
  }

  public initDefaultView() {
    this.addLight();
    this.addBackground();
  }

  removeObjectsByName(name: string) {
    for (let object: Object3D; (object = this.getObjectByName(name)); ) {
      object.parent.remove(object);
    }
  }

  findSpaceMesh(spaceUuid: string): Mesh | null {
    const vehicleMesh = this.getObjectByName('vehicle-mesh') as Mesh;
    if (!vehicleMesh) {
      return null;
    }
    const spaceMesh = vehicleMesh.children
      .filter((x) => x.name === 'space-mesh')
      .find((x) => x.userData.uuid === spaceUuid);
    return spaceMesh as Mesh;
  }

  setContext(
    context: VehicleContext,
    settings: Settings,
    vehicleClickHandler: () => void,
    clickHandlerService: ClickHandlerService
  ): Promise<void> {
    const vehicle = context?.getVehicle();
    return new Promise((resolve, reject) => {
      this.clearAll(clickHandlerService);
      this.addVehicle(vehicle, vehicleClickHandler, clickHandlerService).then(
        () => {
          if (vehicle instanceof EmptyVehicle === false) {
            context.setExistingLoads(this.addLoads(context, settings));
            this.addFlooringLevels(vehicle);

            this.drawGravityCenters(
              vehicle,
              context.getAllLoads(),
              settings.labelConfig?.showGravityCenter
            );
          }

          this.addSpaceMatrixMeshes(context);
          vehicle.updateCalculationSettings(context.getSettings());
          resolve();
        }
      );
    });
  }

  addFreeFloorHighlight(space: Space, statistics: Statistics) {
    this.removeFreeFloorHighlight();
    if (!space) {
      return;
    }
    const spaceMesh = this.findSpaceMesh(space.uuid);
    if (!spaceMesh) {
      console.log('drawFreeFloorHighlight: space mesh not found');
      return;
    }
    const freeFloorMesh = new FreeFloorMesh(space, statistics);
    spaceMesh.add(freeFloorMesh);
  }

  removeFreeFloorHighlight() {
    let highlight: Object3D;
    do {
      highlight = this.getObjectByName('free-floor-mesh');
      if (highlight) {
        highlight.parent.remove(highlight);
      }
    } while (highlight);
  }

  addEmptyVehicleImage(
    clickHandler: () => void,
    clickHandlerService: ClickHandlerService
  ): Promise<void> {
    const material = new MeshStandardMaterial({ color: 0x838996 });
    material.transparent = true;
    material.depthWrite = false;
    material.opacity = 0.5;
    material.metalness = 1;
    material.roughness = 0.5;

    const position = new Vector3(0, 0, 0);

    return this.addEmptyVehicleGLTF(
      material,
      position,
      100 * Config.DIMENSION_SCALING,
      clickHandler,
      clickHandlerService
    );
  }

  addVehicle(
    vehicle: Vehicle,
    clickHandler: () => void,
    clickHandlerService: ClickHandlerService
  ): Promise<void> {
    if (vehicle.type === 'empty') {
      return this.addEmptyVehicleImage(clickHandler, clickHandlerService);
    } else {
      return new Promise((resolve, reject) => {
        const mesh = vehicle.mesh.mesh;
        const clone = this.isSecondaryCopy ? mesh.clone(true) : mesh;
        console.log(
          'add vehicle',
          clone.position,
          (-vehicle.maxLength * Config.DIMENSION_SCALING) / 2,
          (-vehicle.maxWidth * Config.DIMENSION_SCALING) / 2
        );
        clone.position.y = 0;
        clone.position.x = (-vehicle.maxLength * Config.DIMENSION_SCALING) / 2;
        clone.position.z = (-vehicle.maxWidth * Config.DIMENSION_SCALING) / 2;
        mesh.position.copy(clone.position);
        this.add(clone);
        resolve();
      });

      // this.showAxlesLabels();
    }
    // this.drawDebugPoints();
  }

  removeFlooringLevels() {
    this.removeObjectsByName('space-flooring-level-mesh');
  }

  addFlooringLevels(vehicle: Vehicle) {
    this.removeFlooringLevels();
    for (const space of vehicle.spaces) {
      if (space.settings && space.settings.maxHeightEnabled) {
        const flooringLevelMesh = new FlooringLevelMesh(space);
        const spaceMesh = this.findSpaceMesh(space.uuid);
        spaceMesh.add(flooringLevelMesh.obj);
      }
    }
  }

  addLoads(context: VehicleContext, loadSettings: Settings): Load[] {
    const loads: Load[] = [];
    // ładunki które nie są nigdzie załadowane
    const offset = new Vector3(0, 0, 0);
    for (const load of context.getLoads()) {
      loads.push(this.addLoad(load, offset, loadSettings));
    }
    // ładunki na przestrzeniach
    for (const space of context.getVehicle().enabledSpaces) {
      const wPosition = space.mesh.meshObj.getWorldPosition(new Vector3());

      for (const load of space.loads) {
        loads.push(this.addLoad(load, wPosition, loadSettings));
      }
    }
    return loads;
  }

  drawGravityCenters(vehicle: Vehicle, loads: Array<Load>, showIcons: boolean) {
    this.removeGravityCenters();
    this.drawLoadsGravityCenters(vehicle, loads, showIcons);
  }

  getMatrixVectors(): VectorMesh[] {
    return this.matrixMeshes;
  }

  showMatrixVectors() {
    this.matrixMeshes.forEach((v) => {
      v.show();
    });
  }

  hideMatrixVectors() {
    this.matrixMeshes.forEach((v) => {
      v.hide();
    });
  }

  private addLoad(
    load: Load,
    spaceOffset: Vector3,
    loadSettings: Settings
  ): Load {
    load.updateSettings({
      loadBordersIntensity: loadSettings.loadBordersIntensity,
      loadTransparency: loadSettings.loadTransparency
    });

    // console.log('load.position', load.position);
    const position = new Vector3(
      Config.DIMENSION_SCALING * (load.position.x + load.cuboidHull.length / 2),
      Config.DIMENSION_SCALING * (load.position.y + load.cuboidHull.height / 2),
      Config.DIMENSION_SCALING * (load.position.z + load.cuboidHull.width / 2)
    );
    position.add(spaceOffset);
    load.mesh.obj.position.copy(position);
    if (load.selected) {
      load.select();
    } else {
      load.unselect();
    }
    this.isSecondaryCopy
      ? this.add(load.mesh.obj.clone(true))
      : this.add(load.mesh.obj);

    return load;
  }

  private addEmptyVehicleGLTF(
    material: Material,
    position: Vector3,
    scale: number,
    clickHandler: () => void,
    clickHandlerService: ClickHandlerService
  ): Promise<void> {
    const glb = new GLTFLoader();
    return new Promise((resolve, reject) => {
      glb.load(
        'assets/glbs/tir_question_C.glb',
        (gltf) => {
          gltf.scene.name = 'vehicle';
          gltf.scene.scale.multiplyScalar(scale); // adjust scalar factor to match your scene scale

          gltf.scene.traverse((child) => {
            if (clickHandler && (child as Mesh).isMesh) {
              (child as Mesh).material = material;
              child.userData.onclick = clickHandler;
              clickHandlerService.addObjects([child]);
            }
          });
          gltf.scene.position.copy(position);
          //  gltf.scene.rotation.y = -0.72;
          gltf.scene.rotation.y = -1.12;
          this.add(gltf.scene);

          const dirLight = new DirectionalLight(0xffffff, 0.5);
          dirLight.position.set(-100, 10, 10);
          gltf.scene.add(dirLight);
          const dirLight2 = new DirectionalLight(0xffffff, 0.5);
          dirLight2.position.set(100, 10, 10);
          gltf.scene.add(dirLight2);
          resolve();
        },
        (xhr) => {
          // console.log(`${(xhr.loaded / xhr.total) * 100}% loaded`);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  private addBackground() {
    this.background = new Color(Config.MAIN_BG);
    const color = new Color(Config.GRID_COLOR);
    // const grid = new InfiniteGridHelper(1000, 1000, color, 50000);
    const grid = new GridHelper(
      Config.GRID_SIZE,
      Config.GRID_DIVISIONS,
      color,
      color
    );
    grid.position.y = 0;
    this.add(grid);
  }

  private addLight() {
    this.add(new AmbientLight(0xffffff, 2.0));
  }

  private clearAll(clickHandlerService: ClickHandlerService) {
    while (this.children.length > 0) {
      this.remove(this.children[0]);
    }
    clickHandlerService?.clearObjects();
    this.initDefaultView();
  }

  private removeGravityCenters() {
    this.removeObjectsByName('gravity-center');
  }

  private drawLoadsGravityCenters(
    vehicle: Vehicle,
    loads: Array<Load>,
    showIcons: boolean
  ) {
    let weight = 0;
    let weightX = 0;
    let weightY = 0;
    let weightZ = 0;

    //vehicle.space.load :) tak powinno to wygladac jezeli chodzi o organizacje
    //srdoki ciezkosci powinny byc dla kazdej z przestrzeni z osobna

    for (const load of loads.filter((x) => x.loaded === true)) {
      weightX += load.mesh.obj.position.x * load.weight;
      weightZ += load.mesh.obj.position.z * load.weight;
      weightY += load.mesh.obj.position.y * load.weight;
      weight += load.weight;
    }

    const center = new GravityCenter({
      position: new Vector3(
        weightX / weight,
        weightY / weight,
        weightZ / weight
      ),
      weight
    });

    if (showIcons) {
      const centerSprite = new GravityCenterSprite(center);
      centerSprite.position.copy(center.position);
      this.add(centerSprite);
    }

    this.calculateAxlePressures(vehicle, center);
  }

  //zrobic liczenie dla kazdej z przestrzeni z osobna a nie dla calego pojazdu
  // to raczej przenieść do osobnej klasy
  private calculateAxlePressures(vehicle: Vehicle, center: GravityCenter) {
    const axles = new Array<Axle>();
    for (const axle of vehicle.axles) {
      axle.distanceFromGravityCenter = Math.abs(
        center.position.x - (vehicle.mesh.position.x + axle.mesh.obj.position.x)
        // center.position.x -
        // (vehicle.mesh.position.x - vehicle.cabinLength / 2 + axle.offset)
      ); //tylko w X

      axles.push(axle);
    }

    for (const space of vehicle.spaces) {
      for (const axle of space.axles) {
        /*console.log(
          'vehicle.mesh.position.x',
          vehicle.mesh.position.x,
          'space.mesh.position.x',
          space.mesh.position.x
        );*/
        axle.distanceFromGravityCenter = Math.abs(
          center.position.x -
            (vehicle.mesh.position.x +
              axle.mesh.obj.position.x +
              space.mesh.position.x)
        ); //tylko w X
        axles.push(axle);
      }
    }
    if (axles.length < 1) {
      return;
    }

    const comb = new Combination(
      Array(axles.length)
        .fill(0)
        .map((v, i) => i),
      axles.length - 1
    );
    let denominator = 0;
    for (const c of comb) {
      let mul = 1;
      for (const e of c) {
        mul *= axles[e].distanceFromGravityCenter;
      }
      denominator += mul;
    }

    for (let i = 0; i < axles.length; i++) {
      let numerator = center.weight;
      for (let j = 0; j < axles.length; j++) {
        if (i === j) {
          continue;
        }
        numerator *= axles[j].distanceFromGravityCenter;
      }
      axles[i].pressure = numerator / denominator;
    }
  }

  private removeMatrixMeshes() {
    this.matrixMeshes = [];
    this.removeObjectsByName('vector-mesh');
  }

  private addSpaceMatrixMeshes(context: VehicleContext) {
    this.removeMatrixMeshes();
    if (!context || !context.getVehicle()) {
      return;
    }
    context.getVehicle().spaces.forEach((space) => {
      const spaceMesh = this.findSpaceMesh(space.uuid);
      if (!spaceMesh) {
        return;
      }
      space.matrix
        .filter((v) => !v.occupied)
        .forEach((vector) => {
          console.debug('DEBUG: draw matrix vector', vector, space.asVector());
          const vectorMesh = new VectorMesh(vector, { color: 0xfe9800 });
          spaceMesh.add(vectorMesh.mesh);
          this.matrixMeshes.push(vectorMesh);
        });
    });
  }
}
