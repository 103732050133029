<div class="modal-box file-import-box">
  <div class="overlay"></div>
  <div class="modal-box__container" cdkDrag>
    <div class="modal-box__header" cdkDragHandle>
      <h1 i18n>Wybierz przestrzenie do eksportu</h1>

      <button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-box__content">
      <div
        *ngIf="(contexts$ | async).length === 0"
        class="help text-center"
        i18n
      >
        Dodaj rozmieszczenie, aby wyeksportować projekt
      </div>

      <mat-selection-list
        #contexts
        class="text-left"
        (selectionChange)="onSelection($event)"
      >
        <mat-list-option
          #selectAll
          title="Zaznacz wszystkie"
          class="header-line"
          (click)="selectAllContexts()"
          i18n
          >Przestrzeń</mat-list-option
        >
        <mat-list-option
          *ngFor="let context of contexts$ | async"
          [value]="context"
        >
          {{ context.getVehicle().fullName }}
          {{ context.getVehicle() | vehicleDimensions | async }}
        </mat-list-option>
      </mat-selection-list>
      <div class="buttons">
        <div *ngIf="error" class="error">{{ error }}</div>
        <button
          mat-raised-button
          class="btn-primary"
          type="button"
          (click)="onSubmit()"
        >
          <ng-container i18n>Dalej</ng-container>
          <mat-icon>keyboard_double_arrow_right</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
