import {
  EdgesGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineSegments,
  Vector3
} from 'three';
import { Constants } from 'src/app/vehicle/lib/constants';
import { OtherMesh } from '../other-mesh';
import { Other } from '../other';

export class EPAL3Mesh extends OtherMesh {
  public constructor(other: Other) {
    super(other);
    this.mesh.material = [
      Constants.TRANSPARENT_MATERIAL,
      Constants.DARK_BROWN_WOOD_MATERIAL,
      Constants.LIGHT_BROWN_WOOD_MATERIAL
    ];
  }

  override getBufferGeometry() {
    const x0 = 0;
    const z0 = 0;
    const x1 = x0 + this.length;
    const z1 = z0 + this.width;
    const xc = (x1 - x0) / 2;
    const zc = (z1 - z0) / 2;
    const plankW = this.scale(154);
    const plankH = this.scale(22);

    let currentY = 0;
    const r = this.scale(17);
    //bottom planks
    const bottomPlanksPositions = [
      new Vector3(x0, currentY, z0),
      new Vector3(x0, currentY, zc - plankW / 2),
      new Vector3(x0, currentY, z1 - plankW)
    ];
    bottomPlanksPositions.map((p) => {
      this.plank(p, this.length, plankW, plankH, 1, r, r, r, r);
    });

    const boxH = this.scale(78);
    const topHelperPlankW = this.scale(100);
    const boxSize = [plankW, plankW, boxH] as const;
    const beforeTopPlankSize = [plankW, this.width, plankH] as const;
    const topMainPlankSize = [this.length, plankW, plankH] as const;
    const topHelperPlankSize = [this.length, topHelperPlankW, plankH] as const;
    const leftExternalRadiuses = [r, 0, 0, r] as const;
    const rightExternalRadiuses = [0, r, r, 0] as const;
    const backExternalRadiuses = [r, r, 0, 0] as const;
    const frontExternalRadiuses = [0, 0, r, r] as const;

    //boxes
    currentY += plankH;
    const leftBoxes = [
      new Vector3(x0, currentY, z0),
      new Vector3(x0, currentY, zc - plankW / 2),
      new Vector3(x0, currentY, z1 - plankW)
    ];
    leftBoxes.map((p) => {
      this.plank(p, ...boxSize, 2, ...leftExternalRadiuses);
    });
    const centerBoxes = [
      new Vector3(xc - plankW / 2, currentY, z0),
      new Vector3(xc - plankW / 2, currentY, zc - plankW / 2),
      new Vector3(xc - plankW / 2, currentY, z1 - plankW)
    ];
    centerBoxes.map((p) => {
      this.plank(p, ...boxSize, 2);
    });
    const rightBoxes = [
      new Vector3(x1 - plankW, currentY, z0),
      new Vector3(x1 - plankW, currentY, zc - plankW / 2),
      new Vector3(x1 - plankW, currentY, z1 - plankW)
    ];
    rightBoxes.map((p) => {
      this.plank(p, ...boxSize, 2, ...rightExternalRadiuses);
    });

    //before top planks
    currentY += boxH;
    this.plank(
      new Vector3(x0, currentY, z0),
      ...beforeTopPlankSize,
      1,
      ...leftExternalRadiuses
    );
    this.plank(
      new Vector3(xc - plankW / 2, currentY, z0),
      ...beforeTopPlankSize,
      1
    );
    this.plank(
      new Vector3(x1 - plankW, currentY, z0),
      ...beforeTopPlankSize,
      1,
      ...rightExternalRadiuses
    );

    //top planks
    currentY += plankH;
    // back top
    this.plank(
      new Vector3(x0, currentY, z0),
      ...topMainPlankSize,
      2,
      ...backExternalRadiuses
    );
    // middle top
    this.plank(
      new Vector3(x0, currentY, zc - plankW / 2),
      ...topMainPlankSize,
      2
    );
    // front top
    this.plank(
      new Vector3(x0, currentY, z1 - plankW),
      ...topMainPlankSize,
      2,
      ...frontExternalRadiuses
    );
    const topHelperPlanks = [
      new Vector3(x0, currentY, z0 + plankW), // first on the back
      new Vector3(x0, currentY, z1 - plankW - topHelperPlankW), // first on the front
      new Vector3(
        x0,
        currentY,
        zc - plankW / 2 - this.scale(43) - topHelperPlankW
      ),
      new Vector3(x0, currentY, zc + plankW / 2 + this.scale(43))
    ];
    topHelperPlanks.map((p) => {
      this.plank(p, ...topHelperPlankSize, 2);
    });
    currentY += plankH;

    this.mesh.geometry.setIndex(this.indices);
    this.mesh.geometry.setAttribute(
      'position',
      new Float32BufferAttribute(this.vertices, 3)
    );

    this.mesh.geometry.computeVertexNormals();

    const geo = new EdgesGeometry(this.mesh.geometry, 1);
    const mat = new LineBasicMaterial({ color: 0x333333 });
    const wireframe = new LineSegments(geo, mat);
    this.mesh.add(wireframe);

    this.addSpaces(0, currentY);
  }
}
