export class GridSettings {
  show = false;
  unit = 'cm';
  cellSize = 500;
  intensity = 5;
  showLabels = true;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
