import { SpaceFactory } from 'src/app/vehicle/space/lib/space-factory';
import { Vehicle } from '../../../lib/vehicle';
import { EPAL1Mesh } from './model/epal-1-mesh';
import { EPAL2Mesh } from './model/epal-2-mesh';
import { EPAL3Mesh } from './model/epal-3-mesh';
import { EPAL6Mesh } from './model/epal-6-mesh';
import { EPAL7Mesh } from './model/epal-7-mesh';
import { EPALCP1Mesh } from './model/epal-cp-1-mesh';
import { EPALCP2Mesh } from './model/epal-cp-2-mesh';
import { EPALCP3Mesh } from './model/epal-cp-3-mesh';
import { EPALCP4Mesh } from './model/epal-cp-4-mesh';
import { EPALCP5Mesh } from './model/epal-cp-5-mesh';
import { EPALCP6Mesh } from './model/epal-cp-6-mesh';
import { EPALCP7Mesh } from './model/epal-cp-7-mesh';
import { EPALCP8Mesh } from './model/epal-cp-8-mesh';
import { EPALCP9Mesh } from './model/epal-cp-9-mesh';
import { OtherMesh } from './other-mesh';
import { Box3, Vector3 } from 'three';

import { Constants as Config } from 'src/app/config/constants';

export class Other extends Vehicle {
  type = 'other';
  cabinLength = 0;
  cabinHeight = 0;
  cabinRoofHeight = 0;
  cabinWidth = 0;

  constructor(obj: any, spaceFactory: SpaceFactory) {
    super(obj, spaceFactory);

    switch (obj.model) {
      case 'EPAL1':
        this.mesh = new EPAL1Mesh(this);
        break;
      case 'EPAL2':
        this.mesh = new EPAL2Mesh(this);
        break;
      case 'EPAL3':
        this.mesh = new EPAL3Mesh(this);
        break;
      case 'EPAL6':
        this.mesh = new EPAL6Mesh(this);
        break;
      case 'EPAL7':
        this.mesh = new EPAL7Mesh(this);
        break;
      case 'EPAL-CP1':
        this.mesh = new EPALCP1Mesh(this);
        break;
      case 'EPAL-CP2':
        this.mesh = new EPALCP2Mesh(this);
        break;
      case 'EPAL-CP3':
        this.mesh = new EPALCP3Mesh(this);
        break;
      case 'EPAL-CP4':
        this.mesh = new EPALCP4Mesh(this);
        break;
      case 'EPAL-CP5':
        this.mesh = new EPALCP5Mesh(this);
        break;
      case 'EPAL-CP6':
        this.mesh = new EPALCP6Mesh(this);
        break;
      case 'EPAL-CP7':
        this.mesh = new EPALCP7Mesh(this);
        break;
      case 'EPAL-CP8':
        this.mesh = new EPALCP8Mesh(this);
        break;
      case 'EPAL-CP9':
        this.mesh = new EPALCP9Mesh(this);
        break;
      default:
        this.mesh = new OtherMesh(this);
        break;
    }
  }

  get fullName(): string {
    if (this.model) {
      return `${this.model} - ${this.name}`;
    }
    return this.name;
  }

  get fullDescription(): string {
    return `other ${this.length}x${this.width}x${this.height}`;
  }

  get isEmpty(): boolean {
    return !(this.length + this.width + this.height > 0);
  }

  get totalDisplayHeight(): number {
    const box = new Box3().setFromObject(this.mesh.mesh);
    const size = box.getSize(new Vector3());
    return size.y / Config.DIMENSION_SCALING;
  }

  public baseSizeChanged(): boolean {
    const space = this.spaces[0];
    return !space || space.length !== this.length || space.width !== this.width;
  }

  get maxLength(): number {
    return this.spaces.length > 0 ? this.spaces[0].length : 0;
  }
}
